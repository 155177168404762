import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { queryCache } from 'react-query';

import { tripsOrderDelete } from '../../repository/trips';

import { snackbarOpenAction } from '../../store/snackbar/snackbarActions';
import { dialogCloseAllAction, dialogDisplayMessageAction } from '../../store/dialog/dialogActions';

import Mui from '../material';
import CONSTANTS from '../../store/constants';

const DeleteTripDialog = ({ open, handleOnClose }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { content } = useSelector((state) => state.dialog);

  const handleOnSubmit = async () => {
    try {
      dispatch({ type: CONSTANTS.ADD_LOADER, payload: 'tripsOrderDelete' });
      dispatch(dialogCloseAllAction());
      const { success } = await tripsOrderDelete(content.orderId);

      if (success) {
        queryCache.removeQueries('orderList');
        dispatch(snackbarOpenAction('Din resa har blivit avbokad'));
        history.push('/mina-resor');
      } else {
        dispatch(dialogDisplayMessageAction('Din resa gick inte att avbokad'));
      }

      dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'tripsOrderDelete' });
    } catch (error) {
      dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'tripsOrderDelete' });
      dispatch(dialogDisplayMessageAction(error.code));
    }
  };

  return (
    <Mui.Dialog fullWidth maxWidth='xs' open={open} onClose={handleOnClose} aria-labelledby='dialog-title dialog-content'>
      <Mui.DialogTitle id='dialog-title'>
        <FormattedMessage id='bookTripReceipt.cancelTrip' />
      </Mui.DialogTitle>
      <Mui.DialogContent id="dialog-content">Är du säker på att du vill avboka din resa?</Mui.DialogContent>
      <Mui.DialogActions>
        <Mui.Button onClick={handleOnClose} color='primary' disableFocusRipple>
          <FormattedMessage id='dialog.btnCancel' />
        </Mui.Button>
        <Mui.Button onClick={handleOnSubmit} color='primary' disableFocusRipple>
          <FormattedMessage id='dialog.btnTripCancel' />
        </Mui.Button>
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};

export default React.memo(DeleteTripDialog);
