import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import { tripsOrderDelete } from '../../repository/trips';

import { snackbarOpenAction } from '../../store/snackbar/snackbarActions';
import { dialogCloseAllAction, dialogDisplayMessageAction } from '../../store/dialog/dialogActions';
import { tripsResetOffsetAction } from '../../store/trips/tripsActions';

import CONSTANTS from '../../store/constants';
import Mui from '../material';

const DeleteMultipleTripDialog = ({ open, handleOnClose }) => {
  const dispatch = useDispatch();
  const { content } = useSelector((state) => state.dialog);

  const handleOnSubmit = async () => {
    const arr = [];

    dispatch({ type: CONSTANTS.ADD_LOADER, payload: 'tripsOrderDelete' });
    dispatch(dialogCloseAllAction());

    for (let index = 0; index < content.length; index++) {
      arr.push(tripsOrderDelete(content[index]));
    }

    Promise.all(arr)
      .then((response) => {
        if (response.every((res) => res.success)) {
          dispatch(tripsResetOffsetAction());
          dispatch(snackbarOpenAction('Dina bokningar har avbokats'));
          dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'tripsOrderDelete' });
        } else {
          dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'tripsOrderDelete' });
          dispatch(dialogDisplayMessageAction('Dina bokningar gick inte att avbokad'));
        }
      })
      .catch((error) => {
        dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'tripsOrderDelete' });
        dispatch(dialogDisplayMessageAction(error.code));
      });
  };

  return (
    <Mui.Dialog fullWidth maxWidth='xs' open={open} onClose={handleOnClose} aria-labelledby='dialog-title dialog-content'>
      <Mui.DialogTitle id='dialog-title'>Avboka flera</Mui.DialogTitle>
      <Mui.DialogContent id='dialog-content'>Är du säker på att du vill avboka {content.length} resa/resor?</Mui.DialogContent>
      <Mui.DialogActions>
        <Mui.Button onClick={handleOnClose} color='primary' disableFocusRipple>
          <FormattedMessage id='dialog.btnCancel' />
        </Mui.Button>
        <Mui.Button onClick={handleOnSubmit} color='primary' disableFocusRipple>
          <FormattedMessage id='dialog.btnTripCancel' />
        </Mui.Button>
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};

export default React.memo(DeleteMultipleTripDialog);
