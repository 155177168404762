import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import usePermits from '../hooks/usePermits';
import useFavoriteTripsList from '../hooks/useFavoriteTripsList';

import { testValidFrom, testValidUntil } from '../utils/validators';

import Mui from './material';
import FavoriteTripItem from './FavoriteTripItem';

import NoTripsImage from '../images/no_fav_trips.svg';

const FavoriteTripsList = () => {
  const { messages } = useSelector((state) => state.cms);
  const { data: permits } = usePermits();
  const { data: favoriteTrips } = useFavoriteTripsList();
  const [amount, setAmount] = useState(3);

  const findMessage = (string) => {
    const msg = messages.find(
      (msg) => JSON.stringify(msg.name).replace(/"/g, '') === JSON.stringify(string).replace(/"/g, '')
    );
    return msg.content.replace(/<[^>]*>?/gm, '');
  };

  const findPermit = (trip) => {
    const permit = permits.find(
      (permit) => trip.orderJson.travellers[0].travelType.travelTypeId === permit.travelType.travelTypeId
    );

    if (!permit) {
      return { disable: true, msg: findMessage('Ditt tillstånd har slutat gälla') };
    } else if (!testValidFrom(permit.validFrom)) {
      return { disable: true, msg: findMessage('Ditt tillstånd har inte börjat gälla') };
    } else if (!testValidUntil(permit.validUntil)) {
      return { disable: true, msg: findMessage('Ditt tillstånd har slutat gälla') };
    } else if (parseInt(permit.tripCounterConsumed) >= permit.tripsGranted) {
      return { disable: true, msg: findMessage('Max antal resor') };
    } else {
      return { disable: false, msg: '' };
    }
  };

  return (
    <Mui.Card>
      <Mui.CardHeader
        title={
          <Mui.Typography variant='h3' color='primary' tabIndex={0}>
            <FormattedMessage id='start.listFavourite' />
          </Mui.Typography>
        }
      />
      {permits && favoriteTrips && favoriteTrips.length > 0 ? (
        <Mui.List disablePadding>
          {favoriteTrips.slice(0, amount).map((trip) => (
            <FavoriteTripItem key={trip.id} trip={trip} status={findPermit(trip)} />
          ))}
        </Mui.List>
      ) : (
        <Mui.CardContent>
          <Mui.Box display='flex' justifyContent='center' flexDirection='column' alignItems='center'>
            <Mui.Box mb={2}>
              <img src={NoTripsImage} alt='' />
            </Mui.Box>
            <Mui.Typography variant='body1' color='textSecondary' tabIndex={0}>
              Du har inga sparade favoritadresser.
            </Mui.Typography>
          </Mui.Box>
        </Mui.CardContent>
      )}
      <Mui.CardActions>
        {favoriteTrips && amount < favoriteTrips.length && (
          <Mui.Button size='large' fullWidth onClick={() => setAmount(favoriteTrips.length)} disableFocusRipple>
            Visa alla favoritresor ({favoriteTrips.length - amount})
          </Mui.Button>
        )}
      </Mui.CardActions>
    </Mui.Card>
  );
};

export default FavoriteTripsList;
