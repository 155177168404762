import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery, useMutation } from 'react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import Moment from 'react-moment';
import moment from 'moment';
import constants from '../store/constants';

import { themes } from '../themes/themes';
import { tripsOrderGet, tripsVehicleinfoGet } from '../repository/trips';

import Mui from '../components/material';
import Icon from '../components/icons';

import SelectAddressMap from '../components/SelectAddressMap';
import {
  dialogDisplayMessageAction,
  dialogDeleteTripAction,
  dialogEditTripAction,
  dialogCreateFavoriteTripAction,
} from '../store/dialog/dialogActions';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(6, 0),
  },
  headline: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  vehicleNbr: {
    textAlign: 'center',
  },
  title: {
    fontSize: '1.5rem',
  },
  orderAddress: {
    alignItems: 'center',

    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
  },
  h2: {
    fontSize: '1rem',
    marginBottom: 10,
  },
  fullWidth: {
    width: '100%',
  },
  iconMargin: {
    marginRight: theme.spacing(2),
  },
  delete: {
    width: '100%',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,

    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
    '&:focus, &:active': {
      backgroundColor: theme.palette.error.dark,
      outline: `3px solid ${theme.palette.error.light}`,
      outlineOffset: -3,
    },
  },
}));

function RouterButton(props) {
  const classes = useStyles();
  const { icon, to, disabled, children } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to]
  );

  return (
    <Mui.Button
      component={renderLink}
      className={classes.fullWidth}
      variant='outlined'
      color='primary'
      startIcon={icon}
      disabled={disabled}
      aria-disabled={disabled}
      disableFocusRipple>
      {children}
    </Mui.Button>
  );
}

const TripDetails = () => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const types = ['fx', 'olt'];

  const { primaryUser } = useSelector((state) => state.auth);
  const { settings } = useSelector((state) => state.system);

  const [vehicle, setVehicle] = useState(null);
  const [isOngoingTrip, setIsOngoingTrip] = useState(false);

  const { data: order, isFetching } = useQuery(['tripsOrderGet', orderId], tripsOrderGet, {
    refetchOnWindowFocus: false,
    retry: false,
    onError: (error) => {
      dispatch(dialogDisplayMessageAction(error.code));
      history.push('/mina-resor');
    },
  });

  const [fetchVehicleInfo, { data: vehicleInfo }] = useMutation(async (externalId) => await tripsVehicleinfoGet(externalId));

  const handleOnCreate = async () => {
    dispatch(dialogCreateFavoriteTripAction(order));
  };

  const handleOnDelete = async () => {
    dispatch(dialogDeleteTripAction(order));
  };

  const handleOnEdit = async () => {
    dispatch(dialogEditTripAction(order));
  };

  const handleOnBeOnTime = (type) => {
    if (type.toLowerCase() === 'fx') {
      dispatch(dialogDisplayMessageAction('Flex, prel avresetid'));
    } else {
      dispatch(dialogDisplayMessageAction('På plats i tid'));
    }
  };

  const updateVehicle = (data) => {
    setVehicle({ lat: data.lat, lon: data.lon });
  };

  useEffect(() => {
    if (order) {
      const format = 'YYYY-MM-DD HH:mm';
      const date = order.promisedTime;

      let dt = moment();
      const tf = moment(date, format).subtract(parseInt(settings?.position_maximum_minutes_before_arrival?.value), 'minutes');
      const tt = moment(date, format).add(parseInt(settings?.position_maximum_minutes_after_arrival?.value), 'minutes');

      if (dt.isBetween(tf, tt)) {
        setIsOngoingTrip(true);
      }
    }
  }, [order, settings]);

  useEffect(() => {
    if (isOngoingTrip && order?.externalId && order?.travellers[0]?.travelType?.type.toLowerCase() !== 'fx')
      fetchVehicleInfo(order.externalId);
  }, [isOngoingTrip, order, fetchVehicleInfo]);

  useEffect(() => {
    if (vehicleInfo) {
      const token = localStorage.getItem('user-token');
      let eventSource = new EventSource(
        `${window.__ENV__.REACT_APP_GPS_EVENTSOURCE}/${orderId}/${vehicleInfo?.dispatchLinkNbr}/${vehicleInfo?.vehicleNbr}?token=${token}&x-api-key=${window.__ENV__.REACT_APP_X_API_KEY}`
      );

      eventSource.onmessage = (e) => {
        const format = 'YYYY-MM-DD HH:mm';
        let dt = moment();
        const tt = moment(order.promisedTime, format).add(
          parseInt(settings?.position_maximum_minutes_after_arrival?.value),
          'minutes'
        );

        if (dt.isAfter(tt)) {
          setIsOngoingTrip(false);
          setVehicle(null);
          eventSource.close();
        } else {
          updateVehicle(JSON.parse(e.data));
        }
      };
    }
  }, [settings, vehicleInfo, orderId, order]);

  useEffect(() => {
    if (!order && isFetching) {
      dispatch({ type: constants.ADD_LOADER, payload: 'tripsOrderGet' });
    } else {
      dispatch({ type: constants.REMOVE_LOADER, payload: 'tripsOrderGet' });
    }
  }, [dispatch, order, isFetching]);

  return (
    <>
      {!isFetching && order ? (
        order.length <= 0 ? (
          <>
            <Helmet>
              <title>
                {`Resa kunde inte hittas | ${intl.formatMessage({ id: `app.title.${window.__ENV__.REACT_APP_THEME}` })} ${
                  themes[window.__ENV__.REACT_APP_THEME].name
                }`}
              </title>
            </Helmet>
            <Mui.Container maxWidth='sm'>
              <Mui.Paper className={classes.paper}>
                <Mui.Box display='flex' justifyContent='center' alignItems='center' padding={4}>
                  <Mui.Typography tabIndex={0}>Resa kunde inte hittas</Mui.Typography>
                </Mui.Box>
              </Mui.Paper>
            </Mui.Container>
          </>
        ) : (
          <>
            <Helmet>
              <title>
                {`${order.travellers[0].travelType.name} - ${moment(order.promisedTime).format(
                  'DD MMM'
                )} | ${intl.formatMessage({ id: `app.title.${window.__ENV__.REACT_APP_THEME}` })} ${
                  themes[window.__ENV__.REACT_APP_THEME].name
                }`}
              </title>
            </Helmet>
            <Mui.Container maxWidth='sm'>
              <Mui.Paper className={classes.paper}>
                <Mui.Box pt={4} pb={2} className={classes.headline}>
                  <Icon.DriveEtaRoundedIcon color='primary' className={classes.iconMargin} />
                  <Mui.Typography tabIndex={0} variant='h1' className={classes.title}>
                    {isOngoingTrip && 'Pågående'} {order.travellers[0].travelType.name} -{' '}
                    {moment(order.promisedTime).format('DD MMM')}
                  </Mui.Typography>
                </Mui.Box>
                <Mui.Box pb={2} className={classes.vehicleNbr}>
                  {isOngoingTrip && vehicleInfo?.vehicleNbr && (
                    <Mui.Typography tabIndex={0} variant='body2' color='textSecondary'>
                      {`Fordon ${vehicleInfo?.vehicleNbr}`}
                    </Mui.Typography>
                  )}
                </Mui.Box>

                <Mui.Box>
                  <SelectAddressMap
                    vehicle={vehicle}
                    pickupAddress={{ location: { lat: order.pickup.latitude, lon: order.pickup.longitude } }}
                    dropoffAddress={{ location: { lat: order.dropoff.latitude, lon: order.dropoff.longitude } }}
                    displayMap={true}
                  />
                </Mui.Box>

                <Mui.Box pt={4} pb={2} px={4}>
                  <Mui.Grid container>
                    <Mui.Grid item xs={12} sm={6}>
                      <Mui.Box display='flex' mb={2} className={classes.orderAddress}>
                        <Mui.Box tabIndex={0} aria-label="Från">
                          <Mui.Typography variant='h2' color='primary' className={classes.subtitle}>
                            <Mui.Box component='span' mr={2}>
                              <Icon.RadioButtonUncheckedRoundedIcon color='primary' />
                            </Mui.Box>
                            <Mui.Hidden xsDown>Från</Mui.Hidden>
                          </Mui.Typography>
                        </Mui.Box>
                        <Mui.Box>
                          <Mui.Typography tabIndex={0} aria-label={`${order.pickup.strName} ${order.pickup.strNbr} ${order.pickup.strNbrLetter}`}>
                            {order.pickup.strName} {order.pickup.strNbr}
                            {order.pickup.strNbrLetter}
                          </Mui.Typography>
                          <Mui.Typography tabIndex={0} color='textSecondary' variant='body1' aria-label={order.pickup.city}>
                            {order.pickup.city}
                          </Mui.Typography>
                        </Mui.Box>
                      </Mui.Box>
                    </Mui.Grid>

                    <Mui.Grid item xs={12} sm={6}>
                      <Mui.Box display='flex' alignItems='center' mb={2} className={classes.orderAddress}>
                        <Mui.Box tabIndex={0} aria-label="Till">
                          <Mui.Typography variant='h2' color='primary' className={classes.subtitle}>
                            <Mui.Box component='span' mr={2}>
                              <Icon.RoomIcon color='primary' />
                            </Mui.Box>
                            <Mui.Hidden xsDown>Till</Mui.Hidden>
                          </Mui.Typography>
                        </Mui.Box>
                        <Mui.Box>
                          <Mui.Typography tabIndex={0} variant='body1' aria-label={`${order.dropoff.strName} ${order.dropoff.strNbr} ${order.dropoff.strNbrLetter}`}>
                            {order.dropoff.strName} {order.dropoff.strNbr}
                            {order.dropoff.strNbrLetter}
                          </Mui.Typography>
                          <Mui.Typography tabIndex={0} color='textSecondary' variant='body1' aria-label={order.dropoff.city}>
                            {order.dropoff.city}
                          </Mui.Typography>
                        </Mui.Box>
                      </Mui.Box>
                    </Mui.Grid>
                  </Mui.Grid>
                </Mui.Box>

                <Mui.Divider variant='middle' />
                <Mui.Grid container alignItems='center'>
                  <Mui.Grid item sm={6}>
                    <Mui.Box display='flex' alignItems='center' py={4} mx={4}>
                      <Mui.Box component='span' mr={2}>
                        <Icon.WatchLaterRoundedIcon color='primary' />
                      </Mui.Box>
                      <Mui.Box display='flex' flexDirection='column'>
                        <Mui.Typography tabIndex={0} variant='h2' className={classes.subtitle} aria-label="Avgång">
                          Avgång
                        </Mui.Typography>
                        <Mui.Typography tabIndex={0} aria-label={order.promisedTime}>
                          <Moment format='HH:mm'>{order.promisedTime}</Moment>
                        </Mui.Typography>
                      </Mui.Box>
                    </Mui.Box>
                  </Mui.Grid>
                  {order.latestRequestedDropoffTime ? (
                    <Mui.Grid item sm={6}>
                      <Mui.Box display='flex' alignItems='center' py={4} mx={4}>
                        <Mui.Box component='span' mr={2}>
                          <Icon.ArrowRightAltRoundedIcon color='disabled' />
                        </Mui.Box>
                        <Mui.Box display='flex' flexDirection='column'>
                          <Mui.Typography tabIndex={0} aria-label="Senast ankomst">Senast ankomst</Mui.Typography>
                          <Mui.Typography tabIndex={0} aria-label={order.latestRequestedDropoffTime}>
                            <Moment format='HH:mm'>{order.latestRequestedDropoffTime}</Moment>
                          </Mui.Typography>
                        </Mui.Box>
                      </Mui.Box>
                    </Mui.Grid>
                  ) : null}
                </Mui.Grid>
                <Mui.Box display='flex' justifyContent='flex-end' px={4}>
                  <Mui.Button
                    disableFocusRipple
                    variant='text'
                    color='primary'
                    onClick={() => handleOnBeOnTime(order.travellers[0].travelType.type)}>
                    {order.travellers[0].travelType.type.toLowerCase() === 'fx' ? (
                      <FormattedMessage id='bookTripReceipt.beOnTimeFX' />
                    ) : (
                      <FormattedMessage id='bookTripReceipt.beOnTime' />
                    )}
                  </Mui.Button>
                </Mui.Box>
                <Mui.Divider variant='middle' />
                <Mui.Box display='flex' flexDirection='column' p={4}>
                  <Mui.Typography tabIndex={0} variant='h2' className={classes.h2} aria-label="Resenär">
                    Resenär
                  </Mui.Typography>
                  <Mui.Typography tabIndex={0} variant='body1' color='textSecondary' aria-label={`${order.travellers[0].firstName} ${order.travellers[0].surname} ${order.travellers[0].ssn === primaryUser.username.replace('-', '') ? '(Inloggad)' : null}`}>
                    {order.travellers[0].firstName} {order.travellers[0].surname}{' '}
                    {order.travellers[0].ssn === primaryUser.username.replace('-', '') ? '(Inloggad)' : null}
                  </Mui.Typography>
                  {types.includes(order.travellers[0].travelType.type.toLowerCase()) && (
                    <Mui.Typography tabIndex={0} variant='body1' color='textSecondary' aria-label={order.pickup.addrNote.Kontaktnummer}>
                      {order.pickup.addrNote.Kontaktnummer}
                    </Mui.Typography>
                  )}
                </Mui.Box>
                {order.travellers[0].extraAid.length > 0 && (
                  <>
                    <Mui.Divider variant='middle' />
                    <Mui.Box display='flex' flexDirection='column' p={4}>
                      <Mui.Typography tabIndex={0} variant='h2' className={classes.h2} aria-label="Tillval">
                        Tillval
                      </Mui.Typography>
                      <Mui.Typography tabIndex={0} variant='body1' color='textSecondary' aria-label={order.travellers[0].extraAid.map(el => el.description).join(', ')}>
                        {order.travellers[0].extraAid
                          .map((el) => {
                            return el.description;
                          })
                          .join(', ')}
                      </Mui.Typography>
                      {order.travellers[0].nbrCompanions ? (
                        <Mui.Typography tabIndex={0} variant='body1' color='textSecondary' aria-label={`${order.travellers[0].nbrCompanions} Ledsagare`}>
                          {order.travellers[0].nbrCompanions} Ledsagare
                        </Mui.Typography>
                      ) : null}

                      {order.travellers[0].nbrCoTravellers ? (
                        <Mui.Typography tabIndex={0} variant='body1' color='textSecondary' aria-label={`${order.travellers[0].nbrCoTravellers} Medresenär`}>
                          {order.travellers[0].nbrCoTravellers} Medresenär
                        </Mui.Typography>
                      ) : null}
                    </Mui.Box>
                  </>
                )}

                {!types.includes(order.travellers[0].travelType.type.toLowerCase()) && (
                  <>
                    <Mui.Divider variant='middle' />
                    <Mui.Box display='flex' flexDirection='column' p={4}>
                      <Mui.Typography tabIndex={0} variant='h2' className={classes.h2} aria-label="Instruktioner till föraren">
                        Instruktioner till föraren
                      </Mui.Typography>

                      {!!order.pickup.addrNote.Information ||
                      !!order.pickup.addrNote.Kontaktnummer ||
                      !!order.pickup.addrNote.Addressnotering ? (
                        <>
                          <Mui.Box display='flex' alignItems='center'>
                            <Icon.RadioButtonUncheckedRoundedIcon color='primary' className={classes.iconMargin} />
                            <Mui.Typography tabIndex={0} variant='h3' color='primary'>
                              Från
                            </Mui.Typography>
                          </Mui.Box>
                          <Mui.Box pl={6} pb={3}>
                            <Mui.Typography tabIndex={0} variant='body1' color='textSecondary'>
                              {order.pickup.addrNote.Information}
                            </Mui.Typography>
                            <Mui.Typography tabIndex={0} variant='body1' color='textSecondary'>
                              {order.pickup.addrNote.Kontaktnummer}
                            </Mui.Typography>
                            <Mui.Typography tabIndex={0} variant='body1' color='textSecondary'>
                              {order.pickup.addrNote.Addressnotering}
                            </Mui.Typography>
                          </Mui.Box>
                        </>
                      ) : null}

                      {!!order.dropoff.addrNote.Information ||
                      !!order.dropoff.addrNote.Kontaktnummer ||
                      !!order.dropoff.addrNote.Addressnotering ? (
                        <>
                          <Mui.Box display='flex' alignItems='center'>
                            <Icon.RoomIcon color='primary' className={classes.iconMargin} />
                            <Mui.Typography tabIndex={0} variant='h3' color='primary'>
                              Till
                            </Mui.Typography>
                          </Mui.Box>
                          <Mui.Box pl={6}>
                            <Mui.Typography tabIndex={0} variant='body1' color='textSecondary'>
                              {order.dropoff.addrNote.Information}
                            </Mui.Typography>
                            <Mui.Typography tabIndex={0} variant='body1' color='textSecondary'>
                              {order.dropoff.addrNote.Kontaktnummer}
                            </Mui.Typography>
                            <Mui.Typography tabIndex={0} variant='body1' color='textSecondary'>
                              {order.dropoff.addrNote.Addressnotering}
                            </Mui.Typography>
                          </Mui.Box>
                        </>
                      ) : null}
                    </Mui.Box>
                  </>
                )}
                <Mui.Divider variant='middle' />
                <Mui.Box display='flex' flexDirection='column' p={4}>
                  <Mui.Typography tabIndex={0} variant='h2' className={classes.h2} aria-label="Kostnad">
                    Kostnad
                  </Mui.Typography>
                  <Mui.Typography tabIndex={0} variant='body1' aria-label={`${order.fee} SEK`}>{order.fee} SEK</Mui.Typography>
                </Mui.Box>
              </Mui.Paper>
              <Mui.Box pb={6}>
                <Mui.Grid container spacing={2}>
                  <Mui.Grid item xs={12} sm={6}>
                    <Mui.Button
                      variant='outlined'
                      color='primary'
                      disabled={!order.travellers[0].travelType.bookable}
                      className={classes.fullWidth}
                      onClick={handleOnCreate}
                      disableFocusRipple
                      startIcon={<Icon.FavoriteRoundedIcon color='inherit' />}>
                      <FormattedMessage id='bookTripReceipt.favNameTitle' />
                    </Mui.Button>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} sm={6}>
                    <RouterButton
                      disabled={!order.travellers[0].travelType.bookable}
                      to={`/boka-resa/retur/${orderId}/${order.travellers[0].travelType.type}/${order.travellers[0].travelTypeID}`}
                      icon={<Icon.KeyboardReturnRoundedIcon color='inherit' />}>
                      <FormattedMessage id='bookTripReceipt.bookReturn' />
                    </RouterButton>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} sm={6}>
                    <Mui.Button
                      variant='outlined'
                      color='primary'
                      disabled={!order.travellers[0].travelType.bookable}
                      className={classes.fullWidth}
                      onClick={handleOnEdit}
                      disableFocusRipple
                      startIcon={<Icon.EditRoundedIcon color='inherit' />}>
                      <FormattedMessage id='bookTripReceipt.editTrip' />
                    </Mui.Button>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} sm={6}>
                    <Mui.Button
                      className={classes.delete}
                      variant='contained'
                      color='default'
                      disabled={!order.travellers[0].travelType.deletable}
                      onClick={handleOnDelete}
                      disableElevation
                      disableFocusRipple
                      startIcon={<Icon.DeleteRoundedIcon color='inherit' />}>
                      <FormattedMessage id='bookTripReceipt.cancelTrip' />
                    </Mui.Button>
                  </Mui.Grid>
                </Mui.Grid>
              </Mui.Box>
            </Mui.Container>
          </>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default React.memo(TripDetails);
