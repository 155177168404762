import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { userPasswordRequest } from '../../repository/user';
import { dialogCloseAllAction, dialogDisplayMessageAction } from '../../store/dialog/dialogActions';
import { validateSSN, convertSSN } from '../../utils/validators';

import Mui from '../material';

const ForgottenPasswordDialog = ({ open, handleOnClose }) => {
  const dispatch = useDispatch();
  const { content } = useSelector((state) => state.dialog);

  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState(false);
  const [usernameHelpText, setUsernameHelpText] = useState('Skriv personnumret med 12 siffror');

  const handleOnSubmit = async () => {
    if (username.length === 0) {
      return;
    }

    try {
      const { success } = await userPasswordRequest(convertSSN(username));
      if (success) {
        dispatch(dialogCloseAllAction());
        dispatch(dialogDisplayMessageAction('Nytt lösenord'));
      }
    } catch (error) {
      dispatch(dialogCloseAllAction());
      dispatch(dialogDisplayMessageAction(error.code));
    }
  };

  return (
    <Mui.Dialog fullWidth maxWidth='xs' open={open} onClose={handleOnClose} aria-labelledby='dialog-title'>
      <Mui.DialogTitle id='dialog-title' tabIndex={0}>
        <FormattedMessage id='login.newPassword.title' />
      </Mui.DialogTitle>
      <Mui.DialogContent>
        <Mui.Typography tabIndex={0}>
          <FormattedMessage id='login.newPassword.message' />
        </Mui.Typography>
        {content === 'username' ? (
          <Mui.TextField
            fullWidth
            margin='normal'
            value={username}
            id='username-helperText'
            label='Användarnamn'
            required
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
        ) : (
          <Mui.TextField
            fullWidth
            margin='normal'
            error={usernameError}
            value={username}
            id='ssn-helperText'
            label='Personnummer'
            required
            onBlur={(e) => {
              const { error, helptext } = validateSSN(e.target.value);
              setUsernameError(error);
              setUsernameHelpText(helptext);
            }}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            helperText={usernameHelpText}
          />
        )}
      </Mui.DialogContent>
      <Mui.DialogActions>
        <Mui.Button onClick={handleOnClose} color='primary' disableFocusRipple>
          <FormattedMessage id='login.newPassword.btnCancel' />
        </Mui.Button>
        <Mui.Button onClick={handleOnSubmit} color='primary' aria-disabled={username.length === 0} style={{color: username.length === 0 ? 'rgba(0, 0, 0, 0.26)' : null, backgroundColor: username.length === 0 ? '#ffffff' : null, border: username.length === 0 ? '0' : null}} disableFocusRipple>
          <FormattedMessage id='login.newPassword.btnOk' />
        </Mui.Button>
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};

export default React.memo(ForgottenPasswordDialog);
