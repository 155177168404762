import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { callbackRefStore } from '../../store/store';

import Mui from '../material';

const DisplayInformationDialog = ({ open, handleOnClose }) => {
  const { content } = useSelector((state) => state.dialog);

  const clickOnClose = () => {
    if (callbackRefStore.callback) {
      callbackRefStore.callback();
    } else {
      handleOnClose();
    }
  }

  return (
    <Mui.Dialog fullWidth maxWidth='xs' open={open} onClose={handleOnClose} aria-labelledby="dialog-content dialog-actions">
      <Mui.DialogContent id="dialog-content">
        {content.map((msg, idx) => (
          <Mui.Box key={idx}>
            <Mui.Typography variant='h2'>{msg.header}</Mui.Typography>
            <div dangerouslySetInnerHTML={{ __html: msg.info }} />
          </Mui.Box>
        ))}
      </Mui.DialogContent>
      <Mui.DialogActions id="dialog-actions">
        <Mui.Button onClick={clickOnClose} color='primary' disableFocusRipple>
          <FormattedMessage id='dialog.btnClose' />
        </Mui.Button>
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};

export default React.memo(DisplayInformationDialog);
