import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Mui from './material';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    flexDirection: 'column',
    zIndex: theme.zIndex.drawer + 100000,
    color: '#fff',
  },
}));

const LoadingOverlay = () => {
  const classes = useStyles();

  return (
    <Mui.Backdrop className={classes.backdrop} open={true} transitionDuration={{ appear: 0, enter: 0, exit: 200 }}>
      <Mui.CircularProgress color='inherit' />
      <Mui.Box mt={2}>
        <Mui.Typography variant='body1' color='initial'>
          Ett ögonblick...
        </Mui.Typography>
      </Mui.Box>
    </Mui.Backdrop>
  );
};

export default LoadingOverlay;
