const orebro = {
  name: 'Länstrafiken Örebro',
  options: {},
  theme: {
    primary: { main: '#0073B9', light: '#cce3f1' },
    secondary: { main: '#00A55F' },
    error: { main: '#CF374C', contrastText: '#ffffff' },
    purple: { main: '#625396', contrastText: '#ffffff' },
    ocean: { main: '#367c88', contrastText: '#ffffff' },
    green: { main: '#128758', contrastText: '#ffffff' },
  },
};

export default orebro;
