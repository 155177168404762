import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { isChrome, isDesktop, isFirefox, isIOS, isMobile, isOpera } from 'react-device-detect'

import { userGetAction, userClearAuth } from '../../store/auth/authActions';
import { dialogDisplayMessageAction } from '../../store/dialog/dialogActions';

import { bankidAuthUsername, bankidCollectLoginOrderRef } from '../../repository/bankid';

import CONSTANTS from '../../store/constants';
import Mui from '../material';

const useStyles = makeStyles((theme) => ({
  switch: {
    width: '100%',
  },
  switchLabel: {
    margin: 0,
  },
  errorText: {
    color: '#c0392b',
  },
  qrCode: {
    width: '100%',
  },
}));

const LoginBankIdForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const timer = useRef();
  const { links } = useSelector((state) => state.cms);

  const [gdpr, setGdpr] = useState(localStorage.getItem('gdpr') ? true : false);
  const [bankIdError, setBankIdError] = useState("");
  const [qrCode, setQRCode] = useState();
  const [isCheckingBankId, setIsCheckingBankId] = useState(false);
  
  let eventSource = null;

  const handleQRStream = (event) => {
    const data = JSON.parse(event.data);
    setQRCode(data.qrCode);
  };

  const handleBankIdToken = (event) => {
    clearInterval(timer.current);
    
    const { token, refreshToken } = JSON.parse(event.data);
    dispatch({ type: CONSTANTS.SET_PRIMARY_TOKEN, payload: { token } });
    dispatch({ type: CONSTANTS.SET_TOKEN, payload: { token } });
    localStorage.setItem('refreshToken', refreshToken);
    
    dispatch(userGetAction());
    closeEventSource();
  };

  const handleBankIdLoginError = (event) => {
    clearInterval(timer.current);
    closeEventSource();
    setBankIdError("Något gick fel med att logga in med BankId. Försök igen.");
  };

  const closeEventSource = () => {
    clearInterval(timer.current);
    if (eventSource) {
      eventSource.close();
      eventSource.removeEventListener('qr', handleQRStream);
      eventSource.removeEventListener('token', handleBankIdToken);
      eventSource.removeEventListener('error', handleBankIdLoginError);
      eventSource = null;
    }
    setQRCode();
  };

  const loginWithBankIdQRCode = async () => {
    try {
      localStorage.setItem('login-tab', 0);
      if (gdpr) {
        localStorage.setItem('gdpr', 'accepted');
      } else {
        localStorage.removeItem('gdpr');
        setBankIdError("Du måste godkänna integritetspolicyn för att kunna logga in");
        return;
      }

      timer.current = setTimeout(() => {
        closeEventSource();
        setBankIdError("Det tog för lång tid att logga in med BankId. Försök igen.");
      }, 30000);

      const apiURL = window.__ENV__.REACT_APP_API_URL;
      const xApiKey = window.__ENV__.REACT_APP_X_API_KEY;
      eventSource = new EventSource(`${apiURL}/d/auth/bankid/stream?xApiKey=${xApiKey}`);
      eventSource.addEventListener('qr', handleQRStream);
      eventSource.addEventListener('token', handleBankIdToken);
      eventSource.addEventListener('error', handleBankIdLoginError);
    } catch (error) {
      clearInterval(timer.current);

      closeEventSource();

      dispatch(userClearAuth());
      dispatch(dialogDisplayMessageAction(error.code));
    }
  };

  const handleBankIdRedirect = async (orderRef, redirects=0) => {
    clearInterval(timer.current);
    try {
      const collect = await bankidCollectLoginOrderRef(orderRef);
      const { status } = collect.data.response;

      console.info('BankId status:', status);

      if (status === 'pending') {
        timer.current = setTimeout(() => {
          const redirectNr = redirects + 1;
          if (redirectNr < 15) {
            handleBankIdRedirect(orderRef, redirectNr);
          } else {
            setBankIdError("Det tog för lång tid att logga in med BankId. Försök igen.");
          }
        }, 2000);
      } else if (status === 'complete') {
        localStorage.removeItem('orderRef');
        setIsCheckingBankId(false);
        dispatch({ type: CONSTANTS.SET_PRIMARY_TOKEN, payload: collect });
        dispatch({ type: CONSTANTS.SET_TOKEN, payload: collect });
        localStorage.setItem('refreshToken', collect.refreshToken);
        dispatch(userGetAction());
      } else {
        console.info('Oops');
        setIsCheckingBankId(false);
        clearInterval(timer.current);
        setBankIdError("Något gick fel med att logga in med BankId. Försök igen.");
      }
    } catch (error) {
      setIsCheckingBankId(false);
      clearInterval(timer.current);
      
      dispatch(userClearAuth());
      dispatch(dialogDisplayMessageAction(error.code));
    }
  };

  const loginWithBankIdRedirect = async () => {
    try {
      localStorage.setItem('login-tab', 0);
      if (gdpr) {
        localStorage.setItem('gdpr', 'accepted');
      } else {
        localStorage.removeItem('gdpr');
        setBankIdError("Du måste godkänna integritetspolicyn för att kunna logga in");
        return;
      }

      timer.current = setTimeout(() => {
        setIsCheckingBankId(false);
        setBankIdError("Det tog för lång tid att logga in med BankId. Försök igen.");
      }, 30000);

      setIsCheckingBankId(true);

      const auth = await bankidAuthUsername();
      const { autoStartToken, orderRef } = auth.response;
      if (autoStartToken.length && orderRef.length) {
        let redirect;
        let isPolling = false;

        if (isDesktop) {
          redirect = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`
          isPolling = true;
        } else if (isMobile) {
          const { href } = window.location;
          let returnUrl = `${href}#initiated`;

          if (isIOS) {
            if (isChrome) {
              returnUrl = 'googlechrome://';
              isPolling = true;
            } else if (isFirefox) {
              returnUrl = 'firefox://';
              isPolling = true;
            } else if (isOpera) {
              returnUrl = `${href.replace('http', 'touch-http')}#initiated`;
            }
          }

          redirect = `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=${returnUrl}`;
        } else {
          setIsCheckingBankId(false);
          clearInterval(timer.current);
          setBankIdError("Något gick fel med att logga in med BankId. Försök igen.");
          return;
        }

        if (!isPolling) {
          localStorage.setItem('orderRef', orderRef);
        }

        window.location.href = redirect;
        
        if (isPolling) {
          await handleBankIdRedirect(orderRef);
        }
      } else {
        setIsCheckingBankId(false);
        clearInterval(timer.current);
        setBankIdError("Något gick fel med att logga in med BankId. Försök igen.");
      }
    } catch (error) {
      setIsCheckingBankId(false);
      clearInterval(timer.current);

      dispatch(userClearAuth());
      dispatch(dialogDisplayMessageAction(error.code));
    }
  };

  const loginWithOtherBankId = () => {
    if (window.location.hash === '#initiated') {
      window.location.href = window.location.origin;
    }
    window.location.reload();
  };

  const handleHashChange = useCallback(() => {
    if (window.location.hash === '#initiated') {
      const orderRef = localStorage.getItem('orderRef');
      if (orderRef.length) {
        setIsCheckingBankId(true);
        handleBankIdRedirect(orderRef);
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('hashchange', handleHashChange);
    return () => {
      closeEventSource();
      window.removeEventListener('hashchange', handleHashChange);
      localStorage.removeItem('orderRef');
    };
  }, []);

  const getUserAgreementUrl = () => {
    if (links) {
      const content = links.find((item) => item.name === 'Integritetspolicy');
      if (content) return content.url;
      return '';
    }
  };

  if (qrCode) {
    return (
      <>
        <Mui.Container maxWidth='md'>
          <Mui.Box display='flex' alignItems='center'>
            <img src={qrCode} alt='QR kod för BankId login' className={classes.qrCode} />
          </Mui.Box>
        </Mui.Container>
        <Mui.Container maxWidth='md'>
          <Mui.Button onClick={loginWithOtherBankId} fullWidth variant='contained' color='primary' disableElevation disableFocusRipple>
            <FormattedMessage id='login.btnOtherBankIdLogin' />
          </Mui.Button>
        </Mui.Container>
      </>
    );
  }

  if (isCheckingBankId) {
    return (
      <>
        <Mui.Container maxWidth='md'>
          <Mui.Box display='flex' justifyContent='center' marginBottom='5px'>
            <Mui.CircularProgress color='primary' />
          </Mui.Box>
        </Mui.Container>
        <Mui.Container maxWidth='md'>
          <Mui.Button onClick={loginWithOtherBankId} fullWidth variant='contained' color='primary' disableElevation disableFocusRipple>
            <FormattedMessage id='login.btnOtherBankIdLogin' />
          </Mui.Button>
        </Mui.Container>
      </>
    );
  }

  return (
    <Mui.FormGroup row>
      <Mui.Typography aria-live="polite" role="status" className={classes.errorText}>{ bankIdError }</Mui.Typography>

      <Mui.FormControl fullWidth margin='normal'>
        <Mui.FormControlLabel
          className={classes.switchLabel}
          control={
            <Mui.Switch
              checked={gdpr}
              onChange={(e) => setGdpr(e.target.checked)}
              name='gdpr-check'
              color='primary'
            />
          }
          labelPlacement="start"
          label={<>Jag godkänner <a href={getUserAgreementUrl()} target="_blank" rel="noreferrer">integritetspolicyn</a></>}
        />
      </Mui.FormControl>

      <Mui.FormControl fullWidth margin='normal'>
        <Mui.Button onClick={loginWithBankIdQRCode} fullWidth variant='contained' color='primary' disableElevation disableFocusRipple>
          <FormattedMessage id='login.btnLoginBankIdQRCode' />
        </Mui.Button>
      </Mui.FormControl>
      <Mui.FormControl fullWidth margin='normal'>
        <Mui.Button onClick={loginWithBankIdRedirect} fullWidth variant='contained' color='primary' disableElevation disableFocusRipple>
          <FormattedMessage id='login.btnLoginBankIdRedirect' />
        </Mui.Button>
      </Mui.FormControl>
    </Mui.FormGroup>
  );
};

export default React.memo(LoginBankIdForm);
