import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { themes } from '../themes/themes';
import moment from 'moment';
import constants from '../store/constants';

import usePermits from '../hooks/usePermits';
import useOrderSave from '../hooks/useOrderSave';
import useOrderEdit from '../hooks/useOrderEdit';
import useOrderDetail from '../hooks/useOrderDetail';
import useFavoriteDetails from '../hooks/useFavoriteDetails';

import { clearBookingAction, setBookingAction } from '../store/booking/bookingActions';
import { selectIconsHelper } from '../utils/helpers';
import { getFormattedDate, testPhoneNbr, testValidFrom, testValidUntil } from '../utils/validators';

import Mui from '../components/material';

import TripContainer from '../containers/booktrip/TripContainer';
import TravellerContainer from '../containers/booktrip/TravellerContainer';
import OptionsContainer from '../containers/booktrip/OptionsContainer';
import InstructionsContainer from '../containers/booktrip/InstructionsContainer';
import { dialogDisplayMessageAction, dialogDisplayInformationAction, dialogCloseAllAction } from '../store/dialog/dialogActions';

const useStyles = makeStyles((theme) => ({
  headline: {
    fontSize: '1.5rem',
    textAlign: 'center',
  },
  bookButton: {
    marginTop: '1em',
  },
}));

// Vanilla JS ENUM
const validationRefs = {
  TRIP: 'trip',
  OPTIONS: 'options',
  INSTRUCTIONS: 'instructions',
};

const BookTrip = () => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { type, permitId, nodeId, mode } = useParams(); // nodeId is ether a favoriteTripId or orderId
  const types = ['fx', 'olt'];

  const { settings } = useSelector((state) => state.system);
  const { isAuthenticated, customer } = useSelector((state) => state.auth);
  const {
    pickupAddress,
    dropoffAddress,
    pickupHelp,
    extraAids,
    requestPickupTime,
    requestDropOffTime,
  } = useSelector((state) => state.booking);

  const { data: permits, isFetching: isFetchingPermits } = usePermits();
  const [fetchOrderDetail] = useOrderDetail();
  const [fetchFavoriteDetails] = useFavoriteDetails();
  const [submitBookingOrder] = useOrderSave();
  const [updateBookingOrder] = useOrderEdit();

  const [disabled, setDisabled] = useState(true);
  const [permit, setPermit] = useState(null);
  const [maxCoTravellers, setMaxCoTravellers] = useState(0);
  const [minimumMinutesUntilArrival, setMinimumMinutesUntilArrival] = useState(0);
  const [validDates, setValidDates] = useState([]);
  const [dateHelperText, setDateHelperText] = useState('');
  const [tripValidationMessages, setTripValidationMessages] = useState([]);

  const tripRef = useRef();
  const optionsRef = useRef();
  const instructionsRef = useRef();

  const closeValidationDialog = (currentRef) => {
    dispatch(dialogCloseAllAction());
    setTimeout(() => {
      switch (currentRef) {
        case validationRefs.TRIP:
          if (tripRef && tripRef.current) {
            tripRef.current.focus();
          }
          break;
        case validationRefs.OPTIONS:
          if (optionsRef && optionsRef.current) {
            optionsRef.current.focus();
          }
          break;
        case validationRefs.INSTRUCTIONS:
          if (instructionsRef && instructionsRef.current) {
            instructionsRef.current.focus();
          }
          break;
        default:
          break;
      }
    }, 200);
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();

    let currentRef;
    if (disabled) {
      let info = '<ul>';

      if (!pickupAddress) {
        info += `<li>${intl.formatMessage({ id: 'bookTrip.validation.pickupAddress' })}</li>`;
      }

      if (!dropoffAddress) {
        info += `<li>${intl.formatMessage({ id: 'bookTrip.validation.dropoffAddress' })}</li>`;
      }

      if (tripValidationMessages.length > 0) {
        info += tripValidationMessages.map((message) => `<li>${message}</li>`).join('');
        currentRef = validationRefs.TRIP;
      }

      if (extraAids.length === 0) {
        info += `<li>${intl.formatMessage({ id: 'bookTrip.validation.extraAids' })}</li>`;
        currentRef = currentRef || validationRefs.OPTIONS;
      }
      
      if (!testPhoneNbr(pickupHelp[0].contactPhonenumber)) {
        info += `<li>${intl.formatMessage({ id: 'bookTrip.validation.phoneNumber' })}</li>`;
        currentRef = currentRef || validationRefs.INSTRUCTIONS;
      }

      info += '</ul>';
      
      const validationMessages = [{ info }];

      dispatch(dialogDisplayInformationAction(validationMessages, () => closeValidationDialog(currentRef)));
    } else {
      if (mode === 'edit') {
        updateBookingOrder(nodeId);
      } else {
        submitBookingOrder();
      }
    }
  };

  const handleValidDates = (currentPermit) => {
    const { validFrom, validUntil, invalidFrom, invalidUntil } = currentPermit;

    const today = getFormattedDate(moment());
    const twoWeeks = getFormattedDate(moment(today).add(14, 'days'));
    const minDate = getFormattedDate(today.isAfter(validFrom) ? today : validFrom);
    const maxDate = getFormattedDate(twoWeeks.isBefore(validUntil) ? twoWeeks : validUntil);
  
    // Create array of valid dates
    let updatedValidDates = [];
    const startDate = getFormattedDate(minDate);
    const endDate = getFormattedDate(maxDate);
    while (startDate.isSameOrBefore(endDate)) {
      const newDate = getFormattedDate(startDate);
      updatedValidDates.push(newDate);
      startDate.add(1, 'days');
    }
  
    let disabledDates = [];
    if (settings?.order_unbookable_dates?.value) {
      const today = moment();
      const isDecember = today.format('MM') === '12';
      disabledDates = settings.order_unbookable_dates.value.map((date) => {
        const disabledDate = getFormattedDate(date, 'MMDD');
        if (isDecember && date.startsWith('01')) {
          disabledDate.add(1, 'years');
        }
        return disabledDate;
      });
    }
    if (invalidFrom && invalidUntil) {
      const startInvalidDate = getFormattedDate(invalidFrom);
      const endInvalidDate = getFormattedDate(invalidUntil);
      while (startInvalidDate.isSameOrBefore(endInvalidDate)) {
        const newDate = getFormattedDate(startInvalidDate);
        disabledDates.push(newDate);
        startInvalidDate.add(1, 'days');
      }
    }
  
    updatedValidDates = updatedValidDates.filter((date) => disabledDates.every((disabledDate) => !date.isSame(disabledDate)));
    setValidDates(updatedValidDates);

    // Set date helper text
    const showValidFrom = !minDate.isSame(today);
    const showValidUntil = !maxDate.isSame(twoWeeks);
    const showInvalidFrom = minDate.isSameOrBefore(invalidFrom);
    const showInvalidUntil = maxDate.isSameOrAfter(invalidUntil);

    let newDateHelperText = '';
    const prefix = intl.formatMessage({ id: 'bookTrip.dateHelperText.permit' });
    if (showValidFrom && showValidUntil) {
      const validFromText = moment(validFrom).format('DD MMMM');
      const validUntilText = moment(validUntil).format('DD MMMM');

      newDateHelperText = `${prefix} ${intl.formatMessage({
        id: 'bookTrip.dateHelperText.validRange'
      }, {
        validFrom: validFromText,
        validUntil: validUntilText,
      })}`;
    } else if (showValidFrom) {
      const validFromText = moment(validFrom).format('DD MMMM');

      newDateHelperText = `${prefix} ${intl.formatMessage({
        id: 'bookTrip.dateHelperText.validFrom'
      }, {
        validFrom: validFromText,
      })}`;
    } else if (showValidUntil) {
      const validUntilText = moment(validUntil).format('DD MMMM');

      newDateHelperText = `${prefix} ${intl.formatMessage({
        id: 'bookTrip.dateHelperText.validUntil'
      }, {
        validUntil: validUntilText,
      })}`;
    }

    if (showInvalidFrom || showInvalidUntil) {
      const invalidFromText = moment(invalidFrom).format('DD MMMM');
      const invalidUntilText = moment(invalidUntil).format('DD MMMM');

      newDateHelperText += newDateHelperText.length > 0 ? ', ' : `${prefix} `;
      newDateHelperText += `${intl.formatMessage({
        id: 'bookTrip.dateHelperText.invalidRange'
      }, {
        invalidFrom: invalidFromText,
        invalidUntil: invalidUntilText,
      })}`;
    }

    newDateHelperText += newDateHelperText.length > 0 ? '.' : '';
    setDateHelperText(newDateHelperText);
  };

  useEffect(() => {
    const isValid = !!pickupAddress &&
      !!dropoffAddress &&
      testPhoneNbr(pickupHelp[0].contactPhonenumber) &&
      extraAids.length > 0 &&
      tripValidationMessages.length === 0;
    setDisabled(!isValid);
  }, [
    pickupAddress,
    dropoffAddress,
    pickupHelp,
    extraAids,
    requestPickupTime,
    requestDropOffTime,
    tripValidationMessages,
  ]);

  useEffect(() => {
    if (permits && permitId) {
      const currentPermit = permits.find((permit) => permit.travelType.travelTypeId === permitId);

      if (currentPermit) {
        const enviroments = ['gbgstad'];
        const format = 'YYYY-MM-DD HH:mm';
        const currentDate = moment().add(60, 'minutes');

        if (!testValidFrom(currentPermit.validFrom)) {
          dispatch(dialogDisplayMessageAction('Ditt tillstånd har inte börjat gälla'));
          history.push('/');
        } else if (!testValidUntil(currentPermit.validUntil)) {
          dispatch(dialogDisplayMessageAction('Ditt tillstånd har slutat gälla'));
          history.push('/');
        } else if (parseInt(currentPermit.tripCounterConsumed) >= currentPermit.tripsGranted) {
          dispatch(dialogDisplayMessageAction('Max antal resor'));
          history.push('/');
        }

        const contactPhonenumber = enviroments.includes(window.__ENV__.REACT_APP_THEME)
          ? customer?.mobPhoneNbr
            ? customer?.mobPhoneNbr
            : customer?.phoneNbr
          : customer?.phoneNbr;

        handleValidDates(currentPermit);
        const requestPickupTime = validDates[0] ?
          moment(`${validDates[0].format('YYYY-MM-DD')} ${currentDate.format('HH:mm')}`, format) :
          moment(currentDate, format);

        const booking = {
          travelTypeID: currentPermit.travelType.travelTypeId,
          requestPickupTime: requestPickupTime.format(format),
          noteToCCandVehicle: '',
          extraAids: currentPermit.extraAidNoKey ? [currentPermit.extraAidNoKey] : [],
          pickupHelp: [{ id: 0, contactPhonenumber: contactPhonenumber }],
          flex: currentPermit.travelType.type.toLowerCase() === 'fx',
          customerGroup: currentPermit.customerGroup,
        };

        dispatch(setBookingAction(booking));
        setPermit(currentPermit);

        if (nodeId && customer && currentPermit) {
          switch (mode) {
            case 'retur':
              fetchOrderDetail({ swap: true, permit: currentPermit });
              break;
            case 'edit':
              fetchOrderDetail({ swap: false, permit: currentPermit });
              break;
            case 'favorit':
              fetchFavoriteDetails({ permit: currentPermit });
              break;
            default:
              break;
          }
        }
      }
    }

    return () => {
      dispatch(clearBookingAction());
    };
  },
  [
    history,
    nodeId,
    customer,
    mode,
    permits,
    permitId,
    settings,
    minimumMinutesUntilArrival,
    dispatch,
    fetchFavoriteDetails,
    fetchOrderDetail,
  ]);

  useEffect(() => {
    if (permit && settings.max_nbr_of_co_travellers_municipality) {
      const { municipality } = permit.travelType;
      const value = settings.max_nbr_of_co_travellers_municipality.value[municipality]
        ? settings.max_nbr_of_co_travellers_municipality.value[municipality]
        : 0;
      setMaxCoTravellers(value);
    }
  }, [settings.max_nbr_of_co_travellers_municipality, permit]);

  useEffect(() => {
    if (!permit && isFetchingPermits) {
      dispatch({ type: constants.ADD_LOADER, payload: 'userPermitsList' });
    } else {
      dispatch({ type: constants.REMOVE_LOADER, payload: 'userPermitsList' });
    }
  }, [dispatch, permit, isFetchingPermits]);

  useEffect(() => {
    setMinimumMinutesUntilArrival(parseInt(settings?.order_minimum_minutes_until_arrival?.value || 60));
  }, [settings]);

  return (
    <>
      {isAuthenticated && permit ? (
        <>
          <Helmet>
            <title>
              {`${mode === 'edit' ? 'Ändra' : 'Boka'} ${permit.travelType.name} | ${intl.formatMessage({
                id: `app.title.${window.__ENV__.REACT_APP_THEME}`,
              })} ${themes[window.__ENV__.REACT_APP_THEME].name}`}
            </title>
          </Helmet>
          <Mui.Container maxWidth='sm'>
            <Mui.Box pt={6} display='flex' justifyContent='center' alignItems='center'>
              <Mui.Box mr={2} aria-hidden='true'>
                {selectIconsHelper(permit.travelType)}
              </Mui.Box>
              <Mui.Typography variant='h1' className={classes.headline} tabIndex={0}>
                {permit.travelType.name}
              </Mui.Typography>
            </Mui.Box>

            <Mui.Box pb={6}>
              <form onSubmit={handleOnSubmit} id='BookTripForm' autoComplete='off'>
                <TripContainer
                  validDates={validDates}
                  dateHelperText={dateHelperText}
                  customerGroup={permit.customerGroup}
                  setValidationMessages={setTripValidationMessages}
                  ref={tripRef}
                />
                <TravellerContainer />
                <OptionsContainer
                  extraAidPermit={permit.extraAid}
                  extraAidNoKey={permit.extraAidNoKey}
                  maxCoTravellers={maxCoTravellers}
                  maxCompanions={permit.maxCompanions}
                  ref={optionsRef}
                />
                {!types.includes(type.toLowerCase()) && <InstructionsContainer travelType={permit.travelType} ref={instructionsRef} />}
                <Mui.Button
                  className={classes.bookButton}
                  fullWidth
                  type='submit'
                  variant='contained'
                  color='primary'
                  aria-disabled={disabled}
                  disableFocusRipple>
                  {mode === 'edit' ? (
                    <FormattedMessage id='bookTrip.editTrip' />
                  ) : (
                    <FormattedMessage id='bookTrip.bookTrip' />
                  )}
                </Mui.Button>
              </form>
            </Mui.Box>
          </Mui.Container>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default React.memo(BookTrip);
