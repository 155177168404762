import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

import store from './store/store';
import theme from './themes/themes';
import { LanguageProvider } from './translations/LanguageProvider';

console.info(`Version: ${process.env.REACT_APP_VERSION}`);

const root = ({ children }) => (
  <LanguageProvider>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </Provider>
  </LanguageProvider>
);
export default root;
