import CONSTANTS from '../constants';
import { systemSettings, systemVersion } from '../../repository/public';
import { userClearAuth } from '../auth/authActions';

export const systemSettingsAction = () => async (dispatch) => {
  try {
    dispatch({ type: CONSTANTS.ADD_LOADER, payload: 'systemSettingsAction' });
    const { settings } = await systemSettings();
    if (settings) {
      dispatch({ type: CONSTANTS.SET_SETTINGS, payload: settings });
      dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'systemSettingsAction' });
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'systemSettingsAction' });
    dispatch(userClearAuth());
  }
};

export const systemToggleSubNav = () => {
  return { type: CONSTANTS.TOGGLE_SUBNAV };
};

export const systemVersionAction = () => async (dispatch) => {
  try {
    dispatch({ type: CONSTANTS.ADD_LOADER, payload: 'systemVersionAction' });
    const data = await systemVersion();
    dispatch({ type: CONSTANTS.SET_VERSION, payload: data });
    console.table(data);
    dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'systemVersionAction' });
  } catch (error) {
    console.log(error);
    dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'systemVersionAction' });
  }
};
