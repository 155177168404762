import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import {
  agentTravellerRequestAccept,
  agentTravellerRequestDecline,
} from '../../repository/agent';

import { snackbarOpenAction } from '../../store/snackbar/snackbarActions';
import { dialogDisplayMessageAction } from '../../store/dialog/dialogActions';

import Mui from '../../components/material';
import UserInitials from '../../components/UserInitials';

import NoTravellersFlower from '../../images/no-travellers-flower.svg';
import { queryCache } from 'react-query';
import useTravellerRequestList from '../../hooks/useTravellerRequestList';
import { getDisplayName } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(3),
  },
}));

const AddTravellersContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { data: requests } = useTravellerRequestList();

  const handleRequestDecline = async (agentUsername) => {
    try {
      const { success } = await agentTravellerRequestDecline(agentUsername);
      if (success) {
        queryCache.invalidateQueries('agentTravellerList');
        queryCache.invalidateQueries('agentTravellerListPrimary');
        queryCache.invalidateQueries('userTravellerRequestList');
        dispatch(snackbarOpenAction('Avböj ombudsförfråga'));
      }
    } catch (error) {
      console.log(error);
      dispatch(dialogDisplayMessageAction(error.code));
    }
  };

  const handleRequestAccept = async (travellerUsername) => {
    try {
      const { success } = await agentTravellerRequestAccept(travellerUsername);
      if (success) {
        queryCache.invalidateQueries('agentTravellerList');
        queryCache.invalidateQueries('agentTravellerListPrimary');
        queryCache.invalidateQueries('userTravellerRequestList');
        dispatch(snackbarOpenAction('Ombudsförfrågan godkänd'));
      }
    } catch (error) {
      dispatch(dialogDisplayMessageAction(error.code));
    }
  };

  return (
    <>
      <Mui.Box mb={2}>
        <Mui.Typography variant="h2" tabIndex={0}>
          <FormattedMessage id="myPage.myTravellers.headingAdd" />
        </Mui.Typography>
      </Mui.Box>
      {requests && requests.length > 0 ? (
        requests.map((agent, i) => (
          <Mui.Card key={agent.id} className={classes.card}>
            <Mui.CardContent>
              <Mui.Box display='flex' alignItems='center'>
                <UserInitials user={agent} size='medium' />
                <Mui.Box flex='1' ml={3}>
                  <Mui.Typography variant='body1' tabIndex={0}>
                    {getDisplayName(agent)}
                  </Mui.Typography>
                </Mui.Box>
                <Mui.Box mx={2}>
                  <Mui.Button
                    variant='outlined'
                    color='primary'
                    onClick={() => handleRequestDecline(agent.username)}
                    disableFocusRipple>
                    Avböj
                  </Mui.Button>
                </Mui.Box>
                <Mui.Box mx={2}>
                  <Mui.Button
                    variant='contained'
                    color='primary'
                    onClick={() => handleRequestAccept(agent.username)}
                    disableFocusRipple>
                    Godkänn
                  </Mui.Button>
                </Mui.Box>
              </Mui.Box>
            </Mui.CardContent>
          </Mui.Card>
        ))
      ) : (
        <Mui.Card>
          <Mui.CardContent>
            <Mui.Box display='flex' alignItems='center'>
              <img src={NoTravellersFlower} alt='' />
              <Mui.Box ml={3} tabIndex={0}>
                <Mui.Typography variant='h3' color='primary'>
                  Du har inga obesvarade inbjudningar
                </Mui.Typography>
                <Mui.Typography variant='body1'>
                  Om någon har skickat en inbjudan till dig att vara deras ombud kommer de att visas här.{' '}
                </Mui.Typography>
              </Mui.Box>
            </Mui.Box>
          </Mui.CardContent>
        </Mui.Card>
      )}
    </>
  );
};

export default AddTravellersContainer;
