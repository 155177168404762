import CONSTANTS from '../../store/constants';

export const tripsIncrementOffsetAction = (offset) => {
  return { type: CONSTANTS.INCREMENT_OFFSET, payload: offset };
};

export const tripsResetOffsetAction = () => {
  return { type: CONSTANTS.RESET_OFFSET };
};

export const tripsClearTripsAction = () => {
  return { type: CONSTANTS.CLEAR_TRIPS };
};

export const tripsAddOrdersAction = (orders) => {
  return { type: CONSTANTS.ADD_ORDERS, payload: orders };
};
