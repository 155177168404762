import CONSTANTS from '../constants';

const initialState = {
  messages: [],
  permits: [],
  help: [],
  agent: [],
  contact: [],
  sitemap: [],
  links: [],
};

const versionHelp = {
  id: 999,
  type: "INTERN",
  url: "/version",
  name: "Version",
  content: "",
  description: "Intern sida",
  order: 0,
};

const cmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.SET_CMS:
      const links = [...action.payload['Länkar']];
      const integrityPolicy = links.find((item) => item.name === 'Integritetspolicy');
      const help = [...action.payload['Hjälp'], integrityPolicy, versionHelp].filter((item) => item.name !== 'Användarvillkor');

      return {
        ...state,
        messages: [...action.payload['Felmeddelanden'], ...action.payload['Informationstexter']],
        permits: action.payload['Ansök om tillstånd'],
        help,
        agent: action.payload['Jag vill bli ombud'],
        contact: action.payload['Kontakta oss'],
        sitemap: action.payload['Webbplatsen'],
        links,
      };
    default:
      return state;
  }
};

export default cmsReducer;
