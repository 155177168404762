import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { dialogUpdateFavoriteTripAction, dialogDeleteFavoriteTripAction } from '../../store/dialog/dialogActions';

import Mui from '../../components/material';
import Icon from '../../components/icons';
import useFavoriteTripsList from '../../hooks/useFavoriteTripsList';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
}));

const FavoriteTripsContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: favoriteTrips } = useFavoriteTripsList();

  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState(null);

  const handleClick = (event, trip) => {
    setAnchorEl(event.currentTarget);
    setSelected(trip);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelected(null);
  };

  const handleBookTrip = () => {
    const type = selected.orderJson.travellers[0].travelType.type;
    const travelTypeId = selected.orderJson.travellers[0].travelType.travelTypeId;
    history.push(`/boka-resa/favorit/${selected.id}/${type}/${travelTypeId}`);
  };

  const handleUpdateFavoriteTrip = () => {
    dispatch(dialogUpdateFavoriteTripAction(selected));
    handleClose();
  };

  const handleDeleteFavoriteTrip = () => {
    dispatch(dialogDeleteFavoriteTripAction(selected));
    handleClose();
  };

  return (
    <>
      <Mui.Accordion defaultExpanded>
        <Mui.AccordionSummary
          expandIcon={<Icon.ExpandMoreIcon color='primary' />}
          aria-controls='favorite-trips-content'
          id='favorite-trips-header'>
          <Mui.Typography className={classes.header} variant='h2'>
            <Icon.FavoriteRoundedIcon color='primary' className={classes.icon} />
            <FormattedMessage id='myPage.myFavourites.favouriteTrips' />
          </Mui.Typography>
        </Mui.AccordionSummary>
        <Mui.AccordionDetails>
          {favoriteTrips &&
            favoriteTrips
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((trip) => (
                <Mui.Box display='flex' flexGrow={1} flexDirection='column' key={trip.id}>
                  <Mui.Box display='flex' alignItems='center'>
                    <Mui.Box display='flex' alignItems='center' flexGrow={1}>
                      <Icon.DriveEtaRoundedIcon
                        color='primary'
                        className={classes.icon}
                        titleAccess={`${trip.orderJson.travellers[0].travelType.name}`}
                      />
                      <Mui.Typography variant='h3' tabIndex={0}>{trip.name}</Mui.Typography>
                    </Mui.Box>
                    <Mui.Box>
                      <Mui.IconButton
                        aria-label='Mer val'
                        aria-controls='trip-menu'
                        aria-haspopup='true'
                        tabIndex={0}
                        onClick={(event) => handleClick(event, trip)}>
                        <Icon.MoreVertIcon color='primary' titleAccess='Mer val' />
                      </Mui.IconButton>
                    </Mui.Box>
                  </Mui.Box>
                  <Mui.Box mb={2} tabIndex={0}>
                    <Mui.Typography variant='body1' className={classes.address}>
                      <FormattedMessage id='start.from' />
                      {trip.orderJson.pickup.strName} {trip.orderJson.pickup.strNbr}
                      {trip.orderJson.pickup.strNbrLetter}, {trip.orderJson.pickup.city}
                    </Mui.Typography>
                    <Mui.Typography variant='body1' className={classes.address}>
                      <FormattedMessage id='start.to' />
                      {trip.orderJson.dropoff.strName} {trip.orderJson.dropoff.strNbr}
                      {trip.orderJson.dropoff.strNbrLetter}, {trip.orderJson.dropoff.city}
                    </Mui.Typography>
                  </Mui.Box>
                  <Mui.Divider variant='fullWidth' />
                </Mui.Box>
              ))}
        </Mui.AccordionDetails>
      </Mui.Accordion>
      <Mui.Menu
        id='trip-menu'
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <Mui.MenuItem onClick={handleBookTrip}>
          <Icon.DirectionsRoundedIcon color='primary' />
          <Mui.Box ml={2}>
            <FormattedMessage id='myPage.myFavourites.ddBookTrip' />
          </Mui.Box>
        </Mui.MenuItem>
        <Mui.MenuItem onClick={handleUpdateFavoriteTrip}>
          <Icon.EditRoundedIcon color='primary' />
          <Mui.Box ml={2}>
            <FormattedMessage id='myPage.myFavourites.ddRenameFav' />
          </Mui.Box>
        </Mui.MenuItem>
        <Mui.MenuItem onClick={handleDeleteFavoriteTrip}>
          <Icon.DeleteRoundedIcon color='primary' />
          <Mui.Box ml={2}>
            <FormattedMessage id='myPage.myFavourites.ddRemoveFav' />
          </Mui.Box>
        </Mui.MenuItem>
      </Mui.Menu>
    </>
  );
};

export default React.memo(FavoriteTripsContainer);
