import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import { userFavoriteTripCreate } from '../../repository/user';

import { snackbarOpenAction } from '../../store/snackbar/snackbarActions';
import { dialogCloseAllAction } from '../../store/dialog/dialogActions';

import Mui from '../material';

const CreateFavoriteTripDialog = ({ open, handleOnClose }) => {
  const dispatch = useDispatch();
  const { content } = useSelector((state) => state.dialog);

  const [value, setValue] = useState('');

  const handleOnSubmit = async () => {
    const request = {
      orderJson: {
        dropoff: content.dropoff,
        pickup: content.pickup,
        travellers: content.travellers,
      },
      name: value,
    };
    const { success } = await userFavoriteTripCreate(request);
    dispatch(dialogCloseAllAction());

    if (success) {
      dispatch(snackbarOpenAction('Resan är sparad som favorit'));
    } else {
      dispatch(snackbarOpenAction('Resan kunde inte sparas som favorit'));
    }
  };

  return (
    <Mui.Dialog fullWidth maxWidth='xs' open={open} onClose={handleOnClose} aria-labelledby='dialog-title dialog-content'>
      <Mui.DialogTitle id='dialog-title'>
        <FormattedMessage id='bookTripReceipt.favNameTitle' />
      </Mui.DialogTitle>
      <Mui.DialogContent id="dialog-content">
        <Mui.Typography>
          Från: {content.pickup.strName} {content.pickup.strNbr}
          {content.pickup.strNbrLetter}, {content.pickup.city}
        </Mui.Typography>
        <Mui.Typography>
          Till: {content.dropoff.strName} {content.dropoff.strNbr}
          {content.dropoff.strNbrLetter}, {content.dropoff.city}
        </Mui.Typography>
        <Mui.TextField
          fullWidth
          margin='normal'
          id='favNamePlaceholder'
          value={value}
          label={<FormattedMessage id='bookTripReceipt.favNamePlaceholder' />}
          onChange={(event) => setValue(event.target.value)}
        />
      </Mui.DialogContent>
      <Mui.DialogActions id="dialog-actions">
        <Mui.Button onClick={handleOnClose} color='primary' disableFocusRipple>
          <FormattedMessage id='dialog.btnCancel' />
        </Mui.Button>
        <Mui.Button onClick={handleOnSubmit} color='primary' disabled={value.length === 0} disableFocusRipple>
          <FormattedMessage id='dialog.btnConfirm' />
        </Mui.Button>
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};

export default React.memo(CreateFavoriteTripDialog);
