import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import CONSTANTS from '../../store/constants';
import { snackbarOpenAction } from '../../store/snackbar/snackbarActions';
import { dialogCloseAllAction, dialogDisplayMessageAction } from '../../store/dialog/dialogActions';
import { userClearAuth } from '../../store/auth/authActions';

import Mui from '../material';
import { userDelete } from '../../repository/user';

const useStyles = makeStyles(() => ({
  content: {
    '& p:first-of-type': {
      marginTop: 0,
    },
  },
}));

const DeleteUserDialog = ({ open, handleOnClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { messages } = useSelector((state) => state.cms);
  const content = messages?.find(message => message.name === 'Ta bort konto info')?.content || '';

  const handleOnSubmit = async () => {
    try {
      dispatch({ type: CONSTANTS.ADD_LOADER, payload: 'userDelete' });
      dispatch(dialogCloseAllAction());
      const { success } = await userDelete();

      if (success) {
        dispatch(userClearAuth());
        localStorage.removeItem('login-tab');
        localStorage.removeItem('bankid-username');
        localStorage.removeItem('ssn-username');
        localStorage.removeItem('gdpr');
        dispatch(snackbarOpenAction('Din användare är borttagen'));
        history.push('/');
      } else {
        dispatch(dialogDisplayMessageAction('Det gick inte att ta bort dig som användare'));
      }

      dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'userDelete' });
    } catch (error) {
      dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'userDelete' });
      dispatch(dialogDisplayMessageAction(error.code));
    }
  };

  return (
    <Mui.Dialog fullWidth maxWidth='xs' open={open} onClose={handleOnClose} aria-labelledby='dialog-title dialog-content dialog-actions'>
      <Mui.DialogTitle id='dialog-title'>
        <FormattedMessage id='myPage.deleteUser.heading' />
      </Mui.DialogTitle>
      <Mui.DialogContent id="dialog-content" aria-label={content.replace(/(<([^>]+)>)/gi, '')}>
        <Mui.Box className={classes.content}>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Mui.Box>
      </Mui.DialogContent>
      <Mui.DialogActions id="dialog-actions">
        <Mui.Button onClick={handleOnClose} color='primary' disableFocusRipple>
          <FormattedMessage id='dialog.btnCancel' />
        </Mui.Button>
        <Mui.Button onClick={handleOnSubmit} color='primary' disableFocusRipple>
          <FormattedMessage id='myPage.deleteUser.btnRemove' />
        </Mui.Button>
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};

export default React.memo(DeleteUserDialog);
