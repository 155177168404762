import CONSTANTS from '../constants';
import { isMobileOnly } from 'react-device-detect';

const initialState = {
  loaders: [],
  settings: {},
  version: {},
  subNavBarIsOpen: !isMobileOnly,
};

const systemReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.ADD_LOADER:
      return {
        ...state,
        loaders: [...state.loaders, action.payload],
      };

    case CONSTANTS.REMOVE_LOADER:
      return {
        ...state,
        loaders: state.loaders.filter((loader) => loader !== action.payload),
      };

    case CONSTANTS.TOGGLE_SUBNAV:
      return {
        ...state,
        subNavBarIsOpen: !state.subNavBarIsOpen,
      };

    case CONSTANTS.SET_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
  
    case CONSTANTS.SET_VERSION:
      return {
        ...state,
        version: action.payload.version,
      };
  
    default:
      return state;
  }
};

export default systemReducer;
