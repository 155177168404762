import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { themes } from '../themes/themes';
import { useSelector } from 'react-redux';
import Mui from '../components/material';

import createAccountImage from '../images/create-account@3x.jpg';
import Banner from '../components/Banner';
import CreatePasswordForm from '../components/forms/CreatePasswordForm';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.5rem',
  },
  media: {
    maxWidth: '100%',
  },
}));

const CreateAccount = () => {
  const intl = useIntl();
  const classes = useStyles();

  const { messages } = useSelector((state) => state.cms);

  const getCreateAccountText = () => {
    if (messages) {
      const result = messages.find((item) => item.name === 'Skapa konto');
      if (result) return result.content;
      return '';
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {`Skapa konto | ${intl.formatMessage({ id: `app.title.${window.__ENV__.REACT_APP_THEME}` })} ${
            themes[window.__ENV__.REACT_APP_THEME].name
          }`}
        </title>
      </Helmet>
      <Banner />
      <Mui.Container maxWidth='md'>
        <Mui.Box pt={6} pb={3}>
          <Mui.Typography variant='h1' className={classes.headline} tabIndex={0}>
            Skapa konto
          </Mui.Typography>
        </Mui.Box>
        <Mui.Box pb={6}>
          <Mui.Grid container spacing={2}>
            <Mui.Grid item sm={6}>
              <Mui.Card>
                <Mui.CardContent>
                  <Mui.Typography variant="body1" color="intial" tabIndex={0}>
                    <FormattedMessage id="createAccount.userAccountInfo" />
                  </Mui.Typography>
                  <CreatePasswordForm />
                </Mui.CardContent>
              </Mui.Card>
            </Mui.Grid>
            <Mui.Grid item sm={6}>
              <Mui.Card>
                <Mui.CardContent>
                  <img src={createAccountImage} alt='' className={classes.media} />
                  <Mui.Typography variant='h2' color='primary' className={classes.title} tabIndex={0}>
                    Skapa konto
                  </Mui.Typography>
                  <Mui.Typography variant='body1' color='initial' tabIndex={0} dangerouslySetInnerHTML={{ __html: getCreateAccountText() }}/>
                </Mui.CardContent>
              </Mui.Card>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Box>
      </Mui.Container>
    </>
  );
};

export default React.memo(CreateAccount);
