const SET_INVALID_DATETIME = 'SET_INVALID_DATETIME';

// CMS
const SET_CMS = 'SET_CMS';

// Auth
const SET_TOKEN = 'SET_TOKEN';
const SET_PRIMARY_TOKEN = 'SET_PRIMARY_TOKEN';
const SET_PRIMARY_USER = 'SET_PRIMARY_USER';
const SET_USER = 'SET_USER';
const SET_TRAVELLERS = 'SET_TRAVELLERS';
const CLEAR_AUTH = 'CLEAR_AUTH';

// Book Trip
const SET_BOOKING = 'SET_BOOKING';
const SWAP_ADDESSES = 'SWAP_ADDESSES';
const SET_DROPOFF_ADDRESS = 'SET_DROPOFF_ADDRESS';
const SET_PICKUP_ADDRESS = 'SET_PICKUP_ADDRESS';
const SET_DROPOFF_LETTER = 'SET_DROPOFF_LETTER';
const SET_PICKUP_LETTER = 'SET_PICKUP_LETTER';
const SET_REQUEST_PICKUP_TIME = 'SET_REQUEST_PICKUP_TIME';
const SET_REQUEST_DROPOFF_TIME = 'SET_REQUEST_DROPOFF_TIME';
const SET_EXTRA_AIDS = 'SET_EXTRA_AIDS';
const SET_PICKUP_HELP = 'SET_PICKUP_HELP';
const SET_DROPOFF_HELP = 'SET_DROPOFF_HELP';
const SET_NBR_COMPANIONS = 'SET_NBR_COMPANIONS';
const SET_CO_TRAVELLERS = 'SET_CO_TRAVELLERS';
const CLEAR_BOOKING = 'CLEAR_BOOKING';

// Permits
const CLEAR_PERMITS = 'CLEAR_PERMITS';
const SET_PERMITS_LOADING = 'SET_PERMITS_LOADING';
const SET_PERMITS_LIST = 'SET_PERMITS_LIST';
const SELECT_PERMIT = 'SELECT_PERMIT';

// Dialog
const OPEN_DIALOG = 'OPEN_DIALOG';
const CLOSE_DIALOG = 'CLOSE_DIALOG';
const CLOSE_ALL_DIALOG = 'CLOSE_ALL_DIALOG';

// Snackbar
const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';

// Settings
const SET_SETTINGS = 'SET_SETTINGS';
const TOGGLE_SUBNAV = 'TOGGLE_SUBNAV';
const ADD_LOADER = 'ADD_LOADER';
const REMOVE_LOADER = 'REMOVE_LOADER';

// Version
const SET_VERSION = 'SET_VERSION';

// Trips
const INCREMENT_OFFSET = "INCREMENT_OFFSET";
const RESET_OFFSET = "RESET_OFFSET";
const ADD_ORDERS = "ADD_ORDERS";
const CLEAR_TRIPS = "CLEAR_TRIPS";

const constants = {
  SET_INVALID_DATETIME,
  SET_CMS,
  SET_SETTINGS,
  SET_VERSION,
  TOGGLE_SUBNAV,
  ADD_LOADER,
  REMOVE_LOADER,

  SET_TOKEN,
  SET_PRIMARY_TOKEN,
  SET_USER,
  SET_PRIMARY_USER,
  SET_TRAVELLERS,
  CLEAR_AUTH,

  SET_BOOKING,
  SWAP_ADDESSES,
  SET_DROPOFF_ADDRESS,
  SET_PICKUP_ADDRESS,
  SET_DROPOFF_LETTER,
  SET_PICKUP_LETTER,
  SET_REQUEST_DROPOFF_TIME,
  SET_REQUEST_PICKUP_TIME,
  SET_EXTRA_AIDS,
  SET_PICKUP_HELP,
  SET_DROPOFF_HELP,
  SET_NBR_COMPANIONS,
  SET_CO_TRAVELLERS,
  CLEAR_BOOKING,

  CLEAR_PERMITS,
  SET_PERMITS_LOADING,
  SET_PERMITS_LIST,
  SELECT_PERMIT,

  OPEN_DIALOG,
  CLOSE_DIALOG,
  CLOSE_ALL_DIALOG,
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR,

  INCREMENT_OFFSET,
  RESET_OFFSET,
  ADD_ORDERS,
  CLEAR_TRIPS,
};
export default constants;
