import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import Icon from '../components/icons';

export const selectIconsHelper = ({ icon, name }, color = 'primary') => {
  return (
    <>
      {(() => {
        switch (icon) {
          case 'buss':
            return (
              <SvgIcon color={color} viewBox='0 0 22 24' titleAccess={name}>
                <path d='M9,17.7894737 C9,18.5305263 9.316875,19.1957895 9.8125,19.6589474 L9.8125,20.7368421 C9.8125,21.4357895 10.356875,22 11.03125,22 C11.705625,22 12.25,21.4357895 12.25,20.7368421 L12.25,20.3157895 L18.75,20.3157895 L18.75,20.7368421 C18.75,21.4273684 19.294375,22 19.96875,22 C20.635,22 21.1875,21.4357895 21.1875,20.7368421 L21.1875,19.6589474 C21.683125,19.1957895 22,18.5305263 22,17.7894737 L22,9.36842105 C22,6.42105263 19.09125,6 15.5,6 C11.90875,6 9,6.42105263 9,9.36842105 L9,17.7894737 Z M12,18 C11.4466667,18 11,17.5533333 11,17 C11,16.4466667 11.4466667,16 12,16 C12.5533333,16 13,16.4466667 13,17 C13,17.5533333 12.5533333,18 12,18 Z M19,18 C18.4466667,18 18,17.5533333 18,17 C18,16.4466667 18.4466667,16 19,16 C19.5533333,16 20,16.4466667 20,17 C20,17.5533333 19.5533333,18 19,18 Z M3,9 C2.44771525,9 2,8.55228475 2,8 L2,3 C2,2.44771525 2.44771525,2 3,2 L8,2 C8.55228475,2 9,2.44771525 9,3 L9,8 C9,8.55228475 8.55228475,9 8,9 L6,9 L6,22 L5,22 L5,9 L3,9 Z M20,9 L20,13 L11,13 L11,9 L20,9 Z'></path>
              </SvgIcon>
            );
          case 'taxi':
            return (
              <SvgIcon color={color} viewBox='0 0 22 22' titleAccess={name}>
                <path d='M18.92,5.01 C18.72,4.42 18.16,4 17.5,4 L6.5,4 C5.84,4 5.29,4.42 5.08,5.01 L3.11,10.68 C3.04,10.89 3,11.11 3,11.34 L3,18.5 C3,19.33 3.67,20 4.5,20 C5.33,20 6,19.33 6,18.5 L6,18 L18,18 L18,18.5 C18,19.32 18.67,20 19.5,20 C20.32,20 21,19.33 21,18.5 L21,11.34 C21,11.12 20.96,10.89 20.89,10.68 L18.92,5.01 Z M6.5,15 C5.67,15 5,14.33 5,13.5 C5,12.67 5.67,12 6.5,12 C7.33,12 8,12.67 8,13.5 C8,14.33 7.33,15 6.5,15 Z M17.5,15 C16.67,15 16,14.33 16,13.5 C16,12.67 16.67,12 17.5,12 C18.33,12 19,12.67 19,13.5 C19,14.33 18.33,15 17.5,15 Z M5,10 L6.27,6.18 C6.41,5.78 6.79,5.5 7.22,5.5 L16.78,5.5 C17.21,5.5 17.59,5.78 17.73,6.18 L19,10 L5,10 Z'></path>
              </SvgIcon>
            );
          case 'taxi_sh':
            return (
              <SvgIcon color={color} viewBox='0 0 24 24' titleAccess={name}>
                <path d='M21.7563999,1 C22.3063999,1 23,1.7244899 23,2.2744899 L23,8.74237052 C23,9.29237052 22.3063999,10 21.7563999,10 L17.6114109,10 L18.89,13.68 C18.96,13.89 19,14.12 19,14.34 L19,21.5 C19,22.33 18.32,23 17.5,23 C16.67,23 16,22.32 16,21.5 L16,21 L4,21 L4,21.5 C4,22.33 3.33,23 2.5,23 C1.67,23 1,22.33 1,21.5 L1,14.34 C1,14.11 1.04,13.89 1.11,13.68 L3.08,8.01 C3.29,7.42 3.84,7 4.5,7 L14.005,7 L14.005,2.2744899 C14.005,1.7244899 14.7138671,1 15.2638671,1 L21.7563999,1 Z M4.5,16 C3.67,16 3,16.67 3,17.5 C3,18.33 3.67,19 4.5,19 C5.33,19 6,18.33 6,17.5 C6,16.67 5.33,16 4.5,16 Z M15.5,16 C14.67,16 14,16.67 14,17.5 C14,18.33 14.67,19 15.5,19 C16.33,19 17,18.33 17,17.5 C17,16.67 16.33,16 15.5,16 Z M14.3549706,9.5 L4.5,9.5 L3,14 L17,14 L15.6666667,10 L15.2638671,10 C14.9607344,10 14.6093433,9.78504582 14.3549706,9.5 L14.3549706,9.5 Z M21,2 L16,2 C15.4477153,2 15,2.44771525 15,3 L15,3 L15,8 C15,8.55228475 15.4477153,9 16,9 L16,9 L21,9 C21.5522847,9 22,8.55228475 22,8 L22,8 L22,3 C22,2.44771525 21.5522847,2 21,2 L21,2 Z M18.9166667,3 C19.1458333,3 19.3333333,3.1875 19.3333333,3.41666667 L19.3333333,4.66666667 L20.5833333,4.66666667 C20.8125,4.66666667 21,4.85416667 21,5.08333333 L21,5.91666667 C21,6.14583333 20.8125,6.33333333 20.5833333,6.33333333 L19.3333333,6.33333333 L19.3333333,7.58333333 C19.3333333,7.8125 19.1458333,8 18.9166667,8 L18.0833333,8 C17.8541667,8 17.6666667,7.8125 17.6666667,7.58333333 L17.6666667,6.33333333 L16.4166667,6.33333333 C16.1875,6.33333333 16,6.14583333 16,5.91666667 L16,5.08333333 C16,4.85416667 16.1875,4.66666667 16.4166667,4.66666667 L17.6666667,4.66666667 L17.6666667,3.41666667 C17.6666667,3.1875 17.8541667,3 18.0833333,3 L18.9166667,3 Z'></path>
              </SvgIcon>
            );
          case 'flex':
            return (
              <SvgIcon color={color} viewBox='0 0 24 24' titleAccess={name}>
                <path d='M9,17.7894737 C9,18.5305263 9.316875,19.1957895 9.8125,19.6589474 L9.8125,20.7368421 C9.8125,21.4357895 10.356875,22 11.03125,22 C11.705625,22 12.25,21.4357895 12.25,20.7368421 L12.25,20.3157895 L18.75,20.3157895 L18.75,20.7368421 C18.75,21.4273684 19.294375,22 19.96875,22 C20.635,22 21.1875,21.4357895 21.1875,20.7368421 L21.1875,19.6589474 C21.683125,19.1957895 22,18.5305263 22,17.7894737 L22,9.36842105 C22,6.42105263 19.09125,6 15.5,6 C11.90875,6 9,6.42105263 9,9.36842105 L9,17.7894737 Z M12,18 C11.4466667,18 11,17.5533333 11,17 C11,16.4466667 11.4466667,16 12,16 C12.5533333,16 13,16.4466667 13,17 C13,17.5533333 12.5533333,18 12,18 Z M19,18 C18.4466667,18 18,17.5533333 18,17 C18,16.4466667 18.4466667,16 19,16 C19.5533333,16 20,16.4466667 20,17 C20,17.5533333 19.5533333,18 19,18 Z M3,9 C2.44771525,9 2,8.55228475 2,8 L2,3 C2,2.44771525 2.44771525,2 3,2 L8,2 C8.55228475,2 9,2.44771525 9,3 L9,8 C9,8.55228475 8.55228475,9 8,9 L6,9 L6,22 L5,22 L5,9 L3,9 Z M20,9 L20,13 L11,13 L11,9 L20,9 Z'></path>
              </SvgIcon>
            );
          case 'flex_sh':
            return (
              <SvgIcon color={color} viewBox='0 0 24 24' titleAccess={name}>
                <path d='M9,17.7894737 C9,18.5305263 9.316875,19.1957895 9.8125,19.6589474 L9.8125,20.7368421 C9.8125,21.4357895 10.356875,22 11.03125,22 C11.705625,22 12.25,21.4357895 12.25,20.7368421 L12.25,20.3157895 L18.75,20.3157895 L18.75,20.7368421 C18.75,21.4273684 19.294375,22 19.96875,22 C20.635,22 21.1875,21.4357895 21.1875,20.7368421 L21.1875,19.6589474 C21.683125,19.1957895 22,18.5305263 22,17.7894737 L22,9.36842105 C22,6.42105263 19.09125,6 15.5,6 C11.90875,6 9,6.42105263 9,9.36842105 L9,17.7894737 Z M12,18 C11.4466667,18 11,17.5533333 11,17 C11,16.4466667 11.4466667,16 12,16 C12.5533333,16 13,16.4466667 13,17 C13,17.5533333 12.5533333,18 12,18 Z M19,18 C18.4466667,18 18,17.5533333 18,17 C18,16.4466667 18.4466667,16 19,16 C19.5533333,16 20,16.4466667 20,17 C20,17.5533333 19.5533333,18 19,18 Z M3,9 C2.44771525,9 2,8.55228475 2,8 L2,3 C2,2.44771525 2.44771525,2 3,2 L8,2 C8.55228475,2 9,2.44771525 9,3 L9,8 C9,8.55228475 8.55228475,9 8,9 L6,9 L6,22 L5,22 L5,9 L3,9 Z M20,9 L20,13 L11,13 L11,9 L20,9 Z M5.91666667,3 L5.08333333,3 C4.85416667,3 4.66666667,3.1875 4.66666667,3.41666667 L4.66666667,3.41666667 L4.66666667,4.66666667 L3.41666667,4.66666667 C3.1875,4.66666667 3,4.85416667 3,5.08333333 L3,5.08333333 L3,5.91666667 C3,6.14583333 3.1875,6.33333333 3.41666667,6.33333333 L3.41666667,6.33333333 L4.66666667,6.33333333 L4.66666667,7.58333333 C4.66666667,7.8125 4.85416667,8 5.08333333,8 L5.08333333,8 L5.91666667,8 C6.14583333,8 6.33333333,7.8125 6.33333333,7.58333333 L6.33333333,7.58333333 L6.33333333,6.33333333 L7.58333333,6.33333333 C7.8125,6.33333333 8,6.14583333 8,5.91666667 L8,5.91666667 L8,5.08333333 C8,4.85416667 7.8125,4.66666667 7.58333333,4.66666667 L7.58333333,4.66666667 L6.33333333,4.66666667 L6.33333333,3.41666667 C6.33333333,3.1875 6.14583333,3 5.91666667,3 L5.91666667,3 Z'></path>
              </SvgIcon>
            );

          default:
            return (
              <SvgIcon color='disabled' viewBox='0 0 22 22' titleAccess='disabled'>
                <path d='M18.92,5.01 C18.72,4.42 18.16,4 17.5,4 L6.5,4 C5.84,4 5.29,4.42 5.08,5.01 L3.11,10.68 C3.04,10.89 3,11.11 3,11.34 L3,18.5 C3,19.33 3.67,20 4.5,20 C5.33,20 6,19.33 6,18.5 L6,18 L18,18 L18,18.5 C18,19.32 18.67,20 19.5,20 C20.32,20 21,19.33 21,18.5 L21,11.34 C21,11.12 20.96,10.89 20.89,10.68 L18.92,5.01 Z M6.5,15 C5.67,15 5,14.33 5,13.5 C5,12.67 5.67,12 6.5,12 C7.33,12 8,12.67 8,13.5 C8,14.33 7.33,15 6.5,15 Z M17.5,15 C16.67,15 16,14.33 16,13.5 C16,12.67 16.67,12 17.5,12 C18.33,12 19,12.67 19,13.5 C19,14.33 18.33,15 17.5,15 Z M5,10 L6.27,6.18 C6.41,5.78 6.79,5.5 7.22,5.5 L16.78,5.5 C17.21,5.5 17.59,5.78 17.73,6.18 L19,10 L5,10 Z'></path>
              </SvgIcon>
            );
        }
      })()}
    </>
  );
};

export const getTypeIcon = (type, disabled = false) => {
  const iconColor = disabled ? 'disabled' : 'primary';
  switch (type) {
    case 'kart':
      return <Icon.HomeRoundedIcon color={iconColor} titleAccess="Adress" />;
    case 'plag':
      return <Icon.FlagRoundedIcon color={iconColor} titleAccess="Intressepunkt" />;
    case 'customer':
      return <Icon.HomeRoundedIcon color={iconColor} titleAccess="Adress" />;
    case 'hpla':
      return <Icon.DirectionsBusRoundedIcon color={iconColor} titleAccess="Mötesplats" />;
    case 'egen':
      return <Icon.FlagRoundedIcon color={iconColor} titleAccess="Intressepunkt" />;
    default:
      return <Icon.HomeRoundedIcon color={iconColor} titleAccess="Adress" />;
  }
};

export const getColor = (user) => {
  const arrGreen = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
  const arrPurple = ['K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T'];
  const arrOcean = ['U', 'V', 'W', 'X', 'Y', 'Z', 'Å', 'Ä', 'Ö'];
  
  const { firstname, username } = user;
  const first = firstname?.charAt(0) || username.charAt(0);
  if (arrGreen.includes(first)) {
    return 'green';
  } else if (arrPurple.includes(first)) {
    return 'purple';
  } else if (arrOcean.includes(first)) {
    return 'ocean';
  } else {
    return 'none';
  }
};

export const getInitials = (user) => {
  const { firstname, lastname, username } = user;

  if (firstname && lastname) {
    const first = firstname.charAt(0).toUpperCase();
    const last = lastname.charAt(0).toUpperCase();
    return `${first}${last}`;
  }

  return username.charAt(0).toUpperCase();
};

export const getDisplayName = (user) => {
  const { firstname, lastname, username } = user;

  if (firstname && lastname) {
    return `${firstname} ${lastname}`;
  }

  return username;
};
