import CONSTANTS from '../constants';

const initialState = {
  travelTypeID: '',
  nbrCompanions: 0,
  nbrCoTravellers: 0,
  noteToCCandVehicle: '',
  requestPickupTime: '',
  requestDropOffTime: '',
  pickupAddress: null,
  pickupLetter: '',
  pickupHelp: [{ id: 0, contactPhonenumber: '' }],
  dropoffAddress: null,
  dropoffLetter: '',
  dropoffHelp: [{ id: 0, contactPhonenumber: '' }],
  extraAids: [],
  flex: false,
};

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.INIT_BOOKING:
      return {
        ...state,
        travelTypeID: action.payload.travelTypeID,
      };
    case CONSTANTS.SET_BOOKING:
      return {
        ...state,
        ...action.payload,
      };
    case CONSTANTS.SWAP_ADDESSES:
      return {
        ...state,
        pickupAddress: state.dropoffAddress,
        pickupLetter: state.dropoffLetter,
        dropoffAddress: state.pickupAddress,
        dropoffLetter: state.pickupLetter,
      };
    case CONSTANTS.SET_PICKUP_LETTER:
      return {
        ...state,
        pickupLetter: action.payload,
      };
    case CONSTANTS.SET_DROPOFF_LETTER:
      return {
        ...state,
        dropoffLetter: action.payload,
      };
    case CONSTANTS.SET_DROPOFF_ADDRESS:
      return {
        ...state,
        dropoffAddress: action.payload,
      };
    case CONSTANTS.SET_PICKUP_ADDRESS:
      return {
        ...state,
        pickupAddress: action.payload,
      };
    case CONSTANTS.SET_REQUEST_PICKUP_TIME:
      return {
        ...state,
        requestDropOffTime: '',
        requestPickupTime: action.payload,
      };
    case CONSTANTS.SET_REQUEST_DROPOFF_TIME:
      return {
        ...state,
        requestPickupTime: '',
        requestDropOffTime: action.payload,
      };
    case CONSTANTS.SET_PICKUP_HELP:
      return {
        ...state,
        pickupHelp: [action.payload],
      };
    case CONSTANTS.SET_DROPOFF_HELP:
      return {
        ...state,
        dropoffHelp: [action.payload],
      };
    case CONSTANTS.SET_EXTRA_AIDS:
      return {
        ...state,
        extraAids: [...action.payload],
      };
    case CONSTANTS.SET_NBR_COMPANIONS:
      return {
        ...state,
        nbrCompanions: action.payload,
      };
    case CONSTANTS.SET_CO_TRAVELLERS:
      return {
        ...state,
        nbrCoTravellers: action.payload,
      };
    case CONSTANTS.CLEAR_BOOKING:
      return initialState;

    default:
      return state;
  }
};

export default bookingReducer;
