import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { dialogDisplayMessageAction } from '../../store/dialog/dialogActions';
import { snackbarOpenAction } from '../../store/snackbar/snackbarActions';
import { agentUserRequest } from '../../repository/agent';
import { convertSSN } from '../../utils/validators';

import Mui from '../../components/material';

const AddAgentContainer = () => {
  const dispatch = useDispatch();
  const [agentUsername, setAgentUsername] = useState('');

  const handleOnClick = async () => {
    try {
      const { success } = await agentUserRequest(convertSSN(agentUsername));
      if (success) {
        setAgentUsername('');
        dispatch(snackbarOpenAction('Ombudsförfrågan'));
      }
    } catch (error) {
      console.log(error);
      dispatch(dialogDisplayMessageAction(error.code));
    }
  };

  return (
    <Mui.Box mb={6}>
      <Mui.Typography variant='h2' color='primary' tabIndex={0}>
        <FormattedMessage id='myPage.myAgents.headingAdd' />
      </Mui.Typography>

      <Mui.Grid container spacing={2} alignItems='flex-end'>
        <Mui.Grid item xs={12} sm={6}>
          <Mui.TextField
            fullWidth
            margin='normal'
            id='labelAdd'
            autoComplete='chrome-off'
            label={<FormattedMessage id='myPage.myAgents.labelAdd' />}
            value={agentUsername}
            onChange={(e) => setAgentUsername(e.target.value)}
          />
        </Mui.Grid>
        <Mui.Grid item xs={12} sm={4}>
          <Mui.FormControl margin='normal' fullWidth>
            <Mui.Button variant='outlined' color='primary' onClick={handleOnClick} disableFocusRipple>
              <FormattedMessage id='myPage.myAgents.btnAdd' />
            </Mui.Button>
          </Mui.FormControl>
        </Mui.Grid>
      </Mui.Grid>
    </Mui.Box>
  );
};

export default AddAgentContainer;
