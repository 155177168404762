import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { validateSSN, convertSSN } from '../../utils/validators';

import { dialogDisplayMessageAction } from '../../store/dialog/dialogActions';
import { userClearAuth } from '../../store/auth/authActions';
import { userCreate } from '../../repository/user';

import CONSTANTS from '../../store/constants';
import Mui from '../material';

const CreatePasswordForm = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const { links } = useSelector((state) => state.cms);

  const [disabled, setDisabled] = useState(true);
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState(false);
  const [usernameHelpText, setUsernameHelpText] = useState('Skriv personnumret med 12 siffror');
  const [gdprAgreement, setGdprAgreement] = useState(false);

  useEffect(() => {
    const arr = [!!username, gdprAgreement, !usernameError];

    if (arr.every((v) => v === true)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [username, gdprAgreement, usernameError]);

  const handleOnSubmit = async (event) => {
    event.preventDefault();

    if (disabled) {
      return;
    }

    try {
      dispatch({ type: CONSTANTS.ADD_LOADER, payload: 'userCreate' });
      const user = {
        username: convertSSN(username),
        firstname: '',
        lastname: '',
        email: '',
        accountType: 'person',
        gdprAgreement: 'agreed',
      };
      const { success } = await userCreate(user);

      if (success) {
        dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'userCreate' });
        dispatch(dialogDisplayMessageAction('Kontobegäran har skickats'));
        localStorage.setItem('login-tab', 1);
        history.push('/');
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'userCreate' });
      dispatch(userClearAuth());
      dispatch(dialogDisplayMessageAction(error.code));
    }
  };

  const getUserAgreementUrl = () => {
    if (links) {
      const content = links.find((item) => item.name === 'Integritetspolicy');
      if (content) return content.url;
      return '';
    }
  };

  return (
    <form onSubmit={handleOnSubmit} id='CreatePasswordForm' autoComplete='off'>
      <Mui.FormGroup row>
        <Mui.TextField
          required
          fullWidth
          margin='normal'
          error={usernameError}
          value={username}
          variant='outlined'
          id='outlined-helperText'
          label='Personnummer'
          autoComplete='off'
          onBlur={(e) => {
            const { error, helptext } = validateSSN(convertSSN(e.target.value));
            setUsernameError(error);
            setUsernameHelpText(helptext);
          }}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
          helperText={usernameHelpText}
        />

        <Mui.Box mb={4}>
          <Mui.FormControl margin='normal' fullWidth>
            <Mui.Typography
              tabIndex={0}
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage(
                  { id: 'createAccount.acceptInfoLink' },
                  {
                    0: `<a href='${getUserAgreementUrl()}' target='_blank' rel='noopener noreferrer'>`,
                    1: `</a>`,
                  }
                ),
              }}
            />
            <Mui.FormGroup>
              <Mui.FormControlLabel
                control={
                  <Mui.Checkbox
                    checked={gdprAgreement}
                    onChange={(e) => setGdprAgreement(e.target.checked)}
                    name='gdprAgreement'
                    color='primary'
                  />
                }
                label={intl.formatMessage({ id: 'createAccount.acceptInfo' })}
              />
            </Mui.FormGroup>
          </Mui.FormControl>
        </Mui.Box>

        <Mui.Button
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          disableElevation
          disableFocusRipple
          aria-disabled={disabled}
          style={{backgroundColor: disabled ? 'rgba(0, 0, 0, 0.12)' : null, color: disabled ? 'rgba(0, 0, 0, 0.26)' : null, border: disabled ? '0' : null}}>
          Skapa konto
        </Mui.Button>
      </Mui.FormGroup>
    </form>
  );
};

export default React.memo(CreatePasswordForm);
