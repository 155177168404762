import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';

import { snackbarCloseAction } from '../../store/snackbar/snackbarActions';

import Mui from '../../components/material';

import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
  content: {
    '& p': {
      margin: 0,
    },
    '& a': {
      color: theme.palette.primary.main,
    },
  },
}));

const SnackbarContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { messages } = useSelector((state) => state.cms);
  const { open, message } = useSelector((state) => state.snackbar);

  const [description, setDescription] = useState('');

  const handleClose = () => {
    dispatch(snackbarCloseAction());
  };

  useEffect(() => {
    if (messages && message) {
      const msg = messages.find(
        (msg) => JSON.stringify(msg.name).replace(/"/g, '') === JSON.stringify(message).replace(/"/g, '')
      );

      if (msg) {
        setDescription(msg.content);
      } else {
        setDescription(message);
      }
    }
  }, [message, messages]);

  return (
    <Snackbar
      autoHideDuration={4000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      onClose={handleClose}
      TransitionComponent={Slide}
      message={<Mui.Box className={classes.content} dangerouslySetInnerHTML={{ __html: description }}></Mui.Box>}
      key={'snackbar'}
    />
  );
};

export default SnackbarContainer;
