import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

import Mui from '../../components/material';
import Icon from '../../components/icons';
import { userAddressesCustomer } from '../../repository/user';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  address: {
    marginBottom: theme.spacing(1),
  },
  city: {
    marginBottom: theme.spacing(1),
  },
}));

const CustomerAddressesContainer = () => {
  const classes = useStyles();

  const [customerAddresses, setCustomerAddresses] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { customerAddresses } = await userAddressesCustomer();
      setCustomerAddresses(customerAddresses);
    };

    fetchData();
  }, []);

  return (
    <>
      <Mui.Accordion>
        <Mui.AccordionSummary
          expandIcon={<Icon.ExpandMoreIcon color='primary' />}
          aria-controls='customer-addresses-content'
          id='customer-addresses-header'>
          <Mui.Typography className={classes.header} variant='h2'>
            <Icon.AttachmentRoundedIcon color='primary' className={classes.icon} />
            <FormattedMessage id='myPage.myFavourites.addressInstructions' />
          </Mui.Typography>
        </Mui.AccordionSummary>
        <Mui.AccordionDetails>
          {customerAddresses
            .sort((a, b) => a.strName.localeCompare(b.strName))
            .map((address, idx) => (
              <Mui.Box display='flex' flexGrow={1} flexDirection='column' key={idx} mb={2}>
                <Mui.Box display='flex' alignItems='center'>
                  <Mui.Box display='flex' alignItems='center' flexGrow={1}>
                    <Mui.Typography variant='h3' className={classes.address}>
                      {address.strName} {address.strNbr}
                      {address.strNbrLetter}
                    </Mui.Typography>
                  </Mui.Box>
                </Mui.Box>
                <Mui.Box mb={2}>
                  <Mui.Typography variant='h3' className={classes.city}>
                    {address.city}
                  </Mui.Typography>
                  <Mui.Typography variant='body1'>
                    {address.addrNotePickup}
                    <br />
                    {address.addrNoteDropoff}
                  </Mui.Typography>
                </Mui.Box>
                <Mui.Divider variant='fullWidth' />
              </Mui.Box>
            ))}
        </Mui.AccordionDetails>
      </Mui.Accordion>
    </>
  );
};

export default React.memo(CustomerAddressesContainer);
