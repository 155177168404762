import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import { flatten } from 'flat';

import messages_sv from './sv.json';
// import messages_en from './en.json';

const messages = {
  sv: messages_sv,
};

export const LanguageContext = React.createContext(undefined);

export const LanguageProvider = ({ children }) => {
  // navigator.language.split(/[-_]/)[0]
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'sv');

  const changeLanguage = (lang) => {
    localStorage.setItem('language', lang);
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      <IntlProvider locale={language} messages={flatten(messages[language])}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};
