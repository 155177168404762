import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { themes } from '../../themes/themes';

import Mui from '../../components/material';
import Icon from '../../components/icons';

const useStyles = makeStyles((theme) => ({
  headline: {
    textAlign: 'center',
  },
  accordionHeader: {
    fontSize: '1rem',
  },
  content: {
    fontSize: '1rem',
    wordBreak: 'break-word',
    lineHeight: 1.6,
    '& p': {
      fontSize: '1rem',
      lineHeight: 1.6,
    },
    '& a': {
      color: theme.palette.primary.main,
    },
  },
}));

const ContactUs = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { contact } = useSelector((state) => state.cms);

  return (
    <>
      <Helmet>
        <title>
          {`Kontakta oss | ${intl.formatMessage({ id: `app.title.${window.__ENV__.REACT_APP_THEME}` })} ${
            themes[window.__ENV__.REACT_APP_THEME].name
          }`}
        </title>
      </Helmet>
      <Mui.Container maxWidth='sm'>
        <Mui.Box pt={6} pb={3}>
          <Mui.Typography variant='h1' className={classes.headline}>
            <FormattedMessage id='contact.heading' />
          </Mui.Typography>
        </Mui.Box>
        <Mui.Box mb={6}>
          {contact.map((section) => (
            <Mui.Accordion defaultExpanded key={section.id}>
              <Mui.AccordionSummary
                expandIcon={<Icon.ExpandMoreIcon color='primary' />}
                aria-controls={`${section.name.replace(/ /g, '_')}-content`}
                id={`${section.name.replace(/ /g, '_')}-header`}>
                <Mui.Typography className={classes.accordionHeader} variant='h2'>
                  {section.name}
                </Mui.Typography>
              </Mui.AccordionSummary>
              <Mui.AccordionDetails>
                <div className={classes.content} dangerouslySetInnerHTML={{ __html: section.content }}></div>
              </Mui.AccordionDetails>
            </Mui.Accordion>
          ))}
        </Mui.Box>
      </Mui.Container>
    </>
  );
};

export default React.memo(ContactUs);
