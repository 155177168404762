const vastmanland = {
  name: 'Region Västmanland',
  options: {},
  theme: {
    primary: { main: '#1A766E', light: '#d1e3e2' },
    secondary: { main: '#DE0A2C' },
    error: { main: '#CF374C', contrastText: '#ffffff' },
    purple: { main: '#625396', contrastText: '#ffffff' },
    ocean: { main: '#367c88', contrastText: '#ffffff' },
    green: { main: '#128758', contrastText: '#ffffff' },
  },
};

export default vastmanland;
