import moment from 'moment';

export const validateSSN = (value) => {
  const response = {
    error: false,
    helptext: 'Skriv personnumret med 12 siffror',
  };

  if (testSSN(value)) {
    response.error = false;
  } else {
    response.error = true;
    response.helptext = 'Ogiltigt personnummer, ange personnummer med 12 siffror';
  }

  return response;
};

export const validatePhoneNbr = (value, helptext) => {
  const response = {
    error: false,
    helptext: helptext,
  };

  if (testPhoneNbr(value)) {
    response.error = false;
  } else {
    response.error = true;
    response.helptext = 'Ej giltigt telefonnummer!';
  }

  return response;
};

export const testSSN = (ssn) => {
  return /^(19|20)?(\d{6}([-+]|\s)\d{4}|(?!19|20)\d{10})$/.test(ssn);
};

export const is_numeric = (str) => {
  return /^\d+$/.test(str);
};

export const convertSSN = (ssn) => {
  // convert ssid string to ÅÅÅÅMMDD-XXXX
  let string = ssn;
  if (is_numeric(string[0]) && is_numeric(string[1])) {
    if (string.length === 12) {
      string = string.substr(0, 8) + '-' + string.substr(8);
    } else if (string.length === 10 && !string.includes('-')) {
      const year = parseInt(string.substr(0, 2)) < 30 ? '20' : '19';
      string = year + string.substr(0, 6) + '-' + string.substr(6);
    } else if (string.length === 11 && string.includes('-')) {
      string = string.replace(/-/g, '');
      const year = parseInt(string.substr(0, 2)) < 30 ? '20' : '19';
      string = year + string.substr(0, 6) + '-' + string.substr(6);
    }
  }
  return string;
};

export const testPhoneNbr = (number) => {
  return /^\+?\d+(-\d+)*$/.test(number);
};

export const testValidFrom = (validFrom) => {
  const twoWeeks = moment().add(14, 'days').format('YYYY-MM-DD');
  return moment(validFrom).isSameOrBefore(twoWeeks, 'day');
};

export const testValidUntil = (validUntil) => {
  const today = moment().format('YYYY-MM-DD');
  return moment(validUntil).isSameOrAfter(today, 'day');
};

export const getFormattedDate = (date, format) => {
  const dateAsMoment = format ? moment(date, format) : moment(date);
  return moment(dateAsMoment.format('YYYY-MM-DD'));
};
