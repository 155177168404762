import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { themes } from '../themes/themes';

import { systemToggleSubNav } from '../store/system/systemActions';

import Mui from '../components/material';
import Icon from '../components/icons';

import gbgstad from '../themes/gbgstad/gbgstad.png';
import halland from '../themes/halland/halland.png';
import orebro from '../themes/orebro/orebro.png';
import vastmanland from '../themes/vastmanland/vastmanland.png';
import vasttrafik from '../themes/vasttrafik/vasttrafik.svg';

import SelectUser from './SelectUser';
import SubNavBanner from './SubNavBanner';

const logos = {
  gbgstad: gbgstad,
  halland: halland,
  orebro: orebro,
  vastmanland: vastmanland,
  vasttrafik: vasttrafik,
};

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: theme.palette.common.white,
  },
  logoLink: {
    display: 'inline-block',

    '&:focus, &:active': {
      outline: `3px solid ${theme.palette.primary.main}`,
    },
  },
  logoImg: {
    order: 1,
    maxWidth: 180,
    maxHeight: 50,
    display: 'block',
    margin: theme.spacing(2, 0),

    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'auto',
    },
  },
  nav: {
    display: 'flex',
    alignItems: 'flex-end',
    order: 5,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'space-around',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 92,
    },
  },
  navList: {
    display: 'flex',
    alignItems: 'flex-end',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      flex: 1,
    },
  },
  navListItem: {
    [theme.breakpoints.down('sm')]: {
      flex: 1,
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 150,
    },
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.grey[600],
    fontSize: '1rem',
    fontWeight: 500,
    textDecoration: 'none',
    padding: theme.spacing(1, 0),
    border: 'none',
    // backgroun    dColor: theme.palette.common.white,
    borderBottom: `2px solid ${theme.palette.common.white}`,

    '&:focus, &:active': {
      outline: `3px solid ${theme.palette.primary.main}`,
      outlineOffset: -3,
    },
  },
  active: {
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  users: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    order: 2,

    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 0),
    },
    [theme.breakpoints.up('md')]: {
      order: 10,
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isAuthenticated, primaryUser } = useSelector((state) => state.auth);

  const handleToggleNavBar = (event) => {
    if (isMobileOnly) {
      event.preventDefault();
      dispatch(systemToggleSubNav());
    }
  };

  return (
    <>
      <Mui.AppBar position='static' className={classes.appbar} elevation={0}>
        <Mui.Toolbar disableGutters>
          <Mui.Container maxWidth='lg'>
            <Mui.Grid container justifyContent='space-between' alignItems='center' spacing={0}>
              <Mui.Grid item xs={12} sm>
                <NavLink exact to='/' className={classes.logoLink}>
                  <img
                    src={logos[window.__ENV__.REACT_APP_THEME]}
                    alt={`${themes[window.__ENV__.REACT_APP_THEME].name} logga`}
                    className={classes.logoImg}
                  />
                </NavLink>
              </Mui.Grid>

              {!isAuthenticated ? (
                <Mui.Grid item className={classes.nav} role='navigation' aria-label='loginmeny'>
                  <ul className={classes.navList}>
                    <li className={classes.navListItem}>
                      <NavLink exact activeClassName={classes.active} to='/login' className={classes.link}>
                        <Icon.LockIcon />
                        Logga in
                      </NavLink>
                    </li>
                    <li className={classes.navListItem}>
                      <NavLink exact activeClassName={classes.active} to='/skapa-konto' className={classes.link}>
                        <Icon.PersonIcon />
                        Skapa konto
                      </NavLink>
                    </li>
                  </ul>
                </Mui.Grid>
              ) : null}

              {isAuthenticated && primaryUser ? (
                <>
                  <Mui.Grid item className={classes.nav} role='navigation' aria-label='huvudmeny'>
                    <ul className={classes.navList}>
                      <li className={classes.navListItem}>
                        <NavLink activeClassName={classes.active} to='/boka-resa' className={classes.link}>
                          <Icon.AddLocationIcon />
                          Boka resa
                        </NavLink>
                      </li>
                      <li className={classes.navListItem}>
                        <NavLink activeClassName={classes.active} to='/mina-resor' className={classes.link}>
                          <Icon.EventIcon />
                          Mina resor
                        </NavLink>
                      </li>
                      <li className={classes.navListItem}>
                        <NavLink
                          activeClassName={classes.active}
                          to='/mina-sidor'
                          className={classes.link}
                          onClick={handleToggleNavBar}>
                          <Icon.PersonIcon />
                          Min sida
                        </NavLink>
                      </li>
                    </ul>
                  </Mui.Grid>
                  <Mui.Grid item xs={12} sm className={classes.users}>
                    <SelectUser />
                  </Mui.Grid>
                </>
              ) : null}
            </Mui.Grid>
            {isMobileOnly && <SubNavBanner />}
          </Mui.Container>
        </Mui.Toolbar>
      </Mui.AppBar>
    </>
  );
};

export default React.memo(Header);
