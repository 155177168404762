import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Mui from '../components/material';
import Banner from '../components/Banner';
import NewsList from '../components/NewsList';

import NoPermitImage from '../images/no_fav_trips.svg';

const useStyles = makeStyles((theme) => ({
  headline: {
    textAlign: 'center',
  },
  formControl: {
    backgroundColor: theme.palette.common.white,
  },
  content: {
    textAlign: 'center',
  },
}));

const NoPermitsStart = () => {
  const classes = useStyles();
  const { permits, agent } = useSelector((state) => state.cms);

  return (
    <>
      <Banner />
      <Mui.Container maxWidth='sm'>
        <Mui.Box pt={6} pb={3}>
          <Mui.Typography variant='h1' className={classes.headline}>
            <FormattedMessage id='start.nopermits' />
          </Mui.Typography>
        </Mui.Box>
        <Mui.Box mb={6} className={classes.content}>
          <img src={NoPermitImage} alt='' />
          <Mui.Typography variant='body1'>Om du saknar tillstånd, kan du ansöka om tillstånd här.</Mui.Typography>
          {permits &&
            permits.map((link) => (
              <Mui.Box key={link.id}>
                <a href={link.url} target='_blank' rel='noopener noreferrer'>
                  {link.name}
                </a>
              </Mui.Box>
            ))}

          <Mui.Typography variant='body1'>Om du vill boka till någon annan som ombud gör du så här.</Mui.Typography>
          {agent &&
            agent.map((link) => (
              <Mui.Box key={link.id}>
                <a href={link.url} target='_blank' rel='noopener noreferrer'>
                  {link.name}
                </a>
              </Mui.Box>
            ))}
        </Mui.Box>
      </Mui.Container>
      <Mui.Container maxWidth='xs'>
        <Mui.Box>
          <NewsList />
        </Mui.Box>
      </Mui.Container>
    </>
  );
};

export default NoPermitsStart;
