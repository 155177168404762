import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import CONSTANTS from '../store/constants';

import { tripsNotifications } from '../repository/trips';
import { addressSearch } from '../repository/address';
import { setBookingAction } from '../store/booking/bookingActions';
import { userFavoriteTripsList } from '../repository/user';

export default function useFavoriteDetails() {
  const dispatch = useDispatch();
  const { type, nodeId } = useParams(); // nodeId is ether a favoriteTripId or orderId

  const { customer, homeAddress } = useSelector((state) => state.auth);

  const fetchAddressSearch = async (addressString) => {
    return await addressSearch({
      query: addressString,
      municipality: customer?.allowedMunicipalities ? [...customer.allowedMunicipalities] : [],
      flex: type.toLowerCase() === 'fx' ? true : false,
      nearLocation: homeAddress.location,
    });
  };

  return useMutation(
    async ({ permit }) => {
      dispatch({ type: CONSTANTS.ADD_LOADER, payload: 'userFavoriteTripsList' });
      return await userFavoriteTripsList(nodeId);
    },
    {
      onError: (error) => {
        console.log(error);
        dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'userFavoriteTripsList' });
      },
      onSuccess: async (favoriteTrips, { permit }) => {
        const trip = favoriteTrips.find((fav) => fav.id === parseInt(nodeId));

        if (trip) {
          const {
            orderJson: { dropoff, pickup, travellers },
          } = trip;
          let pickupAddressString = `${pickup.strName}${pickup.strNbr ? ` ${pickup.strNbr}` : ''}, ${pickup.city}`;
          let pickupLetter = pickup.strNbrLetter.toUpperCase();
          let dropoffAddressString = `${dropoff.strName}${dropoff.strNbr ? ` ${dropoff.strNbr}` : ''}, ${dropoff.city}`;
          let dropoffLetter = dropoff.strNbrLetter.toUpperCase();

          const pickupAddresses = await fetchAddressSearch(pickupAddressString);
          const dropoffAddresses = await fetchAddressSearch(dropoffAddressString);
          const { driverInstructions } = await tripsNotifications();

          let pickupHelpItems = driverInstructions.find((item) => pickup.addrNote.Information.trim().includes(item.text));
          if (!pickupHelpItems) pickupHelpItems = { id: 0 };

          let dropoffHelpItems = driverInstructions.find((item) => dropoff.addrNote.Information.trim().includes(item.text));
          if (!dropoffHelpItems) dropoffHelpItems = { id: 0 };

          let pickupHelp = [{ ...pickupHelpItems, contactPhonenumber: pickup.addrNote.Kontaktnummer.trim() }];
          let dropoffHelp = [{ ...dropoffHelpItems, contactPhonenumber: dropoff.addrNote.Kontaktnummer.trim() }];
          let extraAids = travellers[0].extraAid.map((aid) => aid.id);

          const booking = {
            noteToCCandVehicle: '',
            nbrCompanions: travellers[0].nbrCompanions,
            nbrCoTravellers: travellers[0].nbrCoTravellers,
            pickupAddress: pickupAddresses.addresses.addresser[0],
            pickupLetter: pickupLetter,
            pickupHelp: pickupHelp,
            dropoffAddress: dropoffAddresses.addresses.addresser[0],
            dropoffLetter: dropoffLetter,
            dropoffHelp: dropoffHelp,
            extraAids: extraAids.length > 0 ? [...extraAids] : [permit.extraAidNoKey],
          };

          dispatch(setBookingAction(booking));
          dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'userFavoriteTripsList' });
        }
      },
    }
  );
}
