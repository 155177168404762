import http from './httpService';

// POST
// ​/trips​/order​/save
// Booking a trip
export const tripsOrderSave = async (request) => {
  const res = await http.post('/trips/order/save', request);
  return await res.data.data;
};

// POST
// /trips/order/list
// Lists all booking for specific user
export const tripsOrderList = async (limit, offset) => {
  let res;

  if (limit !== undefined && offset !== undefined) {
    res = await http.post('/trips/order/list', { limit: parseInt(limit), offset: parseInt(offset) });
  } else {
    res = await http.post('/trips/order/list');
  }
  
  return await res.data.data;
};

// PUT
// /trips/order/update
// Update a specific order for a specific user
export const tripsOrderUpdate = async (request) => {
  const res = await http.put('/trips/order/update', request);
  return res.data.data;
};

// POST
// /trips/order/delete
// Delete a specific order for specific user
export const tripsOrderDelete = async (orderId) => {
  const res = await http.post('/trips/order/delete', { orderId: parseInt(orderId) });
  return res.data;
};

// POST
// /trips/order/get
// Get a specific order
export const tripsOrderGet = async (key, orderId) => {
  const res = await http.post('/trips/order/get', { orderId: parseInt(orderId) });
  return res.data.data.order;
};

// GET
// /trips/notifications
// Get notifications for the driver
export const tripsNotifications = async () => {
  const res = await http.get('/trips/notifications');
  return res.data.data;
};

// POST
// /trips/vehicleinfo/get
// Get information about a vehicle
export const tripsVehicleinfoGet = async (externalId) => {
  const res = await http.post('/trips/vehicleinfo/get', { externalId });
  return res.data.data.vehicleInfo;
};

// POST
// ​/trips​/cost
// Get the cost for current month
export const tripsCost = async () => {
  const enviroments = ['halland', 'gbgstad', 'orebro'];
  if (!enviroments.includes(window.__ENV__.REACT_APP_THEME)) return null;

  const res = await http.get('/trips/cost');
  return res.data.data;
};
