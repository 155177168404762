import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { callbackRefStore } from '../../store/store';

import Mui from '../material';

const BankIdLoginDialog = ({ open, handleOnClose }) => {
  const intl = useIntl();

  return (
    <Mui.Dialog fullWidth maxWidth='xs' open={open} onClose={handleOnClose} aria-labelledby='dialog-title dialog-content'>
      <Mui.DialogTitle id='dialog-title'>
        <FormattedMessage id='login.bankId.dialog.title' />
      </Mui.DialogTitle>
      <Mui.DialogContent id="dialog-content">
        <>
          <Mui.Typography
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: 'login.bankId.dialog.message' }),
            }}
          />
          <Mui.Box display='flex' justifyContent='center'>
            <Mui.CircularProgress color='primary' />
          </Mui.Box>
        </>

        <Mui.Box mt={4}>
          <Mui.Button
            variant='text'
            color='primary'
            fullWidth
            onClick={() => callbackRefStore.callback()}
            disableFocusRipple>
            <FormattedMessage id='login.bankId.dialog.btnCancel' />
          </Mui.Button>
        </Mui.Box>
      </Mui.DialogContent>
      <Mui.DialogActions></Mui.DialogActions>
    </Mui.Dialog>
  );
};

export default React.memo(BankIdLoginDialog);
