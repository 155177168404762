import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Mui from '../../components/material';
import Icon from '../../components/icons';

import { Helmet } from 'react-helmet';
import { themes } from '../../themes/themes';

import { userVerify } from '../../repository/user';

import {
  dialogDisplayInformationAction,
  dialogCloseAllAction
} from '../../store/dialog/dialogActions';

const useStyles = makeStyles((theme) => ({
  headline: {
    textAlign: 'center',
  },
  content: {
    fontSize: '1.125rem',
    wordBreak: 'break-word',
    '& p': {
      fontSize: '1.125rem',
    },
    '& a': {
      color: theme.palette.primary.main,
    },
  },
}));

const CreatePassword = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { hash } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [newPassword, setNewPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [validationMessage, setValidationMessage] = useState();
  const [isConfirmPasswordDirty, setIsConfirmPasswordDirty] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCloseDialog = () => {
    dispatch(dialogCloseAllAction());
    history.push('/');
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();

    if (validationMessage) {
      return;
    }

    if (newPassword !== confirmPassword) {
      setIsConfirmPasswordDirty(true);
      return;
    }

    try {
      await userVerify(hash, newPassword);
      const info = `<p>${intl.formatMessage({ id: 'createPassword.success' })}`;
      const message = [{ info }];
      dispatch(dialogDisplayInformationAction(message, () => handleCloseDialog()));
    } catch (errors) {
      let code = '';
      if (errors.code) {
        code = errors.code.join(' ');
      }
      const info = `<p>${code}</p><p>${intl.formatMessage({ id: 'createPassword.incorrectCode' })}`;
      const message = [{ info }];
      dispatch(dialogDisplayInformationAction(message, () => handleCloseDialog()));
    };
  };

  useEffect(() => {
    let newValidationMessage = undefined;
    if (newPassword.length > 0 && confirmPassword.length > 0) {
      if (newPassword.length < 8) {
        newValidationMessage = intl.formatMessage({ id: 'validation.password.tooShort' });
      } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/.test(newPassword)) {
        newValidationMessage = intl.formatMessage({ id: 'validation.password.format' });
      } else if (isConfirmPasswordDirty && newPassword !== confirmPassword) {
        newValidationMessage = intl.formatMessage({ id: 'validation.password.notMatching' });
      }
    }

    setValidationMessage(newValidationMessage);
  }, [newPassword, confirmPassword, isConfirmPasswordDirty]);

  return (
    <>
      <Helmet>
        <title>
          {`${intl.formatMessage({ id: 'createPassword.heading' })} | ${intl.formatMessage({ id: `app.title.${window.__ENV__.REACT_APP_THEME}` })} ${
            themes[window.__ENV__.REACT_APP_THEME].name
          }`}
        </title>
      </Helmet>
      <Mui.Container maxWidth='sm'>
        <Mui.Box pt={6} pb={3}>
          <Mui.Typography variant='h1' className={classes.headline}>
            <FormattedMessage id='createPassword.heading' />
          </Mui.Typography>
        </Mui.Box>
        <Mui.Box mb={6}>
          <Mui.Card>
              <Mui.CardContent>
                <Mui.Typography variant='body1' color='initial' tabIndex={0}>
                  <FormattedMessage id='createPassword.text' />
                </Mui.Typography>
                <form onSubmit={handleOnSubmit} id='CreatePasswordForm' autoComplete='off'>
                  <Mui.FormGroup row>
                    <Mui.TextField
                      required
                      fullWidth
                      id="new-password"
                      name="new-password"
                      value={newPassword}
                      type={showNewPassword ? 'text' : 'password'}
                      onChange={(event) => setNewPassword(event.target.value)}
                      label={<FormattedMessage id='createPassword.password.new' />}
                      error={!!validationMessage}
                      helperText={validationMessage && <span>{validationMessage}</span> }
                      autoComplete='off'
                      variant='outlined'
                      margin="normal"
                      InputProps={{
                        endAdornment: (
                          <Mui.InputAdornment position='end'>
                            <Mui.IconButton
                              aria-label={showNewPassword ? 'Dölj lösenord' : 'Visa lösenord'}
                              onClick={() => setShowNewPassword(!showNewPassword)}
                              onMouseDown={handleMouseDownPassword}
                              edge='end'>
                              {showNewPassword ? <Icon.Visibility /> : <Icon.VisibilityOff />}
                            </Mui.IconButton>
                          </Mui.InputAdornment>
                        ),
                      }}
                    />
                    <Mui.TextField
                      required
                      fullWidth
                      id="confirm-password"
                      name="confirm-password"
                      value={confirmPassword}
                      type={showConfirmPassword ? 'text' : 'password'}
                      onChange={(event) => setConfirmPassword(event.target.value)}
                      onBlur={() => setIsConfirmPasswordDirty(true)}
                      label={<FormattedMessage id='createPassword.password.repeat' />}
                      autoComplete='off'
                      variant='outlined'
                      margin="normal"
                      InputProps={{
                        endAdornment: (
                          <Mui.InputAdornment position='end'>
                            <Mui.IconButton
                              aria-label={showConfirmPassword ? 'Dölj lösenord' : 'Visa lösenord'}
                              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                              onMouseDown={handleMouseDownPassword}
                              edge='end'>
                              {showConfirmPassword ? <Icon.Visibility /> : <Icon.VisibilityOff />}
                            </Mui.IconButton>
                          </Mui.InputAdornment>
                        ),
                      }}
                    />
                  </Mui.FormGroup>
                  <Mui.FormControl fullWidth margin='normal'>
                    <Mui.Button
                      fullWidth
                      variant='contained'
                      color='primary'
                      disableElevation
                      disableFocusRipple
                      type='submit'
                    >
                      <FormattedMessage id='createPassword.buttonCreatePassword' />
                    </Mui.Button>
                  </Mui.FormControl>
                </form>
              </Mui.CardContent>
            </Mui.Card>
        </Mui.Box>
      </Mui.Container>
    </>
  );
};

export default React.memo(CreatePassword);
