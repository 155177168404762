import http, { httpPrimary } from './httpService';

// POST
// ​/user​/agent​/request
// Ask another user to become an agent
export const agentUserRequest = async (agentUsername) => {
  const res = await http.post(`/user/agent/request`, { agentUsername });
  return res.data;
};

// POST
// ​/user​/agent​/login
// Login as an agent for a traveller
export const agentTravellerLogin = async (user) => {
  const res = await http.post(`/user/agent/login`, user);
  return res.data;
};

// POST
// ​/user​/traveller​/logout
// Logout traveller as an agent
export const agentTravellerLogout = async (user) => {
  const res = await http.post(`/user/traveller/logout`, user);
  return res.data;
};

// POST
// ​/user​/agent​/list
// Get a list of users that are agents for you
export const agentUserList = async () => {
  const res = await http.post('/user/agent/list');
  return res?.data?.data?.agents;
};

// POST
// ​/user​/agent​/delete
// Delete an agent from a traveller
export const agentUserDelete = async (token, agentUsername) => {
  const res = await http.post('/user/agent/delete', { token, agentUsername });
  return res.data;
};

// GET
// ​/user​/traveller​/request​/list
// Get a list of received/unanswered requests to become an agent for a traveller
export const userTravellerRequestList = async () => {
  const res = await http.get('/user/traveller/request/list');
  return res.data.data.requests;
};

// POST
// ​/user​/traveller​/request​/accept
// Accept a request to become an agent for a traveller
export const agentTravellerRequestAccept = async (travellerUsername) => {
  const res = await http.post(`/user/traveller/request/accept`, { travellerUsername });
  return res.data;
};

// POST
// ​/user​/traveller​/request​/decline
// Accept a request to become an agent for a traveller
export const agentTravellerRequestDecline = async (travellerUsername) => {
  const res = await http.post(`/user/traveller/request/decline`, { travellerUsername });
  return res.data;
};

// POST
// ​/user​/traveller​/list
// Get a list of users that you are agent for
export const agentTravellerListPrimary = async () => {
  const res = await httpPrimary.post(`/user/traveller/list`);
  return res.data.data.travellers;
};

// POST
// ​/user​/traveller​/list
// Get a list of users that you are agent for
export const agentTravellerList = async () => {
  const res = await http.post(`/user/traveller/list`);
  return res.data.data.travellers;
};

// POST
// ​/user​/traveller​/delete
// Delete a traveller from an agent
export const agentTravellerDelete = async (travellerUsername) => {
  const res = await http.post(`/user/traveller/delete`, { travellerUsername });
  return res.data;
};
