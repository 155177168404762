import CONSTANTS from '../constants';

const initialState = {
  offset: 0,
  orders: [],
  hits: 0,
  totalHits: 0,
  focusedTripId: null,
  forceOffsetChange: false,
};

const tripsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.INCREMENT_OFFSET:
      return {
        ...state,
        offset: action.payload,
      };

    case CONSTANTS.ADD_ORDERS:
      return {
        ...state,
        orders: [...state.orders, ...action.payload.orders],
        hits: action.payload.hits,
        totalHits: action.payload.totalHits,
        focusedTripId: action.payload.orders[0]?.id,
      };

      case CONSTANTS.CLEAR_TRIPS:
        return {
          ...state,
          offset: 0,
          orders: [],
          hits: 0,
          totalHits: 0,
          focusedTripId: null,
          forceOffsetChange: false,
        }

    case CONSTANTS.RESET_OFFSET:
      // Force a change in 'offset' since it won't actually change from 0 if 'Load more trips' hasn't been clicked yet.
      if (!state.forceOffsetChange) {
        return {
          ...initialState,
          forceOffsetChange: true,
        };
      }
      return initialState;

    default:
      return state;
  }
};

export default tripsReducer;
