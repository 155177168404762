import { composeWithDevTools } from 'redux-devtools-extension';
import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './rootReducer';
import CONSTANTS from './constants';

export let callbackRefStore = {};

function refHandler({ getState }) {
  return (next) => (action) => {
    switch (action.type) {
      case CONSTANTS.OPEN_DIALOG:
        callbackRefStore.callback = action.payload.callback;
        break;
      case CONSTANTS.CLOSE_DIALOG:
        callbackRefStore = {};
        break;
      default:
        break;
    }
    const returnValue = next(action);
    return returnValue;
  };
}

const middleware = [thunk, refHandler];
const composeEnhancers = composeWithDevTools || compose;
const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middleware)));

export default store;
