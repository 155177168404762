import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import CONSTANTS from '../../store/constants';

import { userLogin } from '../../repository/user';
import { convertSSN, validateSSN } from '../../utils/validators';

import { userGetAction, userClearAuth } from '../../store/auth/authActions';
import { dialogDisplayMessageAction, dialogForgottenPasswordAction } from '../../store/dialog/dialogActions';

import Mui from '../material';
import Icon from '../icons';

const useStyles = makeStyles((theme) => ({
  switch: {
    width: '100%',
  },
  switchLabel: {
    margin: 0,
  },
}));

const LoginPasswordForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [usernameOrg, setUsernameOrg] = useState(
    localStorage.getItem('ssn-username') ? localStorage.getItem('ssn-username') : ''
  );
  const [username, setUsername] = useState(
    localStorage.getItem('ssn-username') ? `${localStorage.getItem('ssn-username').slice(0, -4) + '****'}` : ''
  );
  const [usernameError, setUsernameError] = useState(false);
  const [usernameHelpText, setUsernameHelpText] = useState('Skriv personnumret med 12 siffror');
  const [password, setPassword] = useState('');

  const [remember, setRemember] = useState(localStorage.getItem('ssn-username') ? true : false);
  const [showPassword, setShowPassword] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOnForgottenPassword = async () => {
    dispatch(dialogForgottenPasswordAction('ssn'));
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    try {
      dispatch({ type: CONSTANTS.ADD_LOADER, payload: 'userLogin' });
      const auth = await userLogin({ username: convertSSN(usernameOrg), password });

      localStorage.setItem('refreshToken', auth.refreshToken);
      dispatch({ type: CONSTANTS.SET_TOKEN, payload: auth });
      dispatch({ type: CONSTANTS.SET_PRIMARY_TOKEN, payload: auth });

      localStorage.setItem('login-tab', 1);
      if (remember) {
        localStorage.setItem('ssn-username', convertSSN(usernameOrg));
      } else {
        localStorage.removeItem('ssn-username');
      }

      dispatch(userGetAction());
      dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'userLogin' });
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'userLogin' });
      dispatch(userClearAuth());
      dispatch(dialogDisplayMessageAction(error.code));
    }
  };

  return (
    <>
      <form onSubmit={handleOnSubmit} id='LoginPasswordForm' autoComplete='off'>
        <Mui.FormGroup row>
          <Mui.TextField
            required
            fullWidth
            name='username'
            margin='normal'
            autoComplete='off'
            value={username}
            error={usernameError}
            variant='outlined'
            id='ssn-helperText'
            label='Personnummer'
            onBlur={(e) => {
              const { error, helptext } = validateSSN(usernameOrg);
              setUsernameError(error);
              setUsernameHelpText(helptext);
            }}
            onChange={(e) => {
              setUsernameOrg(e.target.value);
              setUsername(e.target.value);
            }}
            helperText={usernameHelpText}
          />

          <Mui.FormControl margin='normal' fullWidth variant='outlined'>
            <Mui.InputLabel htmlFor='password'>Lösenord</Mui.InputLabel>
            <Mui.OutlinedInput
              id='password'
              name='password'
              autoComplete='off'
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <Mui.InputAdornment position='end'>
                  <Mui.IconButton
                    aria-label={showPassword ? 'Dölj lösenord' : 'Visa lösenord'}
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'>
                    {showPassword ? <Icon.Visibility /> : <Icon.VisibilityOff />}
                  </Mui.IconButton>
                </Mui.InputAdornment>
              }
              labelWidth={70}
            />
          </Mui.FormControl>

          <Mui.FormControl fullWidth margin='normal'>
            <Mui.FormControlLabel
              className={classes.switchLabel}
              control={
                <Mui.Switch
                  checked={remember}
                  onChange={(e) => setRemember(e.target.checked)}
                  name='remember'
                  color='primary'
                />
              }
              labelPlacement='start'
              label='Kom ihåg personnummer'
            />
          </Mui.FormControl>

          <Mui.FormControl fullWidth margin='normal'>
            <Mui.Button
              fullWidth
              variant='contained'
              color='primary'
              disableElevation
              disableFocusRipple
              type='submit'
              disabled={usernameError}
            >
              <FormattedMessage id='login.btnLogin' />
            </Mui.Button>
          </Mui.FormControl>
        </Mui.FormGroup>
      </form>
      <Mui.FormControl fullWidth margin='normal'>
        <Mui.Button
          fullWidth
          variant='outlined'
          color='primary'
          disableElevation
          disableFocusRipple
          onClick={handleOnForgottenPassword}>
          <FormattedMessage id='login.forgotPassword' />
        </Mui.Button>
      </Mui.FormControl>
      <Mui.FormControl fullWidth margin="normal">
        <Mui.Button
          fullWidth
          variant="outlined"
          color="primary"
          disableElevation
          disableFocusRipple
          component={Link}
          to="/skapa-konto"
        >
          <FormattedMessage id="login.btnCreateAccount" />
        </Mui.Button>
      </Mui.FormControl>
    </>
  );
};

export default LoginPasswordForm;
