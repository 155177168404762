import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

// import Login from './Login';

const ProtectedRoute = ({ component: Component, user, ...rest }) => {
  const { isAuthenticated, primaryUser } = useSelector((state) => state.auth);
  // const { data, isFetching: isLoadingPermits } = usePermits();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          if (primaryUser) {
            return <Component {...rest} {...props} />;
          }
        } else {
          return <Redirect to='/login' />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
