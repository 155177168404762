import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { dialogDeleteAgentAction } from '../../store/dialog/dialogActions';
import useAgentUserList from '../../hooks/useAgentUserList';

import Mui from '../../components/material';

import NoAgentFlower from '../../images/no-agent-flower.svg';
import UserInitials from '../../components/UserInitials';
import { getDisplayName } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(3),
  },
  delete: {
    width: '100%',
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    '&:focus': {
      backgroundColor: theme.palette.error.dark,
    },
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

const ManageAgentContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { data: agents } = useAgentUserList();

  const handleDeleteAgent = async (agentUsername) => {
    dispatch(dialogDeleteAgentAction(agentUsername));
  };

  return (
    <>
      <Mui.Box mb={3}>
        <Mui.Typography variant='h2' tabIndex={0}>
          <FormattedMessage id='myPage.myAgents.headingManage' />
        </Mui.Typography>
      </Mui.Box>
      <Mui.Box pb={6}>
        {agents && agents.length > 0 ? (
          agents.map((agent, i) => (
            <Mui.Card key={agent.id} className={classes.card}>
              <Mui.CardContent>
                <Mui.Box display='flex' alignItems='center'>
                  <UserInitials user={agent} size='medium' />
                  <Mui.Box flex='1' ml={3}>
                    <Mui.Typography variant='body1' tabIndex={0}>
                      {getDisplayName(agent)}
                    </Mui.Typography>
                  </Mui.Box>
                  <Mui.Box>
                    <Mui.Button
                      variant='contained'
                      className={classes.delete}
                      color='default'
                      disableElevation
                      disableFocusRipple
                      onClick={() => handleDeleteAgent(agent.username)}>
                      Ta bort ombud
                    </Mui.Button>
                  </Mui.Box>
                </Mui.Box>
              </Mui.CardContent>
            </Mui.Card>
          ))
        ) : (
          <Mui.Card>
            <Mui.CardContent>
              <Mui.Box display='flex' alignItems='center'>
                <img src={NoAgentFlower} alt='' />
                <Mui.Box ml={3}>
                  <Mui.Typography variant='h3' color='primary'>
                    Du har inga ombud
                  </Mui.Typography>
                  <Mui.Typography variant='body1'>Om du lägger till ett ombud kommer de att synas här.</Mui.Typography>
                </Mui.Box>
              </Mui.Box>
            </Mui.CardContent>
          </Mui.Card>
        )}
      </Mui.Box>
    </>
  );
};

export default React.memo(ManageAgentContainer);
