import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import moment from 'moment';
import Moment from 'react-moment';

import constants from '../store/constants';
import { themes } from '../themes/themes';

import Mui from '../components/material';

import { dialogDeleteMultipleTripAction } from '../store/dialog/dialogActions';
import { tripsClearTripsAction, tripsIncrementOffsetAction, tripsResetOffsetAction } from '../store/trips/tripsActions';

import TripItem from '../components/TripItem';
import NoTripsImage from '../images/upcoming_trips.png';
import useOrderList from '../hooks/useOrderList';

const useStyles = makeStyles((theme) => ({
  cancelWrapper: {
    padding: theme.spacing(2, 3),
  },
  imageNoTrips: {
    maxWidth: '100%',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  sectionTitle: {
    fontSize: '1.125rem',
    margin: theme.spacing(4, 0, 2),
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
  cancelSwitch: {
    overflow: 'visible',
    '& .Mui-focusVisible': {
      borderRadius: '50%',
      boxShadow: `0 0 0 3px ${theme.palette.primary.main}`,
    },
  },
}));

const MyUpcomingTrips = () => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { offset, orders, hits, totalHits, focusedTripId, forceOffsetChange } = useSelector((state) => state.trips);
  const { settings } = useSelector((state) => state.system);
  const { isFetching, refetch } = useOrderList(6, offset);

  const [ongoingTrip, setOngoingTrip] = useState([]);
  const [trips, setTrips] = useState([]);
  const [selected, setSelected] = useState([]);
  const [cancelSwitch, setCancelSwitch] = useState(false);

  const handleOnSelect = (id) => {
    setSelected((prev) => {
      if (prev.includes(id)) return prev.filter((i) => i !== id);
      return [...prev, id];
    });
  };

  const handleClearSelected = () => {
    setTrips((prev) => {
      return prev.map((row) => {
        row.items.map((trip) => {
          trip.checked = false;
          return trip;
        });
        return row;
      });
    });
    setCancelSwitch(false);
    setSelected([]);
  };

  const handleOnDelete = () => {
    dispatch(dialogDeleteMultipleTripAction(selected));
    handleClearSelected();
    setCancelSwitch(false);
  };

  const loadMoreTrips = () => {
    dispatch(tripsIncrementOffsetAction(hits));
  };

  useEffect(() => {
    if (orders) {
      setTrips((prev) => {
        const newTrips = orders.map((trip) => {
          const format = 'YYYY-MM-DD HH:mm';
          const date = trip.promisedTime;

          let dt = moment();
          const tf = moment(date, format).subtract(
            parseInt(settings?.position_maximum_minutes_before_arrival?.value),
            'minutes'
          );
          const tt = moment(date, format).add(parseInt(settings?.position_maximum_minutes_after_arrival?.value), 'minutes');

          let isOngoingTrip = false;
          if (dt.isBetween(tf, tt)) {
            isOngoingTrip = true;
          }

          return {
            ...trip,
            checked: false,
            active: isOngoingTrip,
          };
        });

        const occurrenceDay = (trip) => {
          return moment(trip.promisedTime).startOf('day').format();
        };

        const groupToDay = (trips, date) => {
          return {
            date: date,
            items: trips,
          };
        };

        const ongoing = newTrips.filter((trip) => trip.active);
        setOngoingTrip(ongoing);

        setTimeout(() => {
          if (prev.length && focusedTripId) {
            document.getElementById(`trip-item--${focusedTripId}`).focus();
          }
        }, 0);

        return _.chain(newTrips)
          .filter((trip) => !trip.active)
          .groupBy(occurrenceDay)
          .map(groupToDay)
          .sortBy('date')
          .value();
      });
    }
  }, [orders, settings, dispatch]);

  useEffect(() => {
    if (isFetching) {
      dispatch({ type: constants.ADD_LOADER, payload: 'orderList' });
    } else {
      dispatch({ type: constants.REMOVE_LOADER, payload: 'orderList' });
    }
  }, [dispatch, isFetching]);

  useEffect(() => {
    refetch();
  }, [offset, forceOffsetChange, refetch]);

  useEffect(() => {
    dispatch(tripsClearTripsAction());

    return () => {  
      dispatch(tripsClearTripsAction());
      dispatch(tripsResetOffsetAction());
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`Mina resor | ${intl.formatMessage({ id: `app.title.${window.__ENV__.REACT_APP_THEME}` })} ${
            themes[window.__ENV__.REACT_APP_THEME].name
          }`}
        </title>
      </Helmet>
      {!orders && isFetching ? (
        <></>
      ) : (
        <>
          <Mui.Container maxWidth='sm'>
            <Mui.Box pt={6} pb={3}>
              <Mui.Typography variant='h1' className={classes.textAlignCenter} tabIndex={0}>
                <FormattedMessage id='myTrips.heading' />
              </Mui.Typography>
            </Mui.Box>

            {!ongoingTrip.length && !trips.length && !isFetching ? (
              <Mui.Box>
                <Mui.Box mb={2}>
                  <img src={NoTripsImage} className={classes.imageNoTrips} alt='' />
                </Mui.Box>
                <Mui.Typography variant='h2' color='primary' className={classes.textAlignCenter} tabIndex={0}>
                  <FormattedMessage id='myTrips.headingNoTrips' />
                </Mui.Typography>
                <Mui.Typography className={classes.textAlignCenter} tabIndex={0}>
                  <FormattedMessage id='myTrips.textNoTrips' />
                </Mui.Typography>
              </Mui.Box>
            ) : null}

            {trips.length ? (
              <>
                <Mui.FormControl fullWidth margin='normal'>
                  <Mui.FormControlLabel
                    control={
                      <Mui.Switch
                        className={classes.cancelSwitch}
                        checked={cancelSwitch}
                        onChange={(e) => setCancelSwitch(e.target.checked)}
                        name='cancelSwitch'
                        color='primary'
                      />
                    }
                    labelPlacement='start'
                    label='Avboka flera resor på en gång'
                  />
                </Mui.FormControl>
              </>
            ) : null}

            {ongoingTrip.length ? (
              <Mui.Box>
                <Mui.Typography variant='h2' className={classes.sectionTitle} tabIndex={0}>
                  <FormattedMessage id='myTrips.ongoingTrip' />
                </Mui.Typography>
                {ongoingTrip.map((trip, idx) => (
                  <Mui.Box key={idx}>
                    <TripItem trip={trip} cancelCheckbox={false} onSelect={handleOnSelect} key={trip.id} />
                  </Mui.Box>
                ))}
              </Mui.Box>
            ) : null}

            <Mui.Box mb={8}>
              {trips
                .filter((trip) => !trip.active)
                .map((group, idx) => (
                  <Mui.Box key={`${group.id}_${idx}`}>
                    <Mui.Typography variant='h2' className={classes.sectionTitle} tabIndex={0}>
                      <Moment format='dddd, DD MMMM, YYYY' locale='sv'>
                        {group.date}
                      </Moment>
                    </Mui.Typography>
                    {group.items.map((trip) => (
                      <TripItem trip={trip} cancelCheckbox={cancelSwitch} onSelect={handleOnSelect} key={trip.id} />
                    ))}
                  </Mui.Box>
                ))}
                {hits < totalHits ? 
                <Mui.FormControl fullWidth margin='normal'>
                  <Mui.Button onClick={() => loadMoreTrips()} fullWidth color='primary' variant='contained' disabled={isFetching} disableFocusRipple>
                    Ladda fler resor
                  </Mui.Button>
                </Mui.FormControl>
                : ''}
            </Mui.Box>
          </Mui.Container>
        </>
      )}

      {cancelSwitch && orders && (
          <Mui.Paper elevation={0}>
            <Mui.Box className={classes.cancelWrapper}>
              <Mui.Typography variant='body1' role='status' tabIndex={0} aria-live="polite">
                Du har valt {selected.length} av {hits} resor att avboka.
              </Mui.Typography>
              <Mui.Box display='flex' justifyContent='flex-end'>
                <Mui.Box mx={2}>
                  <Mui.Button
                    variant='text'
                    color='default'
                    disableElevation
                    disableFocusRipple
                    className={classes.clearSelectedBtn}
                    onClick={handleClearSelected}>
                    Avbryt
                  </Mui.Button>
                </Mui.Box>
                <Mui.Button
                  variant='contained'
                  color='primary'
                  disableElevation
                  disableFocusRipple
                  aria-disabled={selected.length <= 0}
                  onClick={handleOnDelete}>
                  Avboka
                </Mui.Button>
              </Mui.Box>
            </Mui.Box>
          </Mui.Paper>
        )
      }
    </>
  );
};

export default React.memo(MyUpcomingTrips);
