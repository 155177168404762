import http from './httpService';

// POST
// ​/token​/refresh
// Refresh the JWT token
export const systemTokenRefresh = async (refreshToken) => {
  const res = await http.post(`/token/refresh`, { handlerEnabled: false, refreshToken: refreshToken });
  return res.data;
};

// GET
// ​/system​/settings
// Get settings for the system
export const systemSettings = async () => {
  const res = await http.get(`/system/settings`, { handlerEnabled: false });
  return res.data.data;
};

// GET
// ​/system​/cms​/list
// Get the list of CMS objects per catagories
export const systemCmsList = async () => {
  const res = await http.get(`/system/cms/list`, { handlerEnabled: false });
  return res.data.data;
};

// GET
// ​/information/important
// Get important information
export const systemInformation = async () => {
  const res = await http.get(`/information/important`, { handlerEnabled: false });
  return res.data.data;
};

// GET
// ​/version
// Get system version
export const systemVersion = async () => {
  const res = await http.get(`/version`, { handlerEnabled: false });
  return res.data.data;
};
