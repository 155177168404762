import http from './httpService';

// GET
// ​/bankid/auth/username
export const bankidAuthUsername = async (username) => {
  const res = await http.post(`/bankid/auth`, { username });
  return res.data.data;
};

// GET
// /bankid/collect/login/orderRef
export const bankidCollectLoginOrderRef = async (orderRef, transactionId = "") => {
  const res = await http.post(`/bankid/collect/login`, { orderRef, transactionId });
  return res.data;
};

// GET
// /bankid/collect/create/orderRef
export const bankidCollectCreateUser = async (orderRef, transactionId = "") => {
  const res = await http.post(`/bankid/collect/create`, { orderRef, transactionId });
  return res.data;
};
