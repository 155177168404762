import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';

import LoginBankIdForm from './forms/LoginBankIdForm';
import LoginPasswordForm from './forms/LoginPasswordForm';
import LoginGroupAccountForm from './forms/LoginGroupAccountForm';

const useStyles = makeStyles((theme) => ({
  tab: {
    minWidth: 0,
    flex: '1 1 100%',
    opacity: 1,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:focus': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
  },
  selected: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
  },
  indicator: {
    display: 'none',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TabsLogin = () => {
  const classes = useStyles();
  const [value, setValue] = useState(localStorage.getItem('login-tab') ? parseInt(localStorage.getItem('login-tab')) : 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Card>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label='Tabbmeny för val av inloggning. Använd höger och vänster piltangenter för att navigera flikarna. Välj flik med RETUR'
        variant='fullWidth'
        classes={{ indicator: classes.indicator }}>
        <Tab
          label='BankID'
          aria-label="BankID"
          disableFocusRipple
          {...a11yProps(0)}
          classes={{
            root: classes.tab,
            selected: classes.selected,
          }}
        />
        <Tab
          label='Lösenord'
          aria-label="Lösenord"
          disableFocusRipple
          {...a11yProps(1)}
          classes={{
            root: classes.tab,
            selected: classes.selected,
          }}
        />
        <Tab
          label='Gruppkonto'
          aria-label="Gruppkonto"
          disableFocusRipple
          {...a11yProps(2)}
          classes={{
            root: classes.tab,
            selected: classes.selected,
          }}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <LoginBankIdForm />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <LoginPasswordForm />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <LoginGroupAccountForm />
      </TabPanel>
    </Card>
  );
};

export default TabsLogin;
