import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import { themes } from '../../themes/themes';

import Mui from '../../components/material';
import Icon from '../../components/icons';

const useStyles = makeStyles((theme) => ({
  headline: {
    textAlign: 'center',
  },
  accordionHeader: {
    fontSize: '1rem',
  },
  content: {
    fontSize: '1rem',
    wordBreak: 'break-word',
    lineHeight: 1.6,
    '& p': {
      fontSize: '1rem',
      lineHeight: 1.6,
    },
    '& a': {
      color: theme.palette.primary.main,
    },
  },
}));

const Version = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { version } = useSelector((state) => state.system);

  return (
    <>
      <Helmet>
        <title>
          {`${intl.formatMessage({ id: 'version.heading' })} | ${intl.formatMessage({ id: `app.title.${window.__ENV__.REACT_APP_THEME}` })} ${
            themes[window.__ENV__.REACT_APP_THEME].name
          }`}
        </title>
      </Helmet>
      <Mui.Container maxWidth='sm'>
        <Mui.Box pt={6} pb={3}>
          <Mui.Typography variant='h1' className={classes.headline}>
            <FormattedMessage id='version.heading' />
          </Mui.Typography>
        </Mui.Box>
        <Mui.Box mb={6}>
          <Mui.Accordion defaultExpanded>
            <Mui.AccordionSummary
              expandIcon={<Icon.ExpandMoreIcon color='primary' />}
              aria-controls={'app-version-content'}
              id={'app-version-header'}
            >
              <Mui.Typography className={classes.accordionHeader} variant='h2'>
                App
              </Mui.Typography>
            </Mui.AccordionSummary>
            <Mui.AccordionDetails>
              <Mui.Typography className={classes.content}>
                Version: {process.env.REACT_APP_VERSION}
              </Mui.Typography>
            </Mui.AccordionDetails>
          </Mui.Accordion>
        </Mui.Box>
        <Mui.Box mb={6}>
          <Mui.Accordion defaultExpanded>
            <Mui.AccordionSummary
              expandIcon={<Icon.ExpandMoreIcon color='primary' />}
              aria-controls={'api-version-content'}
              id={'api-version-header'}
            >
              <Mui.Typography className={classes.accordionHeader} variant='h2'>
                API
              </Mui.Typography>
            </Mui.AccordionSummary>
            <Mui.AccordionDetails>
              {Object.keys(version).map((api, index) => (
                <Mui.Typography className={classes.content} key={index}>
                  {api}: {version[api]}
                </Mui.Typography>
              ))}
            </Mui.AccordionDetails>
          </Mui.Accordion>
        </Mui.Box>
      </Mui.Container>
    </>
  );
};

export default React.memo(Version);
