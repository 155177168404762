const gbgstad = {
  name: 'Göteborgs Stad',
  options: {},
  theme: {
    primary: { main: '#0076BC', light: '#e1f5fe' },
    secondary: { main: '#3F5564' },
    error: { main: '#CF374C', contrastText: '#ffffff' },
    purple: { main: '#625396', contrastText: '#ffffff' },
    ocean: { main: '#367c88', contrastText: '#ffffff' },
    green: { main: '#128758', contrastText: '#ffffff' },
  },
};

export default gbgstad;
