import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Mui from '../material';

const DisplayMessageDialog = ({ open, handleOnClose }) => {
  const { content } = useSelector((state) => state.dialog);
  const { messages } = useSelector((state) => state.cms);

  // Hack to get default values from CMS
  const { description: cmsTitle, content: cmsDescription } = messages.find((msg) => msg.name === '80');
  const defaultTitle = cmsTitle || 'Felmeddelande';
  const defaultDescription = cmsDescription || 'Ett fel har inträffat, försök igen. Kontakta support om felet kvarstår.';

  const [title, setTitle] = useState(defaultTitle);
  const [description, setDescription] = useState(defaultDescription);

  useEffect(() => {
    let newTitle = defaultTitle;
    let newDescription = defaultDescription;

    if (messages && content) {
      const msg = messages.find(
        (msg) => JSON.stringify(msg.name).replace(/"/g, '') === JSON.stringify(content).replace(/"/g, '')
      );

      if (msg) {
        newTitle = msg.description;
        newDescription = msg.content;
      }
    }

    setTitle(newTitle);
    setDescription(newDescription);
  }, [content, messages]);

  return (
    <Mui.Dialog fullWidth maxWidth='xs' open={open} onClose={handleOnClose} aria-labelledby='dialog-title dialog-content dialog-actions'>
      <Mui.DialogTitle id='dialog-title'>{title}</Mui.DialogTitle>
      <Mui.DialogContent id="dialog-content" aria-label={description.replace(/(<([^>]+)>)/gi, "")}>
        <Mui.Box>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </Mui.Box>
      </Mui.DialogContent>
      <Mui.DialogActions id="dialog-actions">
        <Mui.Button onClick={handleOnClose} color='primary' disableFocusRipple>
          <FormattedMessage id='dialog.btnOk' />
        </Mui.Button>
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};

export default React.memo(DisplayMessageDialog);
