import axios from 'axios';
import store from '../store/store';
import moment from 'moment';
import { decodeToken } from 'react-jwt';

import { systemTokenRefresh } from './public';

import CONSTANTS from '../store/constants';

// Add handlerEnabled: false to request config to not run outgoing interceptor
export const isHandlerEnabled = (config = {}) => {
  return Object.prototype.hasOwnProperty.call(config, 'handlerEnabled') && !config.handlerEnabled ? false : true;
};

// Intercept incomming responses
const errorHandler = (error) => {
  console.log(error);
  if (isHandlerEnabled(error?.response?.config)) {
    if (
      error &&
      error.response &&
      error.response.status === 418 &&
      error.response.config &&
      !error.response.config.__isRetryRequest
    ) {
      console.log('418 returned, skipping');
      throw error.response.data.data.error;
    }
    if (
      error &&
      error.response &&
      error.response.status === 401 &&
      error.response.config &&
      !error.response.config.__isRetryRequest
    ) {
      console.log('401 returned, attempting logout');
      throw error.response.data.data.error;
    }
    if (
      error &&
      error.response &&
      error.response.status === 403 &&
      error.response.config &&
      !error.response.config.__isRetryRequest
    ) {
      console.log('403 returned, attempting logout');
      throw error.response.data.data.error;
    }
    if (
      error &&
      error.response &&
      (error.response.status === 500 || error.response.status === 400) &&
      error.response.config &&
      !error.response.config.__isRetryRequest
    ) {
      if (error.response.data.errors) {
        throw error.response.data.errors;
      } else if (error.response.data.data?.error) {
        throw error.response.data.data.error;
      }
    }
    if (
      error &&
      error.response &&
      error.response.status === 404 &&
      error.response.config &&
      !error.response.config.__isRetryRequest
    ) {
      throw error.response.data.data.error;
    }
  }
  throw error;
};

const requestHandler = async (request, token) => {
  if (isHandlerEnabled(request)) {
    if (token) {
      const decode = decodeToken(token);
      const currentTime = moment();
      const expireAt = moment.unix(decode.exp);
      const duration = expireAt.diff(currentTime, 'minutes');

      if (duration < 5) {
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
          localStorage.removeItem('refreshToken');
          systemTokenRefresh(refreshToken).then((data) => {
            store.dispatch({ type: CONSTANTS.SET_PRIMARY_TOKEN, payload: data });
            store.dispatch({ type: CONSTANTS.SET_TOKEN, payload: data });
            localStorage.setItem('refreshToken', data.refreshToken);
          });
        }
      }
      request.headers['Authorization'] = `Bearer ${token}`;
    }
  }

  request.headers['X-API-KEY'] = `${window.__ENV__.REACT_APP_X_API_KEY}`;
  return request;
};

const http = axios.create({ baseURL: window.__ENV__.REACT_APP_API_URL });
http.interceptors.request.use((request) => requestHandler(request, localStorage.getItem('user-token')));
http.interceptors.response.use(null, (error) => errorHandler(error));

export const httpPrimary = axios.create({ baseURL: window.__ENV__.REACT_APP_API_URL });
httpPrimary.interceptors.request.use((request) => requestHandler(request, localStorage.getItem('primary-token')));
httpPrimary.interceptors.response.use(null, (error) => errorHandler(error));

export default http;
