import React from 'react';
import { queryCache } from 'react-query';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import { agentTravellerDelete } from '../../repository/agent';

import { snackbarOpenAction } from '../../store/snackbar/snackbarActions';
import { dialogCloseAllAction } from '../../store/dialog/dialogActions';

import Mui from '../material';

const DeleteTravellerDialog = ({ open, handleOnClose }) => {
  const dispatch = useDispatch();

  const { content } = useSelector((state) => state.dialog);

  const handleOnSubmit = async () => {
    await agentTravellerDelete(content);
    queryCache.invalidateQueries('agentTravellerList');
    queryCache.invalidateQueries('agentTravellerListPrimary');
    queryCache.invalidateQueries('userTravellerRequestList');

    dispatch(dialogCloseAllAction());
    dispatch(snackbarOpenAction('Resenären har tagits bort'));
  };

  return (
    <Mui.Dialog fullWidth maxWidth='xs' open={open} onClose={handleOnClose} aria-labelledby='dialog-title dialog-content'>
      <Mui.DialogContent id="dialog-content">
        <Mui.Typography>Vill du ta bort {content} som din resenär?</Mui.Typography>
      </Mui.DialogContent>
      <Mui.DialogActions id="dialog-actions">
        <Mui.Button onClick={handleOnClose} color='primary' disableFocusRipple>
          <FormattedMessage id='dialog.btnCancel' />
        </Mui.Button>
        <Mui.Button onClick={handleOnSubmit} color='primary' disableFocusRipple>
          <FormattedMessage id='dialog.btnOk' />
        </Mui.Button>
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};

export default React.memo(DeleteTravellerDialog);
