import React from 'react';
import { Switch } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';

import ProtectedRoute from './ProtectedRoute';
import MyProfile from './mypage/MyProfile';
import SubNavBanner from '../components/SubNavBanner';
import MyFavorites from './mypage/MyFavorites';
import MyPermits from './mypage/MyPermits';
import MyAgents from './mypage/MyAgents';
import MyTravellers from './mypage/MyTravellers';

const MyPage = () => {
  return (
    <>
      {!isMobileOnly && <SubNavBanner />}
      <Switch>
        <ProtectedRoute exact path='/mina-sidor' component={MyProfile} />
        <ProtectedRoute exact path='/mina-sidor/mina-favoriter' component={MyFavorites} />
        <ProtectedRoute exact path='/mina-sidor/mina-tillstand' component={MyPermits} />
        <ProtectedRoute exact path='/mina-sidor/mina-ombud' component={MyAgents} />
        <ProtectedRoute exact path='/mina-sidor/mina-resenarer' component={MyTravellers} />
      </Switch>
    </>
  );
};

export default React.memo(MyPage);
