import React, { useState } from 'react';
import { queryCache } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { userFavoriteTripUpdate } from '../../repository/user';
import { dialogCloseAllAction, dialogDisplayMessageAction } from '../../store/dialog/dialogActions';
import { snackbarOpenAction } from '../../store/snackbar/snackbarActions';

import Mui from '../material';

const UpdateFavoriteTripDialog = ({ open, handleOnClose }) => {
  const dispatch = useDispatch();
  const { content } = useSelector((state) => state.dialog);
  const [value, setValue] = useState('');

  const handleOnSubmit = async () => {
    try {
      const request = {
        ...content,
        name: value,
      };
      const { success } = await userFavoriteTripUpdate(request);
      if (success) {
        queryCache.invalidateQueries('userFavoriteTripsList');

        dispatch(dialogCloseAllAction());
        dispatch(snackbarOpenAction('Favoriten har uppdaterats'));
      }
    } catch (error) {
      dispatch(dialogCloseAllAction());
      dispatch(dialogDisplayMessageAction(error.code));
    }
  };

  return (
    <Mui.Dialog fullWidth maxWidth='xs' open={open} onClose={handleOnClose} aria-labelledby='dialog-title dialog-content dialog-actions'>
      <Mui.DialogTitle id='dialog-title'>
        <FormattedMessage id='bookTripReceipt.favNameTitle' />
      </Mui.DialogTitle>
      <Mui.DialogContent id="dialog-content">
        <Mui.TextField
          fullWidth
          id='favNameEnter'
          value={value}
          label={<FormattedMessage id='bookTripReceipt.favNameEnter' />}
          onChange={(event) => setValue(event.target.value)}
        />
      </Mui.DialogContent>
      <Mui.DialogActions id="dialog-actions">
        <Mui.Button onClick={handleOnClose} color='primary' disableFocusRipple>
          <FormattedMessage id='dialog.btnCancel' />
        </Mui.Button>
        <Mui.Button onClick={handleOnSubmit} color='primary' disabled={value.length === 0} disableFocusRipple>
          <FormattedMessage id='dialog.btnConfirm' />
        </Mui.Button>
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};

export default React.memo(UpdateFavoriteTripDialog);
