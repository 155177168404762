import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';
import moment from 'moment';

import { userInformationList } from '../repository/user';

import Mui from './material';
import NoNewsImage from '../images/news.png';

const useStyles = makeStyles((theme) => ({
  item: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2, 0),
  },
  noNewsImage: {
    maxWidth: '100%',
  },
  noNewsText: {
    fontSize: '1.25rem',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  fromNow: {
    fontSize: '0.875rem',
    marginBottom: theme.spacing(1),
    fontWeight: 'normal',
    letterSpacing: 0.24,
    color: '#0009',

    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
  moreNewsBtn: {
    backgroundColor: theme.palette.common.white,
  },
  header: {
    marginBottom: theme.spacing(1),
  },
  info: {
    marginBottom: theme.spacing(1),
  },
}));

const NewsList = () => {
  const classes = useStyles();
  const [amount, setAmount] = useState(3);
  const { data, isFetching } = useQuery('userInformationList', userInformationList, {
    refetchOnWindowFocus: false,
    retry: false,
  });

  const handleDate = (publishedAt) => {
    const date = publishedAt.date ? moment(publishedAt.date) : moment(publishedAt);
    if (moment().diff(date, 'days') >= 2) {
      return moment(date).format('DD MMMM, YYYY');
    }
    return date.calendar().split(' ')[0];
  };

  return (
    <Mui.Box mb={6}>
      <Mui.Typography variant='h2' color='primary' tabIndex={0}>
        <FormattedMessage id='news.heading' />
      </Mui.Typography>
      {isFetching ? null : data && data.length ? (
        <>
          {data.slice(0, amount).map((news) => (
            <Mui.Box key={news.id}>
              <Mui.Box className={classes.item}>
                {news.publishedAt && (
                  <Mui.Typography variant='body2' className={classes.fromNow} tabIndex={0}>
                    {handleDate(news.publishedAt)}
                  </Mui.Typography>
                )}
                <Mui.Typography variant='h3' className={classes.header} tabIndex={0}>
                  {news.header}
                </Mui.Typography>
                <Mui.Typography variant='body1' className={classes.info} tabIndex={0}>
                  {news.info}
                </Mui.Typography>
              </Mui.Box>
              <Mui.Divider variant='middle' />
            </Mui.Box>
          ))}
          {data && amount < data.length && (
            <Mui.Box mt={4}>
              <Mui.Button
                variant='contained'
                className={classes.moreNewsBtn}
                size='large'
                fullWidth
                disableFocusRipple
                onClick={() => setAmount(data.length)}>
                Visa alla nyheter ({data.length - amount})
              </Mui.Button>
            </Mui.Box>
          )}
        </>
      ) : (
        <Mui.Box>
          <img src={NoNewsImage} alt='' className={classes.noNewsImage} />
          <Mui.Typography variant='h2' color='primary' className={classes.noNewsText} tabIndex={0}>
            <FormattedMessage id='news.noNews' />
          </Mui.Typography>
        </Mui.Box>
      )}
    </Mui.Box>
  );
};

export default NewsList;
