const vasttrafik = {
  name: 'Västtrafik',
  options: {},
  theme: {
    primary: { main: '#007EB1', light: '#e1f5fe' },
    secondary: { main: '#009DDB' },
    error: { main: '#CF374C', contrastText: '#ffffff' },
    purple: { main: '#625396', contrastText: '#ffffff' },
    ocean: { main: '#367c88', contrastText: '#ffffff' },
    green: { main: '#128758', contrastText: '#ffffff' },
  },
};

export default vasttrafik;
