import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { dialogDeleteTravellerAction } from '../../store/dialog/dialogActions';

import Mui from '../../components/material';

import NoAgentFlower from '../../images/no-agent-flower.svg';
import UserInitials from '../../components/UserInitials';
import useAgentTravellerList from '../../hooks/useAgentTravellerList';
import { getDisplayName } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(3),
  },
  delete: {
    width: '100%',
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
    '&:focus, &:active': {
      backgroundColor: theme.palette.error.dark,
      outline: `3px solid ${theme.palette.error.light}`,
      outlineOffset: -3,
    },
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

const ManageTravellersContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { data: travellers } = useAgentTravellerList();

  const handleDeleteTraveller = async (travellerUsername) => {
    dispatch(dialogDeleteTravellerAction(travellerUsername));
  };

  return (
    <>
      <Mui.Box mb={2}>
        <Mui.Typography variant='h2' tabIndex={0}>
          <FormattedMessage id='myPage.myTravellers.headingManage' />
        </Mui.Typography>
      </Mui.Box>
      <Mui.Box pb={6}>
        {travellers && travellers.length > 0 ? (
          travellers.map((traveller) => (
            <Mui.Card key={traveller.id} className={classes.card}>
              <Mui.CardContent>
                <Mui.Box display='flex' alignItems='center'>
                  <UserInitials user={traveller} size='medium' />
                  <Mui.Box flex='1' ml={3} tabIndex={0}>
                    <Mui.Typography variant='body1'>
                      {getDisplayName(traveller)}
                    </Mui.Typography>
                  </Mui.Box>
                  <Mui.Box>
                    <Mui.Button
                      variant='contained'
                      className={classes.delete}
                      color='default'
                      disableElevation
                      disableFocusRipple
                      onClick={() => handleDeleteTraveller(traveller.username)}>
                      Ta bort resenär
                    </Mui.Button>
                  </Mui.Box>
                </Mui.Box>
              </Mui.CardContent>
            </Mui.Card>
          ))
        ) : (
          <Mui.Card>
            <Mui.CardContent>
              <Mui.Box display='flex' alignItems='center'>
                <img src={NoAgentFlower} alt='' />
                <Mui.Box ml={3} tabIndex={0}>
                  <Mui.Typography variant='h3' color='primary'>
                    Du har inga resenärer
                  </Mui.Typography>
                  <Mui.Typography variant='body1'>
                    Om någon har lagt till dig som ombud kommer de att synas här.
                  </Mui.Typography>
                </Mui.Box>
              </Mui.Box>
            </Mui.CardContent>
          </Mui.Card>
        )}
      </Mui.Box>
    </>
  );
};

export default React.memo(ManageTravellersContainer);
