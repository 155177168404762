import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import { getInitials, getColor } from '../utils/helpers';

import Mui from './material';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '50%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  large: {
    fontSize: '2.125rem',
    width: 88,
    height: 88,
    marginBottom: theme.spacing(2),
  },
  medium: {
    fontSize: '1.25rem',
    width: 40,
    height: 40,
  },
  small: {
    fontSize: 13,
    width: 24,
    height: 24,
  },
  green: {
    color: theme.palette.green.contrastText,
    backgroundColor: theme.palette.green.main,
  },
  purple: {
    color: theme.palette.purple.contrastText,
    backgroundColor: theme.palette.purple.main,
  },
  ocean: {
    color: theme.palette.ocean.contrastText,
    backgroundColor: theme.palette.ocean.main,
  },
}));

const UserInitials = ({ user, size }) => {
  const classes = useStyles();
  return (
    <Mui.Box className={clsx(classes.root, classes[size], classes[getColor(user)])}>
      {getInitials(user)}
    </Mui.Box>
  );
};

export default UserInitials;
