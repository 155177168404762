import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery, queryCache } from 'react-query';
import { FormattedMessage } from 'react-intl';

import { getTypeIcon } from '../../utils/helpers';
import { userFavoriteAddressesList, userFavoriteAddressCreate, userFavoriteAddressDelete } from '../../repository/user';
import { snackbarOpenAction } from '../../store/snackbar/snackbarActions';
import { addressSearchAll } from '../../repository/address';

import Mui from '../../components/material';
import Icon from '../../components/icons';
import useDebounce from '../../hooks/useDebounce';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  createButton: {
    marginBottom: 8,
  },
}));

const FavoritePlacesContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { customer, homeAddress } = useSelector((state) => state.auth);

  const [anchorEl, setAnchorEl] = useState(null);

  const [selectedFavorite, setSelectedFavorite] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState({ address: '', type: '' });
  const [addressLetter, setAddressLetter] = useState('');
  const [addressInputValue, setAddressInputValue] = useState('');

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const { data: favorites } = useQuery('userFavoriteAddressesList', userFavoriteAddressesList, {
    refetchOnWindowFocus: false,
    retry: false,
  });

  const handleClickMoreMenu = (event, favorite) => {
    setSelectedFavorite(favorite);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSelectedFavorite(null);
    setAnchorEl(null);
  };

  const handleOnChange = async (event, value) => {
    const request = {
      query: value.address,
      municipality: customer?.allowedMunicipalities ? [...customer.allowedMunicipalities] : [],
      flex: true,
      nearLocation: homeAddress.location,
    };
    const { addresses } = await addressSearchAll(request);
    setSelectedAddress(addresses.addresser.find((row) => row.address === value.address));
  };

  const handleOnInputChange = async (event, value) => {
    if (value) {
      setSearchTerm(value);
    } else {
      setSearchTerm('');
    }
  };

  const handleOnOpen = async () => {
    setAddresses([]);
  };

  const handleCreateFavoriteAddress = async () => {
    const fixAddress = (val) => {
      const arr = val.split(' ');
      const number = arr.pop();

      const first = arr.join(' ');
      return {
        strName: first,
        strNbr: number ? number : '',
      };
    };

    let request = null;
    if (selectedAddress.type === 'kart') {
      const obj = fixAddress(selectedAddress.address.split(',')[0]);
      request = {
        addressType: selectedAddress.type,
        strName: obj.strName,
        strNbr: obj.strNbr,
        strNbrLetter: addressLetter ? addressLetter : '',
        city: selectedAddress.address.split(',')[1].trim(),
        stopName: '',
      };
    }

    if (selectedAddress.type === 'hpla') {
      request = {
        addressType: selectedAddress.type,
        strName: '',
        strNbrLetter: '',
        strNbr: '',
        city: '',
        stopName: selectedAddress.address,
      };
    }

    if (selectedAddress.type === 'egen') {
      request = {
        addressType: selectedAddress.type,
        strName: '',
        strNbrLetter: '',
        strNbr: '',
        city: '',
        stopName: selectedAddress.address,
      };
    }

    const { success } = await userFavoriteAddressCreate(request);
    if (success) {
      setSelectedAddress({ address: '', type: '' });
      setAddressLetter('');
      queryCache.invalidateQueries('userFavoriteAddressesList');
      dispatch(snackbarOpenAction('Favoritadress skapad'));
    }
  };

  const handleDeleteFavoriteAddress = async () => {
    const { success } = await userFavoriteAddressDelete(selectedFavorite.id);
    if (success) {
      handleClose();
      queryCache.invalidateQueries('userFavoriteAddressesList');
      dispatch(snackbarOpenAction('Favoritadress borttagen'));
    }
  };

  const doSearchAddress = useCallback(
    async (value) => {
      const request = {
        query: value,
        municipality: customer?.allowedMunicipalities ? [...customer.allowedMunicipalities] : [],
        nearLocation: homeAddress.location,
      };

      const { addresses } = await addressSearchAll(request);
      setAddressInputValue(value);
      setAddresses([...addresses.addresser])
    }, [customer]
  );

  useEffect(() => {
    if (debouncedSearchTerm) {
      doSearchAddress(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, doSearchAddress]);

  return (
    <>
      <Mui.Accordion>
        <Mui.AccordionSummary
          expandIcon={<Icon.ExpandMoreIcon color='primary' />}
          aria-controls='favorite-place-content'
          id='favorite-place-header'>
          <Mui.Typography className={classes.header} variant='h2'>
            <Icon.RoomIcon color='primary' className={classes.icon} />
            <FormattedMessage id='myPage.myFavourites.favouritePlaces' />
          </Mui.Typography>
        </Mui.AccordionSummary>
        <Mui.AccordionDetails>
          {favorites &&
            favorites.favoriteAddresses
              .sort((a, b) => a.strName.localeCompare(b.strName))
              .sort((a, b) => a.stopName.localeCompare(b.stopName))
              .map((favorite, idx) => (
                <Mui.Box display='flex' flexGrow={1} flexDirection='column' key={favorite.id}>
                  <Mui.Box display='flex' alignItems='center' m={2}>
                    {favorite.stopName ? (
                      <>
                        <Mui.Box display='flex' className={classes.icon}>
                          {getTypeIcon(favorite.addressType)}
                        </Mui.Box>
                        <Mui.Box display='flex' flexDirection='column' flexGrow={1} tabIndex={0}>
                          <Mui.Typography variant='h3'>{favorite.stopName}</Mui.Typography>
                          <Mui.Typography variant='body1'>{favorite.city}</Mui.Typography>
                        </Mui.Box>
                      </>
                    ) : (
                      <>
                        <Mui.Box display='flex' className={classes.icon}>
                          {getTypeIcon(favorite.addressType)}
                        </Mui.Box>
                        <Mui.Box display='flex' flexDirection='column' flexGrow={1} tabIndex={0}>
                          <Mui.Typography variant='h3'>
                            {favorite.strName} {favorite.strNbr}
                            {favorite.strNbrLetter}
                          </Mui.Typography>
                          <Mui.Typography variant='body1'>{favorite.city}</Mui.Typography>
                        </Mui.Box>
                      </>
                    )}
                    <Mui.Box>
                      <Mui.IconButton
                        aria-label='Mer val'
                        aria-controls='place-menu'
                        aria-haspopup='true'
                        onClick={(e) => handleClickMoreMenu(e, favorite)}>
                        <Icon.MoreVertIcon color='primary' titleAccess='Mer val' />
                      </Mui.IconButton>
                    </Mui.Box>
                  </Mui.Box>
                  <Mui.Divider variant='fullWidth' />
                </Mui.Box>
              ))}
        </Mui.AccordionDetails>
      </Mui.Accordion>
      <Mui.Box my={6}>
        <Mui.Typography variant='h2' color='primary' tabIndex={0}>
          <FormattedMessage id='myPage.myFavourites.headingAdd' />
        </Mui.Typography>
        <Mui.Grid container spacing={3} alignItems='flex-end'>
          <Mui.Grid item xs={8} sm={6}>
            <Mui.Autocomplete
              fullWidth
              disablePortal
              disableClearable
              id='myFavourites'
              value={selectedAddress}
              options={addresses}
              filterOptions={(options) => options}
              getOptionLabel={(option) => option.address}
              onInputChange={handleOnInputChange}
              onChange={handleOnChange}
              onOpen={handleOnOpen}
              noOptionsText={<FormattedMessage id='myPage.myFavourites.noFavoriteAddressOptionsText' />}
              renderOption={({ address, type }, { selected }) => (
                <Mui.Box display='flex' alignItems='center' aria-label={address}>
                  {getTypeIcon(type)}
                  <Mui.Box ml={3}>{address}</Mui.Box>
                </Mui.Box>
              )}
              renderInput={(params) => (
                <Mui.TextField
                  {...params}
                  label={<FormattedMessage id='myPage.myFavourites.labelAdd' />}
                  margin='normal'
                  variant='standard'
                />
              )}
            />
          </Mui.Grid>
          <Mui.Grid item xs={4} sm={2}>
            <Mui.TextField
              fullWidth
              margin='normal'
              id='labelLetter'
              aria-labelledby={`myFavourites-label labelLetter-label`}
              label={<FormattedMessage id='myPage.myFavourites.labelLetter' />}
              value={addressLetter}
              onChange={(e) => setAddressLetter(e.target.value.toUpperCase())}
              inputProps={{ maxLength: 1 }}
              onInput={(e) => {
                if (!/^[A-Za-z]+$/.test(e.target.value)) {
                  e.target.value = '';
                }
              }}
            />
          </Mui.Grid>
          <Mui.Grid item xs={12} sm={4}>
            <Mui.Button
              fullWidth
              variant='outlined'
              color='primary'
              disableFocusRipple
              onClick={handleCreateFavoriteAddress}
              className={classes.createButton}>
              <FormattedMessage id='myPage.myFavourites.btnAddfavouritePlaces' />
            </Mui.Button>
          </Mui.Grid>
        </Mui.Grid>
        
        <Mui.Typography variant="srOnly" aria-live="polite" aria-atomic="true" role="status">Det finns {addresses.length} förslag i listan relaterade till din sökning på {addressInputValue}</Mui.Typography>
      </Mui.Box>
      <Mui.Menu
        id='place-menu'
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <Mui.MenuItem onClick={handleDeleteFavoriteAddress}>
          <Icon.RoomIcon color='primary' />
          <Mui.Box ml={2}>
            <FormattedMessage id='myPage.myFavourites.ddRemoveAsFav' />
          </Mui.Box>
        </Mui.MenuItem>
      </Mui.Menu>
    </>
  );
};

export default React.memo(FavoritePlacesContainer);
