import { combineReducers } from 'redux';

import auth from './auth/authReducer';
import booking from './booking/bookingReducer';
import dialog from './dialog/dialogReducer';
import snackbar from './snackbar/snackbarReducer';
import cms from './cms/cmsReducer';
import system from './system/systemReducer';
import trips from './trips/tripsReducer';

const rootReducer = combineReducers({
  auth,
  booking,
  dialog,
  snackbar,
  cms,
  system,
  trips,
});

export default rootReducer;
