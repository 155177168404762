import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { userPasswordUpdate } from '../../repository/user';
import { dialogCloseAllAction, dialogDisplayMessageAction } from '../../store/dialog/dialogActions';

import Mui from '../material';
import Icon from '../icons';

import NewsImage from '../../images/news.png';

const useStyles = makeStyles((theme) => ({
  image: {
    width: '100%',
  },
}));

const ChangePasswordDialog = ({ open, handleOnClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [errorCode, setErrorCode] = useState(null);
  const [validationCode, setValidationCode] = useState(null);
  const [isConfirmPasswordDirty, setIsConfirmPasswordDirty] = useState(false);

  const [oldPassword, setOldPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleOnSubmit = async () => {
    try {
      const request = {
        password: oldPassword,
        newPassword: newPassword,
      };

      await userPasswordUpdate(request);
      dispatch(dialogCloseAllAction());
      dispatch(dialogDisplayMessageAction('Lösenord uppdaterats'));
    } catch (error) {
      setErrorCode(error.code);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // reset error state when old password changes
  useEffect(() => {
    setErrorCode(null);
  }, [oldPassword]);

  useEffect(() => {
    let updatedCode = null;
    if (newPassword.length > 0 && confirmPassword.length > 0) {
      if (newPassword.length < 8) {
        updatedCode = 'tooShort';
      } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/.test(newPassword)) {
        updatedCode = 'format';
      } else if (isConfirmPasswordDirty && newPassword !== confirmPassword) {
        updatedCode = 'notMatching';
      }
    }

    setValidationCode(updatedCode);
  }, [newPassword, confirmPassword, isConfirmPasswordDirty]);

  return (
    <Mui.Dialog fullWidth maxWidth='xs' open={open} onClose={handleOnClose} aria-labelledby='dialog-title'>
      <Mui.DialogTitle id='dialog-title'>
        <FormattedMessage id='myPage.password.headingChangePsw' />
      </Mui.DialogTitle>
      <img src={NewsImage} alt='' className={classes.image} />
      <Mui.DialogContent>
        <Mui.TextField
          fullWidth
          error={errorCode !== null}
          margin='normal'
          id='old-password'
          autoComplete='off'
          label={<FormattedMessage id='myPage.password.old' />}
          type={showOldPassword ? 'text' : 'password'}
          value={oldPassword}
          onChange={(event) => setOldPassword(event.target.value)}
          InputProps={{
            startAdornment: (
              <Mui.InputAdornment position='start'>
                <Icon.LockIcon color='primary' />
              </Mui.InputAdornment>
            ),
            endAdornment: (
              <Mui.InputAdornment position='end'>
                <Mui.IconButton
                  aria-label={showOldPassword ? 'Dölj lösenord' : 'Visa lösenord'}
                  onClick={() => setShowOldPassword(!showOldPassword)}
                  onMouseDown={handleMouseDownPassword}>
                  {showOldPassword ? <Icon.Visibility /> : <Icon.VisibilityOff />}
                </Mui.IconButton>
              </Mui.InputAdornment>
            ),
          }}
          helperText={errorCode && <FormattedMessage id={`errors.password.${errorCode}`} />}
        />
        <Mui.TextField
          fullWidth
          error={validationCode !== null}
          margin='normal'
          id='new-password'
          autoComplete='off'
          label={<FormattedMessage id='myPage.password.new' />}
          type={showNewPassword ? 'text' : 'password'}
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
          InputProps={{
            startAdornment: (
              <Mui.InputAdornment position='start'>
                <Icon.LockIcon color='primary' />
              </Mui.InputAdornment>
            ),
            endAdornment: (
              <Mui.InputAdornment position='end'>
                <Mui.IconButton
                  aria-label={showNewPassword ? 'Dölj lösenord' : 'Visa lösenord'}
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  onMouseDown={handleMouseDownPassword}>
                  {showNewPassword ? <Icon.Visibility /> : <Icon.VisibilityOff />}
                </Mui.IconButton>
              </Mui.InputAdornment>
            ),
          }}
          helperText={validationCode && <FormattedMessage id={`validation.password.${validationCode}`} />}
        />
        <Mui.TextField
          fullWidth
          error={validationCode === 'notMatching'}
          margin='normal'
          id='confirm-password'
          autoComplete='off'
          label={<FormattedMessage id='myPage.password.repeat' />}
          type={showConfirmPassword ? 'text' : 'password'}
          value={confirmPassword}
          onChange={(event) => setConfirmPassword(event.target.value)}
          onBlur={() => setIsConfirmPasswordDirty(true)}
          InputProps={{
            startAdornment: (
              <Mui.InputAdornment position='start'>
                <Icon.LockIcon color='primary' />
              </Mui.InputAdornment>
            ),
            endAdornment: (
              <Mui.InputAdornment position='end'>
                <Mui.IconButton
                  aria-label={showConfirmPassword ? 'Dölj lösenord' : 'Visa lösenord'}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  onMouseDown={handleMouseDownPassword}>
                  {showConfirmPassword ? <Icon.Visibility /> : <Icon.VisibilityOff />}
                </Mui.IconButton>
              </Mui.InputAdornment>
            ),
          }}
        />
      </Mui.DialogContent>
      <Mui.DialogActions>
        <Mui.Button onClick={handleOnClose} color='primary' disableFocusRipple>
          <FormattedMessage id='dialog.btnCancel' />
        </Mui.Button>
        <Mui.Button
          onClick={handleOnSubmit}
          color='primary'
          disableFocusRipple
          disabled={oldPassword.length === 0 ||
            newPassword.length === 0 ||
            confirmPassword.length === 0 ||
            errorCode ||
            validationCode
          }
        >
          <FormattedMessage id='dialog.btnConfirm' />
        </Mui.Button>
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};

export default ChangePasswordDialog;
