import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { dialogDisplayMessageAction, dialogCloseAction } from '../../store/dialog/dialogActions';
import { userClearAuth } from '../../store/auth/authActions';

import Mui from '../material';

const InactiveUserDialog = ({ open, handleOnClose }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state.system);

  const handleClose = () => {
    dispatch(dialogCloseAction("inactiveUser"));
    clearTimeout(timer);
  };

  const handleConfirm = () => {
    handleClose();
  };

  const handleDeny = () => {
    handleClose();
    dispatch(userClearAuth());
    dispatch(dialogDisplayMessageAction('Din session har gått ut'));
  };

  const timer = setTimeout(() => {
    handleDeny();
  }, parseInt(settings?.app_warning_before_logout?.value) * 1000);

  return (
    <Mui.Dialog fullWidth maxWidth='xs' open={open} onClose={handleOnClose} aria-labelledby='dialog-title dialog-content dialog-actions'>
      <Mui.DialogTitle id='dialog-title'>
        <FormattedMessage id='general.inactiveUser.title' />
      </Mui.DialogTitle>
      <Mui.DialogContent id="dialog-content">
        <>
          <Mui.Typography
            dangerouslySetInnerHTML={{
              __html: intl.formatMessage({ id: 'general.inactiveUser.text' }),
            }}
          />
        </>
      </Mui.DialogContent>
      <Mui.DialogActions id="dialog-actions">
        <Mui.Button
          variant='contained'
          color='primary'
          onClick={() => handleConfirm()}
          disableFocusRipple>
          <FormattedMessage id='dialog.btnYes' />
        </Mui.Button>
        <Mui.Button
          variant='outlined'
          color='primary'
          onClick={() => handleDeny()}
          disableFocusRipple>
          <FormattedMessage id='dialog.btnNo' />
        </Mui.Button>
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};

export default React.memo(InactiveUserDialog);
