import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { selectIconsHelper } from '../utils/helpers';
import { dialogDisplayMessageAction } from '../store/dialog/dialogActions';

import Mui from '../components/material';
import Icon from '../components/icons';

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 3,
    margin: theme.spacing(2, 0),
  },
  item: {
    position: 'relative',
    padding: theme.spacing(2, 2),
    color: theme.palette.grey[600],
    border: '3px solid transparent',
    alignItems: 'flex-start',

    '&:hover': {
      border: `3px solid ${theme.palette.primary.main}`,
    },

    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      padding: theme.spacing(2, 3),
    },
  },
  itemDisable: {
    position: 'relative',
    opacity: 0.6,
    cursor: 'not-allowed',
    pointerEvents: 'none',
  },
  link: {
    display: 'block',
    textDecoration: 'none',
    flex: '1',
    color: theme.palette.grey[600],
  },
  tripInfo: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    maxWidth: '89%',
    paddingBottom: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(0),
    },
  },
  column: {
    flex: 1,
  },
  subtitle: {
    fontSize: '0.75rem',
    fontWeight: 500,
  },
  time: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 150,
  },
  addresses: {
    display: 'flex',
    flexDirection: 'column',
  },
  toAddress: {
    display: 'flex',
    alignItems: 'center',
  },
  arrow: {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      top: 10,
      right: 10,
    },
  },
  cancelCheckbox: {
    '&.Mui-focusVisible': {
      borderRadius: '50%',
      boxShadow: `0 0 0 3px ${theme.palette.primary.main}`,
    },
  },
}));

const TripItem = ({ trip, onSelect, cancelCheckbox }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (event) => {
    trip.checked = event.target.checked;
    onSelect(trip.id);
  };

  const handleOnInvalidAddress = (event) => {
    event.preventDefault();
    dispatch(dialogDisplayMessageAction('Adressfel'));
  };

  const renderTripDetails = (isInvalid) => {
    return (
      <Mui.Grid container alignItems='center'>
        <Mui.Grid item xs={12} sm className={classes.tripInfo}>
          {selectIconsHelper(trip.travelType)}
          <Mui.Box mr={2} ml={4}>
            <Mui.Typography variant='body2' color='textPrimary'>
              {trip.pickupAddress?.strName} {trip.pickupAddress?.strNbr}
              {trip.pickupAddress?.strNbrLetter}
            </Mui.Typography>
            <Mui.Box className={classes.toAddress}>
              <Icon.RoomRoundedIcon color='disabled' />
              <Mui.Typography variant='body2' color='textPrimary'>
                {trip.dropOffAddress?.strName} {trip.dropOffAddress?.strNbr}
                {trip.dropOffAddress?.strNbrLetter}
              </Mui.Typography>
            </Mui.Box>
          </Mui.Box>
        </Mui.Grid>
        <Mui.Grid item xs={12} sm className={classes.time}>
          <Icon.WatchLaterIcon color='primary' />
          <Mui.Box className={classes.column} mr={2} ml={4}>
            <Mui.Typography variant='body1' color='primary' className={classes.subtitle}>
              Avgång
            </Mui.Typography>
            <Mui.Typography color='textPrimary'>
              <Moment format='HH:mm'>{trip.promisedTime}</Moment>
            </Mui.Typography>
          </Mui.Box>
          {trip.latestRequestedDropOffTime ? (
            <>
              <Icon.ArrowRightAltRoundedIcon color='disabled' />
              <Mui.Box className={classes.column} mx={2}>
                <Mui.Typography variant='body1' color='primary' className={classes.subtitle}>
                  Senast ankomst
                </Mui.Typography>
                <Mui.Typography color='textPrimary'>
                  <Moment format='HH:mm'>{trip.latestRequestedDropOffTime}</Moment>
                </Mui.Typography>
              </Mui.Box>
            </>
          ) : null}
        </Mui.Grid>
        {isInvalid && (
          <Mui.Grid item className={classes.arrow}>
            <Icon.ArrowForwardRoundedIcon color='primary' />
          </Mui.Grid>
        )}
      </Mui.Grid>
    );
  };

  return (
    <Mui.Paper className={classes.paper}>
      <Mui.Grid container className={classes.item} wrap='nowrap' alignItems='center'>
        {cancelCheckbox && (
          <Mui.Grid item>
            <Mui.Box mr={2}>
              {trip.travelType.deletable ? (
                <Mui.Checkbox
                  className={classes.cancelCheckbox}
                  color='primary'
                  checked={trip.checked}
                  onChange={handleChange}
                  inputProps={{
                    'aria-label': `Markera resan från ${trip.pickupAddress?.strName} till ${trip.dropOffAddress?.strName}`,
                  }}
                />
              ) : (
                <Mui.Tooltip title='Kan ej avbokas i appen'>
                  <span>
                    <Mui.Checkbox
                      className={classes.cancelCheckbox}
                      color='primary'
                      checked={false}
                      disabled={true}
                      onChange={handleChange}
                      inputProps={{
                        'aria-label': `Markera resan från ${trip.pickupAddress?.strName} till ${trip.dropOffAddress?.strName}`,
                      }}
                    />
                  </span>
                </Mui.Tooltip>
              )}
            </Mui.Box>
          </Mui.Grid>
        )}
        <Mui.Grid item className={classes.column}>
          {trip.validAddresses ? (
            <Link
              id={`trip-item--${trip.id}`}
              to={`/mina-resor/${trip.id}`}
              className={trip.travelType ? classes.link : classes.itemDisable}
              aria-label={`${moment(trip.promisedTime).format('dddd, DD MMMM, YYYY')}, ${trip.travelType?.name}, från ${
                trip.pickupAddress?.strName
              } ${trip.pickupAddress?.strNbr}${trip.pickupAddress?.strNbrLetter} till ${trip.dropOffAddress?.strName} ${
                trip.dropOffAddress?.strNbr
              }${trip.dropOffAddress?.strNbrLetter}, Avgång ${moment(trip.promisedTime).format('HH:mm')} ${
                trip.latestRequestedDropOffTime
                  ? `Senast ankomst ${moment(trip.latestRequestedDropOffTime).format('HH:mm')}`
                  : ''
              }`}>
              {renderTripDetails(trip.validAddresses)}
            </Link>
          ) : (
            <Link
              id={`trip-item--${trip.id}`}
              to={`/mina-resor/${trip.id}`}
              className={trip.travelType ? classes.link : classes.itemDisable}
              aria-label={`${moment(trip.promisedTime).format('dddd, DD MMMM, YYYY')}, ${trip.travelType?.name}, från ${
                trip.pickupAddress?.strName
              } ${trip.pickupAddress?.strNbr}${trip.pickupAddress?.strNbrLetter} till ${trip.dropOffAddress?.strName} ${
                trip.dropOffAddress?.strNbr
              }${trip.dropOffAddress?.strNbrLetter}, Avgång ${moment(trip.promisedTime).format('HH:mm')} ${
                trip.latestRequestedDropOffTime
                  ? `Senast ankomst ${moment(trip.latestRequestedDropOffTime).format('HH:mm')}`
                  : ''
              }`}
              onClick={(event) => handleOnInvalidAddress(event)}>
              {renderTripDetails(trip.validAddresses)}
            </Link>
          )}
        </Mui.Grid>
      </Mui.Grid>
    </Mui.Paper>
  );
};

export default TripItem;
