import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { themes } from '../../themes/themes';

import Mui from '../../components/material';

const useStyles = makeStyles((theme) => ({
  headline: {
    textAlign: 'center',
  },
  content: {
    fontSize: '1.125rem',
    wordBreak: 'break-word',
    '& p': {
      fontSize: '1.125rem',
    },
    '& a': {
      color: theme.palette.primary.main,
    },
  },
}));

const Accessibility = () => {
  const intl = useIntl();
  const classes = useStyles();

  const { sitemap } = useSelector((state) => state.cms);
  const [page, setPage] = useState(null);

  useEffect(() => {
    const page = sitemap.find((item) => item.name === 'Tillgänglighet');
    setPage(page);
  }, [sitemap]);

  return (
    <>
      <Helmet>
        <title>
          {`Tillgänglighet | ${intl.formatMessage({ id: `app.title.${window.__ENV__.REACT_APP_THEME}` })} ${
            themes[window.__ENV__.REACT_APP_THEME].name
          }`}
        </title>
      </Helmet>
      <Mui.Container maxWidth='sm'>
        <Mui.Box pt={6} pb={3}>
          <Mui.Typography variant='h1' className={classes.headline}>
            <FormattedMessage id='accessibility.heading' />
          </Mui.Typography>
        </Mui.Box>
        <Mui.Box mb={6}>
          {page && <div className={classes.content} dangerouslySetInnerHTML={{ __html: page.content }}></div>}
        </Mui.Box>
      </Mui.Container>
    </>
  );
};

export default React.memo(Accessibility);
