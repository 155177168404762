import React from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { systemInformation } from '../repository/public';
import { dialogDisplayInformationAction } from '../store/dialog/dialogActions';

import Mui from '../components/material';
import TabsLogin from '../components/TabsLogin';
import Banner from '../components/Banner';

import LoginAccountImage from '../images/login-account@3x.png';
import { themes } from '../themes/themes';

const useStyles = makeStyles((theme) => ({
  media: {
    maxWidth: '100%',
  },
  headline: {
    fontSize: '2rem',
  },
  title: {
    fontSize: '1.5rem',
  },
}));

const Login = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { messages } = useSelector((state) => state.cms);

  const getLoginText = () => {
    if (messages) {
      const result = messages.find((item) => item.name === 'Info-inlog');
      if (result) return result.content;
      return '';
    }
  };

  useQuery('systemInformation', systemInformation, {
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      if (data.length > 0) {
        dispatch(dialogDisplayInformationAction(data));
      }
    },
  });

  return (
    <>
      <Helmet>
        <title>
          {`Logga in | ${intl.formatMessage({ id: `app.title.${window.__ENV__.REACT_APP_THEME}` })} ${
            themes[window.__ENV__.REACT_APP_THEME].name
          }`}
        </title>
      </Helmet>

      <Banner />
      <Mui.Container maxWidth='md'>
        <Mui.Box pt={6} pb={3}>
          <Mui.Typography variant='h2' className={classes.headline} tabIndex={0}>
            <FormattedMessage id='login.heading' />
          </Mui.Typography>
        </Mui.Box>
        <Mui.Box mb={4}>
          <Mui.Grid container spacing={2}>
            <Mui.Grid item sm={6}>
              <TabsLogin />
            </Mui.Grid>
            <Mui.Grid item sm={6}>
              <Mui.Box mb={6}>
                <Mui.Card>
                  <Mui.CardContent>
                    <img src={LoginAccountImage} alt="" className={classes.media} />
                    <Mui.Typography variant="h3" color="primary" className={classes.title} tabIndex={0}>
                      <FormattedMessage id="login.subHeading" />
                    </Mui.Typography>
                    <Mui.Typography
                      variant="body1"
                      color="initial"
                      tabIndex={0}
                      dangerouslySetInnerHTML={{ __html: getLoginText() }}
                    />
                  </Mui.CardContent>
                </Mui.Card>
              </Mui.Box>
            </Mui.Grid>
          </Mui.Grid>
        </Mui.Box>
      </Mui.Container>
    </>
  );
};

export default React.memo(Login);
