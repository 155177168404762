import { withStyles } from '@material-ui/core/styles';

import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const StyledToggleButtonGroup = withStyles((theme) => ({
  root: {
    flexWrap: 'wrap',
  },
  grouped: {
    margin: theme.spacing(2),
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main} !important`,
    },
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:last-child': {
      marginRight: 0,
    },
    '&:first-child': {
      marginLeft: 0,
      borderRadius: theme.shape.borderRadius,
    },
  },
  groupedHorizontal: {
    marginTop: 0,

    '&:not(:last-child)': {},
    '&:not(:first-child)': {
      borderRadius: '4px !important',
      border: `1px solid ${theme.palette.primary.main} !important`,
    },
    '&.Mui-selected': {
      border: `1px solid ${theme.palette.primary.main} !important`,
    },
  },
}))(ToggleButtonGroup);

export default StyledToggleButtonGroup;
