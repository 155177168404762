import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Mui from './material';
import Icon from './icons';

const useStyles = makeStyles((theme) => ({
  linkList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  linkListItem: {},
  link: {
    display: 'flex',
    alignItems: 'flex-end',
    fontSize: '1rem',
    color: theme.palette.common.white,
    textDecoration: 'none',
    padding: '4px 0',
    margin: '2px 0',
    '&:focus, &:active': {
      outline: `3px solid ${theme.palette.common.white}`,
    },
  },
  linkIcon: {
    color: theme.palette.common.white,
    margin: theme.spacing(0, 3, 0, 1),
  },
  name: {
    flex: '1',
    fontWeight: 300,
    letterSpacing: 0.48,
  },
  divider: {
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
  },
}));

const FooterLinkList = ({ links }) => {
  const classes = useStyles();

  return (
    <ul className={classes.linkList}>
      {links.map((link) => (
        <li key={link.id} className={classes.linkListItem}>
          {link.type === 'INTERN' ? (
            <Link to={link.url} className={classes.link}>
              <Icon.ArrowForwardRoundedIcon className={classes.linkIcon} />
              <Mui.Typography className={classes.name}>{link.name}</Mui.Typography>
            </Link>
          ) : (
            <a href={link.url} target='_blank' rel='noopener noreferrer' className={classes.link}>
              <Icon.ArrowForwardRoundedIcon className={classes.linkIcon} />
              <Mui.Typography className={classes.name}>{link.name}</Mui.Typography>
              <Icon.LaunchRoundedIcon className={classes.linkIcon} titleAccess='. Extern sida, öppnas i nytt fönster' />
            </a>
          )}
          <Mui.Divider variant='fullWidth' classes={{ root: classes.divider }} />
        </li>
      ))}
    </ul>
  );
};

export default FooterLinkList;
