import http from './httpService';

// POST
// ​/search/address
export const addressSearch = async (request) => {
  const res = await http.post(`/search/address`, request);
  return res.data.data;
};

// POST
// ​/search/address/all
export const addressSearchAll = async (request) => {
  const res = await http.post(`/search/address/all`, request);
  return res.data.data;
};
