import CONSTANTS from '../constants';

import { tripsOrderSave, tripsOrderUpdate } from '../../repository/trips';
import { dialogDisplayMessageAction } from '../dialog/dialogActions';
import { snackbarOpenAction } from '../snackbar/snackbarActions';

export const submitBookingOrderAction = (history) => async (dispatch, getState) => {
  const { booking } = getState();

  try {
    const request = {
      ...booking,
      pickupAddress: booking.pickupAddress.address,
      dropoffAddress: booking.dropoffAddress.address,
    };
    dispatch({ type: CONSTANTS.ADD_LOADER, payload: 'tripsOrderSave' });
    const data = await tripsOrderSave(request);

    if (data.orderId) {
      dispatch(clearBookingAction());
      dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'tripsOrderSave' });
      dispatch(snackbarOpenAction('Tack för din bokning'));
      history.push(`/mina-resor/${data.orderId}`);
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'tripsOrderSave' });
    dispatch(dialogDisplayMessageAction(error.code));
  }
};

export const updateBookingOrderAction = (orderId, history) => async (dispatch, getState) => {
  const { booking } = getState();

  try {
    const request = {
      ...booking,
      orderId: parseInt(orderId),
      pickupAddress: booking.pickupAddress.address,
      dropoffAddress: booking.dropoffAddress.address,
    };

    dispatch({ type: CONSTANTS.ADD_LOADER, payload: 'tripsOrderUpdate' });
    const { success, data } = await tripsOrderUpdate(request);

    if (success) {
      dispatch(clearBookingAction());
      dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'tripsOrderUpdate' });
      dispatch(snackbarOpenAction('Din bokning har blivit ändrad'));
      history.push(`/mina-resor/${data.orderId}`);
    }
  } catch (error) {
    console.log(error);
    dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'tripsOrderUpdate' });
    dispatch(dialogDisplayMessageAction(error.code));
  }
};

export const clearBookingAction = () => {
  return { type: CONSTANTS.CLEAR_BOOKING };
};

export const setBookingAction = (booking) => {
  return { type: CONSTANTS.SET_BOOKING, payload: booking };
};

export const setPickupAddressAction = (address) => {
  return { type: CONSTANTS.SET_PICKUP_ADDRESS, payload: address };
};

export const setDropoffAddressAction = (address) => {
  return { type: CONSTANTS.SET_DROPOFF_ADDRESS, payload: address };
};

export const setDropoffLetterAction = (letter) => {
  return { type: CONSTANTS.SET_DROPOFF_LETTER, payload: letter };
};

export const setPickupLetterAction = (letter) => {
  return { type: CONSTANTS.SET_PICKUP_LETTER, payload: letter };
};

export const bookingSwapAddressesAction = () => {
  return { type: CONSTANTS.SWAP_ADDESSES };
};

export const setRequestDropoffTimeAction = (date) => {
  return { type: CONSTANTS.SET_REQUEST_DROPOFF_TIME, payload: date };
};

export const setRequestPickupTimeAction = (date) => {
  return { type: CONSTANTS.SET_REQUEST_PICKUP_TIME, payload: date };
};

export const setExtraAidsAction = (extraAids) => {
  return { type: CONSTANTS.SET_EXTRA_AIDS, payload: extraAids };
};

export const setNbrCompanionsAction = (nbrCompanions) => {
  return { type: CONSTANTS.SET_NBR_COMPANIONS, payload: nbrCompanions };
};

export const setNbrCoTravellersAction = (nbrCoTravellers) => {
  return { type: CONSTANTS.SET_CO_TRAVELLERS, payload: nbrCoTravellers };
};

export const setPickupHelpAction = (pickupHelp) => {
  return { type: CONSTANTS.SET_PICKUP_HELP, payload: pickupHelp };
};

export const setDropoffHelpAction = (dropoffHelp) => {
  return { type: CONSTANTS.SET_DROPOFF_HELP, payload: dropoffHelp };
};
