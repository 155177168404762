import DriveEtaRoundedIcon from '@material-ui/icons/DriveEtaRounded';
import AdjustOutlinedIcon from '@material-ui/icons/AdjustOutlined';
import RoomIcon from '@material-ui/icons/Room';
import DirectionsRoundedIcon from '@material-ui/icons/DirectionsRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RadioButtonUncheckedRoundedIcon from '@material-ui/icons/RadioButtonUncheckedRounded';
import WatchLaterRoundedIcon from '@material-ui/icons/WatchLaterRounded';
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded';
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';
import KeyboardReturnRoundedIcon from '@material-ui/icons/KeyboardReturnRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import RoomRoundedIcon from '@material-ui/icons/RoomRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import FlagRoundedIcon from '@material-ui/icons/FlagRounded';
import DirectionsBusRoundedIcon from '@material-ui/icons/DirectionsBusRounded';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AttachmentRoundedIcon from '@material-ui/icons/AttachmentRounded';
import PersonIcon from '@material-ui/icons/Person';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import EventIcon from '@material-ui/icons/Event';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';
import FavoriteIcon from '@material-ui/icons/Favorite';
import NoteAddRoundedIcon from '@material-ui/icons/NoteAddRounded';
import MapRoundedIcon from '@material-ui/icons/MapRounded';
import ExtensionRoundedIcon from '@material-ui/icons/ExtensionRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import AccountBalanceWalletRoundedIcon from '@material-ui/icons/AccountBalanceWalletRounded';

const icons ={
  AccountBalanceWalletRoundedIcon,
  AccountCircleRoundedIcon,
  SwapHorizIcon,
  DriveEtaRoundedIcon,
  AdjustOutlinedIcon,
  RoomIcon,
  DirectionsRoundedIcon,
  ExpandMoreIcon,
  RadioButtonUncheckedRoundedIcon,
  WatchLaterRoundedIcon,
  ArrowRightAltRoundedIcon,
  FavoriteRoundedIcon,
  KeyboardReturnRoundedIcon,
  EditRoundedIcon,
  DeleteRoundedIcon,
  RoomRoundedIcon,
  ArrowForwardRoundedIcon,
  WatchLaterIcon,
  HomeRoundedIcon,
  FlagRoundedIcon,
  DirectionsBusRoundedIcon,
  PhoneRoundedIcon,
  EmailRoundedIcon,
  Visibility,
  VisibilityOff,
  LockIcon,
  MoreVertIcon,
  AttachmentRoundedIcon,
  PersonIcon,
  AddLocationIcon,
  EventIcon,
  PeopleAltRoundedIcon,
  LaunchRoundedIcon,
  FavoriteIcon,
  NoteAddRoundedIcon,
  MapRoundedIcon,
  ExtensionRoundedIcon,
  CheckCircleRoundedIcon,
  CloseRoundedIcon,
};
export default icons;
