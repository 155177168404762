import CONSTANTS from '../constants';

const initialState = {
  open: false,
  message: '',
};

const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.OPEN_SNACKBAR:
      return {
        ...state,
        open: true,
        message: action.payload,
      };

    case CONSTANTS.CLOSE_SNACKBAR:
      return initialState;

    default:
      return state;
  }
};

export default snackbarReducer;
