import CONSTANTS from '../constants';
import { systemCmsList } from '../../repository/public';

export const cmsListAction = () => async (dispatch) => {
  try {
    const { cms } = await systemCmsList();
    dispatch({ type: CONSTANTS.SET_CMS, payload: cms });
  } catch (error) {
    console.log(error);
  }
};
