import React, { useState, useEffect, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { tripsNotifications } from '../../repository/trips';
import { setPickupHelpAction, setDropoffHelpAction } from '../../store/booking/bookingActions';

import Mui from '../../components/material';
import Icon from '../../components/icons';
import { dialogDisplayMessageAction } from '../../store/dialog/dialogActions';
import { validatePhoneNbr } from '../../utils/validators';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  card: {
    marginTop: '1em',
  },
}));

const InstructionsContainer = forwardRef(function InstructionsContainer ({ travelType }, ref) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const enviroments = ['gbgstad'];
  const isSMF = enviroments.includes(window.__ENV__.REACT_APP_THEME) && travelType.type.toLowerCase() !== 'fx';

  const { pickupHelp, dropoffHelp } = useSelector((state) => state.booking);
  const [driverInstructions, setDriverInstructions] = useState([]);

  const [phoneNbrError, setPhoneNbrError] = useState(false);
  const [phoneNbrHelpText, setPhoneNbrHelpText] = useState('Obligatoriskt');
  const [extraPhoneNbrError, setExtraPhoneNbrError] = useState(false);
  const [extraPhoneNbrHelpText, setExtraPhoneNbrHelpText] = useState('');

  const handlePickupHelpChange = ({ target: { value } }) => {
    const item = driverInstructions.find((item) => item.id === value);
    if (item) {
      dispatch(setPickupHelpAction({ ...item, contactPhonenumber: pickupHelp[0].contactPhonenumber }));
    } else {
      dispatch(setPickupHelpAction({ id: 0, contactPhonenumber: pickupHelp[0].contactPhonenumber }));
    }
  };

  const handleDropOffHelpChange = ({ target: { value } }) => {
    const item = driverInstructions.find((item) => item.id === value);
    if (item) {
      dispatch(setDropoffHelpAction({ ...item, contactPhonenumber: dropoffHelp[0].contactPhonenumber }));
    } else {
      dispatch(setDropoffHelpAction({ id: 0, contactPhonenumber: dropoffHelp[0].contactPhonenumber }));
    }
  };

  const handlePickupPhoneNbr = ({ target: { value } }) => {
    dispatch(setPickupHelpAction({ ...pickupHelp[0], contactPhonenumber: value }));
  };

  const handleDropoffPhoneNbr = ({ target: { value } }) => {
    dispatch(setDropoffHelpAction({ ...dropoffHelp[0], contactPhonenumber: value }));
  };

  const handleDisplayHelp = () => {
    dispatch(dialogDisplayMessageAction('Instruktioner till föraren'));
  };

  useEffect(() => {
    const fetchDriverInstructions = async () => {
      const { driverInstructions } = await tripsNotifications();
      setDriverInstructions(driverInstructions);
    };
    fetchDriverInstructions();
  }, []);

  return (
    <Mui.Card className={classes.card}>
      <Mui.CardContent
        aria-controls='instructionToDriver-content'
        id='instructionToDriver-header'
        role="region"
        aria-label="Instruktioner till föraren"
      >
        <Mui.Typography className={classes.header} variant='h2'>
          <Icon.NoteAddRoundedIcon color='primary' className={classes.icon} />
          <FormattedMessage id='bookTrip.instructionToDriver.heading' />
        </Mui.Typography>
        
        <Mui.Box display='flex' justifyContent='flex-end'>
          <Mui.Button variant='text' color='primary' onClick={handleDisplayHelp} disableFocusRipple>
            Hjälp
          </Mui.Button>
        </Mui.Box>
        <Mui.Box display='flex' alignItems='center'>
          <Icon.RadioButtonUncheckedRoundedIcon color='primary' className={classes.icon} />
          <Mui.Typography id='instructionToDriver.tripFrom' variant='h4' color='primary'>
            <FormattedMessage id='bookTrip.instructionToDriver.tripFrom' />
          </Mui.Typography>
        </Mui.Box>
        <Mui.Grid container spacing={3}>
          {driverInstructions.length > 0 ? (
            <Mui.Grid item xs={12} sm={6}>
              <Mui.FormControl fullWidth margin='normal'>
                <Mui.InputLabel id='pickup-instructions-label'>
                  <FormattedMessage id='bookTrip.instructionToDriver.wantWhatHelp' />
                </Mui.InputLabel>
                <Mui.Select
                  id='pickup-instructions'
                  labelId='pickup-instructions-label'
                  aria-labelledby='instructionToDriver.tripFrom pickup-instructions-label'
                  value={pickupHelp[0].id}
                  onChange={handlePickupHelpChange}>
                  <Mui.MenuItem value={0}>Inget</Mui.MenuItem>
                  {driverInstructions.map((item) => (
                    <Mui.MenuItem key={item.id} value={item.id}>
                      {item.text}
                    </Mui.MenuItem>
                  ))}
                </Mui.Select>
              </Mui.FormControl>
            </Mui.Grid>
          ) : null}
          <Mui.Grid item xs={12} sm={6}>
            <Mui.TextField
              required
              disabled={isSMF}
              fullWidth
              margin='normal'
              autoComplete='chrome-off'
              type='tel'
              id='numberToContactPerson'
              error={phoneNbrError}
              helperText={phoneNbrHelpText}
              label={<FormattedMessage id='bookTrip.instructionToDriver.numberToContactPerson' />}
              value={pickupHelp[0].contactPhonenumber}
              inputProps={{
                maxLength: 15,
              }}
              onBlur={(e) => {
                const { error, helptext } = validatePhoneNbr(e.target.value, 'Obligatoriskt');
                setPhoneNbrError(error);
                setPhoneNbrHelpText(helptext);
              }}
              onChange={handlePickupPhoneNbr}
              inputRef={ref}
            />
          </Mui.Grid>
        </Mui.Grid>
        {driverInstructions.length > 0 || !isSMF ? (
          <>
            <Mui.Box display='flex' alignItems='center' mt={4}>
              <Icon.RoomIcon color='primary' className={classes.icon} />
              <Mui.Typography id='instructionToDriver.tripTo' variant='h4' color='primary'>
                <FormattedMessage id='bookTrip.instructionToDriver.tripTo' />
              </Mui.Typography>
            </Mui.Box>
            <Mui.Grid container spacing={3}>
              {driverInstructions.length > 0 ? (
                <Mui.Grid item xs={12} sm={6}>
                  <Mui.FormControl fullWidth margin='normal'>
                    <Mui.InputLabel id='dropoff-instructions-label'>
                      <FormattedMessage id='bookTrip.instructionToDriver.wantWhatHelp' />
                    </Mui.InputLabel>
                    <Mui.Select
                      id='dropoff-instructions'
                      labelId='dropoff-instructions-label'
                      aria-labelledby='instructionToDriver.tripTo dropoff-instructions-label'
                      value={dropoffHelp[0].id}
                      onChange={handleDropOffHelpChange}>
                      <Mui.MenuItem value={0}>Inget</Mui.MenuItem>
                      {driverInstructions.map((item) => (
                        <Mui.MenuItem key={item.id} value={item.id}>
                          {item.text}
                        </Mui.MenuItem>
                      ))}
                    </Mui.Select>
                  </Mui.FormControl>
                </Mui.Grid>
              ) : null}
              {!isSMF ? (
                <Mui.Grid item xs={12} sm={6}>
                  <Mui.TextField
                    fullWidth
                    margin='normal'
                    id='extraPhoneNumber'
                    autoComplete='chrome-off'
                    type='tel'
                    error={extraPhoneNbrError}
                    helperText={extraPhoneNbrHelpText}
                    label={<FormattedMessage id='bookTrip.instructionToDriver.extraPhoneNumber' />}
                    value={dropoffHelp[0].contactPhonenumber}
                    inputProps={{
                      maxLength: 15,
                    }}
                    onBlur={(e) => {
                      if (e.target.value) {
                        const { error, helptext } = validatePhoneNbr(e.target.value, '');
                        setExtraPhoneNbrError(error);
                        setExtraPhoneNbrHelpText(helptext);
                      }
                    }}
                    onChange={handleDropoffPhoneNbr}
                  />
                </Mui.Grid>
              ) : null}
            </Mui.Grid>
          </>
        ) : null}
      </Mui.CardContent>
    </Mui.Card>
  );
});

export default React.memo(InstructionsContainer);
