import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { validatePhoneNbr } from '../../utils/validators';
import { getDisplayName } from '../../utils/helpers';
import { setPickupHelpAction } from '../../store/booking/bookingActions';

import Mui from '../../components/material';
import Icon from '../../components/icons';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  card: {
    marginTop: '1em',
  },
}));

const TravellerContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const types = ['fx', 'olt'];

  const { type } = useParams();
  const { user, primaryUser } = useSelector((state) => state.auth);
  const { pickupHelp } = useSelector((state) => state.booking);

  const [phoneNbrError, setPhoneNbrError] = useState(false);
  const [phoneNbrHelpText, setPhoneNbrHelpText] = useState('Obligatoriskt');

  const handlePickupPhoneNbr = ({ target: { value } }) => {
    dispatch(setPickupHelpAction({ ...pickupHelp[0], contactPhonenumber: value }));
  };

  return (
    <Mui.Card className={classes.card}>
      <Mui.CardContent
        aria-controls='traveller-content'
        id='traveller-header'
        role="region"
        aria-label="Information om resenär"
        tabIndex={0}
      >
        <Mui.Typography className={classes.header} variant='h2'>
          <Icon.AccountCircleRoundedIcon color='primary' className={classes.icon} />
          <FormattedMessage id='bookTrip.traveler.heading' />
        </Mui.Typography>
        
        <Mui.Grid aria-labelledby="bookTrip.traveler.name" tabIndex={0}>
          <Mui.Grid item sm={6}>
            <Mui.Typography variant='body1' color='textSecondary' id="bookTrip.traveler.name">
              {getDisplayName(user)} {user.username === primaryUser.username ? '(Inloggad)' : null}
            </Mui.Typography>
          </Mui.Grid>
          {types.includes(type.toLowerCase()) && (
            <>
              <Mui.Grid item xs={12}></Mui.Grid>
              <Mui.Grid item sm={6}>
                <Mui.TextField
                  fullWidth
                  required
                  disabled={['olt'].includes(type.toLowerCase())}
                  margin='normal'
                  autoComplete='chrome-off'
                  id='numberToContactPerson'
                  error={phoneNbrError}
                  helperText={phoneNbrHelpText}
                  label={<FormattedMessage id='bookTrip.instructionToDriver.numberToContactPerson' />}
                  value={pickupHelp[0].contactPhonenumber}
                  inputProps={{
                    maxLength: 15,
                  }}
                  onBlur={(e) => {
                    const { error, helptext } = validatePhoneNbr(e.target.value, 'Obligatoriskt');
                    setPhoneNbrError(error);
                    setPhoneNbrHelpText(helptext);
                  }}
                  onChange={handlePickupPhoneNbr}
                />
              </Mui.Grid>
            </>
          )}
        </Mui.Grid>
      </Mui.CardContent>
    </Mui.Card>
  );
};

export default React.memo(TravellerContainer);
