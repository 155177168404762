import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';

import { userClearAuth, agentTravellerLoginAction, agentTravellerLogoutAction } from '../store/auth/authActions';
import { agentTravellerListPrimary } from '../repository/agent';
import { getDisplayName } from '../utils/helpers';

import Mui from './material';
import UserInitials from './UserInitials';

const useStyles = makeStyles((theme) => ({
  formControl: {
    [theme.breakpoints.down('sm')]: {
      flex: '1',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 275,
    },
  },
}));

const SelectUser = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { user, primaryUser } = useSelector((state) => state.auth);
  const { data: travellers } = useQuery('agentTravellerListPrimary', agentTravellerListPrimary, {
    refetchOnWindowFocus: false,
    retry: false,
  });

  const [activeUser, setActiveUser] = useState(primaryUser);

  const handleChange = async ({ target: { value } }) => {
    if (value.type === 'logout') {
      dispatch(userClearAuth());
    } else {
      if (value.id === primaryUser.id) {
        dispatch(agentTravellerLogoutAction(activeUser.username, history));
      } else {
        dispatch(agentTravellerLoginAction(value.username, history));
      }
      setActiveUser(value);
    }
  };

  return (
    <>
      <Mui.Box mr={2}>
        <UserInitials user={user} size='small' />
      </Mui.Box>

      <Mui.TextField id='select-user' value={activeUser} onChange={handleChange} className={classes.formControl} select>
        <Mui.MenuItem value={primaryUser}>
          {getDisplayName(primaryUser)} (inloggad)
        </Mui.MenuItem>
        {travellers &&
          travellers.map((user) => (
            <Mui.MenuItem key={user.id} value={user}>
              {getDisplayName(user)}
            </Mui.MenuItem>
          ))}
        <Mui.MenuItem value={{ type: 'logout' }}>Logga ut</Mui.MenuItem>
      </Mui.TextField>
    </>
  );
};

export default React.memo(SelectUser);
