import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Mui from './material';

import { selectIconsHelper } from '../utils/helpers';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'block',
    width: '100%',
    textDecoration: 'none',

    '&:focus, &:active': {
      outline: `3px solid ${theme.palette.primary.main}`,
    },
  },
  disable: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    pointerEvents: 'none',
  },
  errorMsg: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: theme.spacing(1),
  },
  header: {
    display: 'flex',
    padding: theme.spacing(0, 0, 1),
    alignItems: 'center',
  },
  name: {
    flexGrow: '1',
    marginLeft: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  info: {
    fontSize: '0.875rem',
    lineHeight: 1.7,
    color: theme.palette.grey[600],
    padding: theme.spacing(0, 0, 1),
  },
}));

const FavoriteTripItem = ({ trip, status }) => {
  const classes = useStyles();

  return (
    <>
      <Mui.ListItem className={status.disable ? classes.disable : null}>
        <Link
          to={`/boka-resa/favorit/${trip.id}/${trip.orderJson.travellers[0].travelType.type}/${trip.orderJson.travellers[0].travelType.travelTypeId}`}
          className={classes.link}
          aria-label={`${trip.name}, ${trip.orderJson.travellers[0].travelType.name}, ${
            status.disable ? `${status.msg},` : ''
          } från ${trip.orderJson.pickup.strName} ${trip.orderJson.pickup.strNbr}${trip.orderJson.pickup.strNbrLetter}, ${
            trip.orderJson.pickup.city
          } till ${trip.orderJson.dropoff.strName} ${trip.orderJson.dropoff.strNbr}${trip.orderJson.dropoff.strNbrLetter}, ${
            trip.orderJson.dropoff.city
          } `}>
          <Mui.Box className={classes.header}>
            {selectIconsHelper(trip?.orderJson?.travellers[0]?.travelType, status.disable ? 'disabled' : 'primary')}
            <Mui.Typography variant='h4' color='textPrimary' classes={{ root: classes.name }}>
              {trip.name}
            </Mui.Typography>
          </Mui.Box>
          {status.disable && (
            <Mui.Box>
              <Mui.Typography color='textPrimary' className={classes.errorMsg}>
                {status.msg}
              </Mui.Typography>
            </Mui.Box>
          )}
          <Mui.Box className={classes.info}>
            <FormattedMessage id='start.from' />
            <span>{trip.orderJson.pickup.strName} </span>
            <span>
              {trip.orderJson.pickup.strNbr}
              {trip.orderJson.pickup.strNbrLetter},
            </span>{' '}
            <span>{trip.orderJson.dropoff.city}</span>
            <br />
            <FormattedMessage id='start.to' />
            <span>{trip.orderJson.dropoff.strName} </span>
            <span>
              {trip.orderJson.dropoff.strNbr}
              {trip.orderJson.dropoff.strNbrLetter},
            </span>{' '}
            <span>{trip.orderJson.dropoff.city}</span>
          </Mui.Box>
        </Link>
      </Mui.ListItem>
      <Mui.Divider variant='middle' aria-hidden='true' />
    </>
  );
};

export default FavoriteTripItem;
