import CONSTANTS from '../constants';

const initialState = {
  content: null,
  displayMessage: false,
  displayInformation: false,
  changePassword: false,
  deleteUser: false,
  deleteTrip: false,
  deleteMultipleTrip: false,
  createFavoriteTrip: false,
  deleteFavoriteTrip: false,
  updateFavoriteTrip: false,
  deleteAgent: false,
  deleteTraveller: false,
  forgottenPassword: false,
  inactiveUser: false,
};

const dialogReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.OPEN_DIALOG:
      return {
        ...state,
        [action.payload.dialog]: true,
        content: action.payload.content,
      };

    case CONSTANTS.CLOSE_DIALOG:
      return {
        ...state,
        [action.payload.dialog]: false,
        content: null,
      };

    case CONSTANTS.CLOSE_ALL_DIALOG:
      return initialState;

    default:
      return state;
  }
};

export default dialogReducer;
