import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { setExtraAidsAction, setNbrCoTravellersAction, setNbrCompanionsAction } from '../../store/booking/bookingActions';
import { dialogDisplayMessageAction } from '../../store/dialog/dialogActions';

import Mui from '../../components/material';
import Icon from '../../components/icons';

import StyledToggleButtonGroup from '../../components/StyledToggleButtonGroup';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  toggleButtonIcon: {
    marginRight: theme.spacing(2),
  },
  card: {
    marginTop: '1em',
  },
}));

const OptionsContainer = forwardRef(function OptionsContainer ({ extraAidNoKey, extraAidPermit, maxCompanions, maxCoTravellers }, ref) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { settings } = useSelector((state) => state.system);
  const { nbrCompanions, nbrCoTravellers, extraAids } = useSelector((state) => state.booking);

  const handleOptionsChange = (event, value) => {
    if (extraAidNoKey && value.length === 0) value = [extraAidNoKey];
    else value = value.filter((r) => r !== extraAidNoKey);

    const cannotCombined = value.some((r) => settings.aids_which_cannot_be_combined.value.includes(r));
    if (cannotCombined && value.length > 1) {
      dispatch(dialogDisplayMessageAction('Tillval kombinationsfel'));
    } else if (value.length <= 2) {
      dispatch(setExtraAidsAction(value));
    } else {
      handleDisplayHelp();
    }
  };

  const handleOnChangeNbrCompanions = ({ target: { value } }) => {
    dispatch(setNbrCompanionsAction(value));
  };

  const handleOnChangenbrCoTravellers = ({ target: { value } }) => {
    dispatch(setNbrCoTravellersAction(value));
  };

  const handleDisplayHelp = () => {
    dispatch(dialogDisplayMessageAction('Tillval och hjälpmedel'));
  };

  return (
    <Mui.Card className={classes.card}>
      <Mui.CardContent
        aria-controls='extraAid-content'
        id='extraAid-header'
      >
        <Mui.Typography className={classes.header} variant='h2'>
          <Icon.ExtensionRoundedIcon color='primary' className={classes.icon} />
          <FormattedMessage id='bookTrip.extraAid.heading' />
        </Mui.Typography>
        
        <Mui.Box display='flex' justifyContent='flex-end'>
          <Mui.Button variant='text' color='primary' onClick={handleDisplayHelp} disableFocusRipple>
            Hjälp
          </Mui.Button>
        </Mui.Box>
        <Mui.Grid container spacing={3}>
          <Mui.Grid item sm={12}>
            <StyledToggleButtonGroup
              size='small'
              value={extraAids}
              onChange={handleOptionsChange}
              aria-label='Välj tillval och hjälpmedel'>
              {extraAidPermit &&
                extraAidPermit.map((aid, index) => {
                  if (index === 0) {
                    return (
                      <Mui.ToggleButton key={aid.id} value={aid.id} aria-label={aid.description} ref={ref}>
                        {extraAids.includes(aid.id) ? (
                          <Icon.CheckCircleRoundedIcon color='primary' className={classes.toggleButtonIcon} />
                        ) : (
                          ''
                        )}
                        {aid.description}
                      </Mui.ToggleButton>
                    );
                  } else {
                    return (
                      <Mui.ToggleButton key={aid.id} value={aid.id} aria-label={aid.description}>
                        {extraAids.includes(aid.id) ? (
                          <Icon.CheckCircleRoundedIcon color='primary' className={classes.toggleButtonIcon} />
                        ) : (
                          ''
                        )}
                        {aid.description}
                      </Mui.ToggleButton>
                    );
                  }
                })}
            </StyledToggleButtonGroup>
            {extraAids.length <= 0 && (
              <Mui.FormHelperText>
                Obligatorisk, du måste välja minst ett hjälpmedel på det här tillståndet
              </Mui.FormHelperText>
            )}
          </Mui.Grid>
          <Mui.Grid item xs={12} sm={6}>
            <Mui.TextField
              select
              fullWidth
              margin='normal'
              className={classes.formControl}
              id='nbrCoTravellers'
              label={<FormattedMessage id='bookTrip.extraAid.accompanying' />}
              value={nbrCoTravellers}
              onChange={handleOnChangenbrCoTravellers}>
              <Mui.MenuItem value={0}>Ingen</Mui.MenuItem>
              {Array.from(Array(parseInt(maxCoTravellers))).map((value, idx) => (
                <Mui.MenuItem key={idx + 1} value={idx + 1}>
                  {idx + 1} <FormattedMessage id='bookTrip.extraAid.accompanying' />
                </Mui.MenuItem>
              ))}
            </Mui.TextField>
          </Mui.Grid>
          <Mui.Grid item xs={12} sm={6}>
            <Mui.TextField
              select
              fullWidth
              margin='normal'
              className={classes.formControl}
              id='nbrCompanions'
              label={<FormattedMessage id='bookTrip.extraAid.companion' />}
              value={nbrCompanions}
              onChange={handleOnChangeNbrCompanions}>
              <Mui.MenuItem value={0}>Ingen</Mui.MenuItem>
              {Array.from(Array(parseInt(maxCompanions))).map((value, idx) => (
                <Mui.MenuItem key={idx + 1} value={idx + 1}>
                  {idx + 1} <FormattedMessage id='bookTrip.extraAid.companion' />
                </Mui.MenuItem>
              ))}
            </Mui.TextField>
          </Mui.Grid>
        </Mui.Grid>
      </Mui.CardContent>
    </Mui.Card>
  );
});

export default React.memo(OptionsContainer);
