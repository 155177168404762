import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { queryCache } from 'react-query';

import Mui from '../material';

import { dialogDisplayMessageAction, dialogCloseAllAction } from '../../store/dialog/dialogActions';
import { userFavoriteTripDelete } from '../../repository/user';
import { snackbarOpenAction } from '../../store/snackbar/snackbarActions';

const DeleteFavoriteTripDialog = ({ open, handleOnClose }) => {
  const dispatch = useDispatch();
  const { content } = useSelector((state) => state.dialog);

  const handleOnSubmit = async () => {
    try {
      const { success } = await userFavoriteTripDelete(content.id);
      if (success) {
        queryCache.invalidateQueries('userFavoriteTripsList');

        dispatch(dialogCloseAllAction());
        dispatch(snackbarOpenAction('Favoriten har tagits bort'));
      }
    } catch (error) {
      console.log(error);
      dispatch(dialogCloseAllAction());
      dispatch(dialogDisplayMessageAction(error.code));
    }
  };

  return (
    <Mui.Dialog fullWidth maxWidth='xs' open={open} onClose={handleOnClose} aria-labelledby='dialog-title dialog-content'>
      <Mui.DialogTitle id='dialog-title'>
        <FormattedMessage id='myPage.myFavourites.deleteFavTripTitle' />
      </Mui.DialogTitle>
      <Mui.DialogContent id="dialog-content">
        <Mui.Typography>
          <FormattedMessage id='myPage.myFavourites.deleteFavTripText' />
        </Mui.Typography>
      </Mui.DialogContent>
      <Mui.DialogActions id="dialog-actions">
        <Mui.Button onClick={handleOnClose} color='primary' disableFocusRipple>
          <FormattedMessage id='dialog.btnCancel' />
        </Mui.Button>
        <Mui.Button onClick={handleOnSubmit} color='primary' disableFocusRipple>
          <FormattedMessage id='dialog.btnRemove' />
        </Mui.Button>
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};

export default React.memo(DeleteFavoriteTripDialog);
