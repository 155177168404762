import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { systemToggleSubNav } from '../store/system/systemActions';
import { isMobileOnly } from 'react-device-detect';
import usePermits from '../hooks/usePermits';

const useStyles = makeStyles((theme) => ({
  isClose: {
    display: 'none',
  },
  isOpen: {
    display: 'flex',

    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      zIndex: 9000,
      left: 0,
      right: 0,
    },
  },
  banner: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    background: theme.palette.primary.main,
  },
  navList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    whiteSpace: 'nowrap',
    listStyle: 'none',
    margin: 0,
    padding: theme.spacing(3),

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  link: {
    display: 'block',
    fontSize: '1rem',
    fontWeight: 500,
    letterSpacing: 1.49,
    color: theme.palette.common.white,
    margin: theme.spacing(2, 4),
    textDecoration: 'none',

    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 4),
    },

    '&:focus, &:active': {
      outline: `3px solid ${theme.palette.common.white}`,
    },
  },
  active: {
    borderBottom: `1px solid ${theme.palette.common.white}`,
  },
}));

const SubNavBanner = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data: permits } = usePermits();
  const { subNavBarIsOpen } = useSelector((state) => state.system);

  const handleCloseSubNav = () => {
    if (isMobileOnly) dispatch(systemToggleSubNav());
  };

  return (
    <div
      className={clsx(classes.banner, subNavBarIsOpen ? classes.isOpen : classes.isClose)}
      role='navigation'
      aria-label='undermeny'>
      <ul className={classes.navList}>
        <li className={classes.navListItem}>
          <NavLink
            exact
            activeClassName={classes.active}
            to='/mina-sidor/'
            className={classes.link}
            onClick={handleCloseSubNav}>
            Min profil
          </NavLink>
        </li>
        {permits && (
          <>
            <li className={classes.navListItem}>
              <NavLink
                exact
                activeClassName={classes.active}
                to='/mina-sidor/mina-favoriter'
                className={classes.link}
                onClick={handleCloseSubNav}>
                Mina favoriter
              </NavLink>
            </li>
            <li className={classes.navListItem}>
              <NavLink
                exact
                activeClassName={classes.active}
                to='/mina-sidor/mina-tillstand'
                className={classes.link}
                onClick={handleCloseSubNav}>
                Mina tillstånd
              </NavLink>
            </li>
            <li className={classes.navListItem}>
              <NavLink
                exact
                activeClassName={classes.active}
                to='/mina-sidor/mina-ombud'
                className={classes.link}
                onClick={handleCloseSubNav}>
                Mina ombud
              </NavLink>
            </li>
          </>
        )}
        <li className={classes.navListItem}>
          <NavLink
            exact
            activeClassName={classes.active}
            to='/mina-sidor/mina-resenarer'
            className={classes.link}
            onClick={handleCloseSubNav}>
            Mina resenärer
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default React.memo(SubNavBanner);
