import { useHistory } from 'react-router-dom';
import { useMutation, queryCache } from 'react-query';
import { tripsOrderSave } from '../repository/trips';
import { useDispatch, useSelector } from 'react-redux';

import { dialogDisplayMessageAction } from '../store/dialog/dialogActions';
import { clearBookingAction } from '../store/booking/bookingActions';
import { snackbarOpenAction } from '../store/snackbar/snackbarActions';

import CONSTANTS from '../store/constants';

export default function useOrderSave() {
  const dispatch = useDispatch();
  const history = useHistory();
  const booking = useSelector((state) => state.booking);

  return useMutation(
    async () => {
      dispatch({ type: CONSTANTS.ADD_LOADER, payload: 'tripsOrderSave' });
      const request = {
        ...booking,
        pickupAddress: booking.pickupAddress.address,
        dropoffAddress: booking.dropoffAddress.address,
      };
      return await tripsOrderSave(request);
    },
    {
      onError: (error) => {
        console.log(error);
        dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'tripsOrderSave' });
        dispatch(dialogDisplayMessageAction(error.code));
      },
      onSuccess: (data) => {
        dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'tripsOrderSave' });
        //queryCache.invalidateQueries('orderList');
        queryCache.removeQueries('orderList');

        dispatch(clearBookingAction());
        dispatch(snackbarOpenAction('Tack för din bokning'));

        // Change url
        history.push(`/mina-resor/${data.orderId}`);
      },
    }
  );
}
