import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { dialogInactiveUserAction } from '../store/dialog/dialogActions';

const IdleTimer = () => {
  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state.system);

  const handleOnIdle = (event) => {
    console.log('last active', getLastActiveTime());
    dispatch(dialogInactiveUserAction());
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: (parseInt(settings?.app_inactive_logout?.value) * 1000) - (parseInt(settings?.app_warning_before_logout?.value) * 1000),
    onIdle: handleOnIdle,
    debounce: 1000,
  });

  return <></>;
};

export default IdleTimer;
