import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Mui from '../material';

const EditTripDialog = ({ open, handleOnClose }) => {
  const history = useHistory();
  const { content } = useSelector((state) => state.dialog);

  const handleOnSubmit = async () => {
    handleOnClose();
    history.push(
      `/boka-resa/edit/${content.orderId}/${content.travellers[0].travelType.type}/${content.travellers[0].travelTypeID}`
    );
  };

  return (
    <Mui.Dialog fullWidth maxWidth='xs' open={open} onClose={handleOnClose} aria-labelledby='dialog-title dialog-content'>
      <Mui.DialogTitle id='dialog-title'>
        <FormattedMessage id='tripEditAlert.title' />
      </Mui.DialogTitle>
      <Mui.DialogContent id="dialog-content">
        <Mui.Typography>
          <FormattedMessage id='tripEditAlert.message' />
        </Mui.Typography>
      </Mui.DialogContent>
      <Mui.DialogActions id="dialog-actions">
        <Mui.Button onClick={handleOnClose} color='primary' disableFocusRipple>
          <FormattedMessage id='dialog.btnCancel' />
        </Mui.Button>
        <Mui.Button onClick={handleOnSubmit} color='primary' disableFocusRipple>
          <FormattedMessage id='dialog.btnOk' />
        </Mui.Button>
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};

export default React.memo(EditTripDialog);
