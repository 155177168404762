import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import CONSTANTS from '../../store/constants';

import { userLogin } from '../../repository/user';

import { userGetAction, userClearAuth } from '../../store/auth/authActions';
import { dialogDisplayMessageAction, dialogForgottenPasswordAction } from '../../store/dialog/dialogActions';

import Mui from '../material';
import Icon from '../icons';
import { FormattedMessage } from 'react-intl';

const GroupAccountForm = () => {
  const dispatch = useDispatch();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOnForgottenPassword = async () => {
    dispatch(dialogForgottenPasswordAction('username'));
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    try {
      localStorage.setItem('login-tab', 2);

      dispatch({ type: CONSTANTS.ADD_LOADER, payload: 'userLogin' });
      const auth = await userLogin({ username, password });

      localStorage.setItem('refreshToken', auth.refreshToken);
      dispatch({ type: CONSTANTS.SET_PRIMARY_TOKEN, payload: auth });
      dispatch({ type: CONSTANTS.SET_TOKEN, payload: auth });
      dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'userLogin' });

      dispatch(userGetAction());
    } catch (error) {
      console.log(error);
      dispatch({ type: CONSTANTS.REMOVE_LOADER, payload: 'userLogin' });
      dispatch(userClearAuth());
      dispatch(dialogDisplayMessageAction(error.code));
    }
  };

  return (
    <>
      <form onSubmit={handleOnSubmit} id='LoginGroupAccountForm' autoComplete='off'>
        <Mui.FormGroup row>
          <Mui.TextField
            required
            fullWidth
            name='username'
            margin='normal'
            autoComplete='off'
            value={username}
            variant='outlined'
            id='username'
            label='Användarnamn'
            onChange={(e) => setUsername(e.target.value)}
          />

          <Mui.FormControl margin='normal' fullWidth variant='outlined'>
            <Mui.InputLabel htmlFor='password'>Lösenord</Mui.InputLabel>
            <Mui.OutlinedInput
              id='password'
              name='password'
              autoComplete='off'
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <Mui.InputAdornment position='end'>
                  <Mui.IconButton
                    aria-label={showPassword ? 'Dölj lösenord' : 'Visa lösenord'}
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'>
                    {showPassword ? <Icon.Visibility /> : <Icon.VisibilityOff />}
                  </Mui.IconButton>
                </Mui.InputAdornment>
              }
              labelWidth={70}
            />
          </Mui.FormControl>

          <Mui.FormControl fullWidth margin='normal'>
            <Mui.Button type='submit' fullWidth variant='contained' color='primary' disableElevation disableFocusRipple>
              <FormattedMessage id='login.functionAccount' />
            </Mui.Button>
          </Mui.FormControl>
        </Mui.FormGroup>
      </form>
      <Mui.FormControl fullWidth margin='normal'>
        <Mui.Button
          fullWidth
          variant='outlined'
          color='primary'
          disableElevation
          disableFocusRipple
          onClick={handleOnForgottenPassword}>
          <FormattedMessage id='login.forgotPassword' />
        </Mui.Button>
      </Mui.FormControl>
    </>
  );
};

export default React.memo(GroupAccountForm);
