import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { dialogCloseAllAction, dialogCloseAction } from '../../store/dialog/dialogActions';

import DisplayMessageDialog from '../../components/dialogs/DisplayMessageDialog';
import ChangePasswordDialog from '../../components/dialogs/ChangePasswordDialog';
import DeleteUserDialog from '../../components/dialogs/DeleteUserDialog';
import DeleteTripDialog from '../../components/dialogs/DeleteTripDialog';
import EditTripDialog from '../../components/dialogs/EditTripDialog';
import DeleteMultipleTripDialog from '../../components/dialogs/DeleteMultipleTripDialog';
import CreateFavoriteTripDialog from '../../components/dialogs/CreateFavoriteTripDialog';
import UpdateFavoriteTripDialog from '../../components/dialogs/UpdateFavoriteTripDialog';
import DeleteFavoriteTripDialog from '../../components/dialogs/DeleteFavoriteTripDialog';
import DeleteAgentDialog from '../../components/dialogs/DeleteAgentDialog';
import DeleteTravellerDialog from '../../components/dialogs/DeleteTravellerDialog';
import ForgottenPasswordDialog from '../../components/dialogs/ForgottenPasswordDialog';
import BankIdLoginDialog from '../../components/dialogs/BankIdLoginDialog';
import DisplayInformationDialog from '../../components/dialogs/DisplayInformationDialog';
import InactiveUserDialog from '../../components/dialogs/InactiveUserDialog';

const DialogContainer = () => {
  const dispatch = useDispatch();
  const dialog = useSelector((state) => state.dialog);

  const handleOnSingelClose = (dialog) => {
    dispatch(dialogCloseAction(dialog));
  };

  const handleOnClose = () => {
    dispatch(dialogCloseAllAction());
  };

  return (
    <>
      {dialog.changePassword && <ChangePasswordDialog open={dialog.changePassword} handleOnClose={handleOnClose} />}

      {dialog.deleteUser && <DeleteUserDialog open={dialog.deleteUser} handleOnClose={handleOnClose} />}

      {dialog.deleteTrip && <DeleteTripDialog open={dialog.deleteTrip} handleOnClose={handleOnClose} />}

      {dialog.editTrip && <EditTripDialog open={dialog.editTrip} handleOnClose={handleOnClose} />}

      {dialog.deleteMultipleTrip && (
        <DeleteMultipleTripDialog open={dialog.deleteMultipleTrip} handleOnClose={handleOnClose} />
      )}

      {dialog.createFavoriteTrip && (
        <CreateFavoriteTripDialog open={dialog.createFavoriteTrip} handleOnClose={handleOnClose} />
      )}

      {dialog.updateFavoriteTrip && (
        <UpdateFavoriteTripDialog open={dialog.updateFavoriteTrip} handleOnClose={handleOnClose} />
      )}

      {dialog.deleteFavoriteTrip && (
        <DeleteFavoriteTripDialog open={dialog.deleteFavoriteTrip} handleOnClose={handleOnClose} />
      )}

      {dialog.deleteAgent && <DeleteAgentDialog open={dialog.deleteAgent} handleOnClose={handleOnClose} />}

      {dialog.deleteTraveller && <DeleteTravellerDialog open={dialog.deleteTraveller} handleOnClose={handleOnClose} />}

      {dialog.forgottenPassword && <ForgottenPasswordDialog open={dialog.forgottenPassword} handleOnClose={handleOnClose} />}

      {dialog.bankidLogin && <BankIdLoginDialog open={dialog.bankidLogin} handleOnClose={handleOnClose} />}

      {dialog.displayMessage && <DisplayMessageDialog open={dialog.displayMessage} handleOnClose={handleOnClose} />}
      
      {dialog.inactiveUser && <InactiveUserDialog open={dialog.inactiveUser} handleOnClose={handleOnClose} />}

      {dialog.displayInformation && (
        <DisplayInformationDialog
          open={dialog.displayInformation}
          handleOnClose={() => handleOnSingelClose('displayInformation')}
        />
      )}
    </>
  );
};

export default DialogContainer;
