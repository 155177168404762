import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { tripsOrderList } from '../repository/trips';
import { tripsAddOrdersAction } from '../store/trips/tripsActions';

export default function useOrderList(limit, offset) {
  const dispatch = useDispatch();
  return useQuery('orderList', (context) => tripsOrderList(limit, offset), {
  	refetchOnWindowFocus: false,
  	retry: false,
  	onSuccess: (data) => {
  	  dispatch(tripsAddOrdersAction(data));
    }
  });
}
